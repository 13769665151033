/* Stores states of the current mapbox render, to be shared between Sidebar.js & Mapbox.js. Originally implemented for the zone heatmap shareable link feature*/
import React, { createContext, useContext, useState, useRef, MutableRefObject } from 'react';
import mapboxgl from 'mapbox-gl';

interface MapStateContextType {
  lowerUniBound: number;
  setLowerUniBound: React.Dispatch<React.SetStateAction<number>>;
  upperUniBound: number;
  setUpperUniBound: React.Dispatch<React.SetStateAction<number>>;
  viewZoneMap: boolean;
  setViewZoneMap: React.Dispatch<React.SetStateAction<boolean>>;
  map: MutableRefObject<mapboxgl.Map | null>;
  plotConfigs: {
    [key: string]: {
      caption: string;
      targetVar: string;
      feature: string;
      unit?: string;
    };
  };
}

const MapStateContext = createContext<MapStateContextType | undefined>(undefined);

export const MapStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [lowerUniBound, setLowerUniBound] = useState(60);
  const [upperUniBound, setUpperUniBound] = useState(140);
  const [viewZoneMap, setViewZoneMap] = useState(false);
  const map = useRef<mapboxgl.Map | null>(null);

  const plotConfigs = {
    'height': {
        caption: 'Canopy Height',
        targetVar: 'heightTarg', // targetVars are from from get_stats output
        feature: 'canopy_height_m', // features are from treeData geojson 
        unit: 'm',
    },
    'spacing': {
        caption: 'Trunk Spacing',
        targetVar: 'spacingTarg',
        feature: 'tree_spacing',
        unit: 'ft',
    },
    'tree-vigor': {
        caption: 'Canopy Area',
        targetVar: 'vigorTarg',
        feature: 'canopy_area_m2',
        unit: 'm\u00B2',
    },
    'tree_diam': {
        caption: 'Trunk Diameter',
        targetVar: 'treeDiamTarg',
        feature: 'width',
        unit: 'in',
    },
    'uniform': {
        caption: 'Fruits per Tree',
        feature: 'num_buds',
        targetVar: 'uniTarg',
        unit: 'fruits',
    },
    'size': {
        caption: 'Fruit Size per Tree',
        targetVar: 'uniSizeTarg',
        feature: 'avg_diam', 
        unit: 'mm',
    }
};

  return (
    <MapStateContext.Provider value={{
      lowerUniBound,
      setLowerUniBound,
      upperUniBound,
      setUpperUniBound,
      viewZoneMap,
      setViewZoneMap,
      map,
      plotConfigs,
    }}>
      {children}
    </MapStateContext.Provider>
  );
};

export const useMapState = () => {
  const context = useContext(MapStateContext);
  if (context === undefined) {
    throw new Error('useMapState must be used within a MapStateProvider');
  }
  return context;
};