import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faTimes } from '@fortawesome/free-solid-svg-icons';

export const ScheduleControl = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className='font-lato bg-white border border-gray-900 rounded-lg px-2 pb-2 pt-1 text-lg opacity-90 flex z-50 flex-col drop-shadow-md cursor-pointer w-fit' onClick={openModal}>
                <div className='flex items-center'>
                    <FontAwesomeIcon className='size-7 mr-2' icon={faCalendarDays} />
                    <p className='text-md'>Scheduling</p>
                </div>
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-4 rounded-lg w-11/12 max-w-3xl cursor-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-bold">Field Tech Request Form</h2>
                            <button onClick={closeModal} className="text-2xl">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <iframe
                            src="https://docs.google.com/forms/d/e/1FAIpQLSfYVMTxPfeFwHOPaCpo--mP1F1Aor4vjP9PSTUXIV2qVVtLGw/viewform?pli=1"
                            width="100%"
                            height="600"
                        >
                            Loading...
                        </iframe>
                    </div>
                </div>
            )}
        </>
    );
};