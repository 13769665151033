import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import pulsing from '../assets/pulsing.gif'
import { FeedbackForm } from '../sidebar/ScanViewStats';
import React from 'react';

export const HelpControl = ({ truthfulScanName }) => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        setVisible(v => !v);
    };

    return (
        <div className='font-lato bg-white border border-gray-900 rounded-lg px-2 pb-2 pt-1 text-lg opacity-90 flex flex-col drop-shadow-md w-fit'>
            {visible ? (
                <div className="w-80 flex flex-col items-center justify-center">
                    <p className="text-xl font-bold mt-2">Need help?</p>
                    <div className="flex text-left flex-col pl-2">
                        <p className="p-2 text-gray-700 text-sm text-left text-wrap">Charlie from Orchard Robotics is available now to call or email, or leave feedback in the form below:</p>

                        <div className="flex items-center text-left">
                            <img src={pulsing} alt="green-dot" className="h-8 w-8 mr-2 animate-pulse" />
                            <p className="text-lg mr-2">{`(315) 359-7337`}</p>
                        </div>

                        <div className="flex items-center text-left">
                            <img src={pulsing} alt="green-dot" className="h-8 w-8 mr-2 animate-pulse" />
                            <a className='text-blue-500 underline' href="mailto:charlie@orchard-robotics.com">charlie@orchard-robotics.com</a>
                        </div>
                        <hr />
                        <FeedbackForm truthfulScanName={truthfulScanName} />
                    </div>
                    <FontAwesomeIcon className="text-2xl cursor-pointer" icon="fa-solid fa-caret-right" onClick={toggleVisible} />
                </div>
            ) : (
                <div className='font-bold text-center p-1 cursor-pointer' onClick={toggleVisible}>
                    <div className="flex items-center">
                        <FontAwesomeIcon className='text-2xl align-middle' icon='fa-solid fa-question' />
                    </div>
                </div>
            )}
        </div>
    )
}