import MapboxDraw, { DrawModeChangeEvent } from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';
import axios from 'axios';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPoint, MultiPolygon, Point, Polygon } from "geojson";
import mapboxgl, { AnyLayer, GeoJSONSource, LngLatBoundsLike, LngLatLike, MapLayerMouseEvent } from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import loading from '../assets/loading.gif';
import { BlockScanMap, Coordinate, EntityType, FruitType, Orchard, StageType, Statistics } from '../common/types';
import { useMapState } from '../context/MapStateContext';
import { GroundTruthDialog } from './GroundTruthDialog';
import { HelpControl } from './HelpControl';
import { EnableDrawControl, MapSettings } from './MapControls';
import { AreaSelectionConfirmationDialog, ManualCalibrationDialog, NoScansWarningDialog, PolygonAreaStatsDialog, SizeTargetAlertDialog, TreeImageDialog } from './MapDialogs';
import { getPlotTypeConfig, greenStaticDot, redStaticDot } from './MapLayerConfigs';
import { addPopupHandlers, onMouseLeaveHandler, popupConfigs } from './MapPopupConfigs';
import { MapboxGLDrawConfig } from './MapboxGLDrawConfig';
import { LegendRenderer } from './legends/LegendRenderer';

// public key on Mapbox website
mapboxgl.accessToken = 'pk.eyJ1IjoiY2xvdWQtYWRtaW4tb3IiLCJhIjoiY2xtc2d1dHlrMGY1ejJpcGpoM2drcTNsZSJ9.o7zJs0A2ewtqcflV1ReYUQ';

interface MapBoxProps {
    exclusionArray: string[];
    orchardCode: string;
    orchard: Orchard | null;
    sectionGeojson: FeatureCollection | null;
    allowedVarieties: any[];
    setMapCenter: React.Dispatch<React.SetStateAction<any>>;
    downloadFormat: string;
    plotType: string | null;
    setPlotType: React.Dispatch<React.SetStateAction<string | null>>;
    polygonStats: Statistics | null;
    setPolygonStats: React.Dispatch<React.SetStateAction<any>>;
    polygonStatDialog: boolean;
    setPolygonStatDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setAddToSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    mapInstance: React.MutableRefObject<mapboxgl.Map | null>;
    mapCenter: any;
    setControlEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    blockName: string | null;
    stats: Statistics | null;
    truthfulScanName: string | null;
    truthfulScanId: number | null;
    controlEnabled: boolean;
    sidebarViewMode: string;
    setSidebarViewMode: React.Dispatch<React.SetStateAction<string>>;
    setBlockName: React.Dispatch<React.SetStateAction<string | null>>;
    blockView: boolean;
    setBlockView: React.Dispatch<React.SetStateAction<boolean>>;
    initialLocation: Coordinate | undefined;
    treeData: any;
    blockData: FeatureCollection<MultiPolygon, GeoJsonProperties>;
    setBlockData: React.Dispatch<React.SetStateAction<FeatureCollection<MultiPolygon, GeoJsonProperties>>>;
    adminUser: boolean;
    blockScanMap: BlockScanMap;
    stageType: StageType | null;
    setTreeViewLat: React.Dispatch<React.SetStateAction<number>>;
    setTreeViewLong: React.Dispatch<React.SetStateAction<number>>;
    treeViewLat: number;
    treeViewLong: number;
    entity: EntityType;
    sidebarWidth: number;
    blockInfo: any;
    fruitType: FruitType;
  }

export const MapBox: React.FC<MapBoxProps> = ({ exclusionArray, orchardCode, orchard, sectionGeojson, allowedVarieties, setMapCenter, downloadFormat, plotType, setPlotType, polygonStats, setPolygonStats, polygonStatDialog, setPolygonStatDialog, setAddToSidebar, mapInstance, mapCenter, setControlEnabled, blockName, stats, truthfulScanName, truthfulScanId, controlEnabled, sidebarViewMode, setSidebarViewMode, setBlockName, blockView, setBlockView, initialLocation, treeData, blockData, setBlockData, adminUser, blockScanMap, stageType, setTreeViewLat, setTreeViewLong, treeViewLat, treeViewLong, entity, sidebarWidth, blockInfo, fruitType }) => {
    const initialZoom = 15.64;

    const {
        lowerBound,
        setLowerBound,
        upperBound,
        setUpperBound,
        viewZoneMap,
        setViewZoneMap,
        map,
      } = useMapState();
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const draw = useRef<MapboxDraw | null>(null);
    const [treeId, setTreeId] = useState('')
    const [drawEnabled, setDrawEnabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [imageURL, setImageURL] = useState("");
    const updateAreaSelectionRef = useRef<((e: any) => Promise<void>) | null>(null);
    const [lng, setLng] = useState(initialLocation != undefined ? initialLocation.long : 0);
    const [lat, setLat] = useState(initialLocation != undefined ? initialLocation.lat : 0);
    const [zoom, setZoom] = useState(initialZoom.toString());

    const [confirmDialog, setConfirmDialog] = useState(false);
    const fireAxiosCallRef = useRef<(() => void) | null>(null);
    const [sizeTargHeatmapDialog, setSizeTargHeatmapDialog] = useState(false)
    const [style, setStyle] = useState('mapbox://styles/mapbox/satellite-streets-v9')
    
    // For Map elements
    const [treeDiamTarg, setTreeDiamTarg] = useState<number>(0)
    const [heightTarg, setHeightTarg] = useState<number>(0)
    const [spacingTarg, setSpacingTarg] = useState<number>(0)
    const [vigorTarg, setVigorTarg] = useState<number>(0)
    const [uniTarg, setUniTarg] = useState<number>(0)
    const [rldTarg, setRldTarg] = useState<number>(0);
    const [uniSizeTarg, setUniSizeTarg] = useState<number>(0);

    const [noScanWarningDialog, setNoScanWarningDialog] = useState(false);
    const [viewTapeSections, setViewTapeSections] = useState(false);
    const [view3DMap, setView3DMap] = useState(false);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [manualCalibrationDialog, setManualCalibrationDialog] = useState(false);
    const [selectedCalibrationSection, setSelectedCalibrationSection] = useState(null);
    const [isGroundTruthDialogOpen, setIsGroundTruthDialogOpen] = useState(false);
    const [mapNeedsRedraw, setMapNeedsRedraw] = useState(false);
    const [selectedRLDOptions, setSelectedRLDOptions] = useState({
        // TODO, convert to ft
        ccsa_red_m2: true,
        ccsa_yellowred_m2: false,
        ccsa_yellow_m2: false,
        ccsa_wilted_m2: false
    });

    // Admin Features
    const [auditTreeData, setAuditTreeData] = useState(null);
    const [viewAutoMovedTrees, setViewAutoMovedTrees] = useState(true);

    useEffect(() => {
        setViewTapeSections(false);
        setViewZoneMap(false);

        // If back button is clicked in sidebar (sets TSN === ''), remove layer(s)
        if (truthfulScanName === '' || truthfulScanName === null) {
            if (map.current && map.current.getLayer("trees-point-uni")) {
                map.current.removeLayer('trees-point-uni');
            }
            return
        }

        // Reset area selection
        if (!draw.current || !map.current || !map.current.isStyleLoaded() || !map.current.hasControl(draw.current)) return;
        const data: FeatureCollection = draw.current.getAll();
        let pids: string[] = []
        data.features.forEach(f => {
            if (f.geometry.type === 'Polygon' && typeof f.id === 'string') pids.push(f.id);
        });
        draw.current.delete(pids);

    }, [truthfulScanName]);

    const removeMapSource = (sourceId: string) => {
        if (!map.current) {
            return;
        }
        const mapSource = map.current.getSource(sourceId);
        if (!mapSource) {
            return;
        }
        for (let mapLayer of map.current.getStyle()?.layers || []) {
            if ('source' in mapLayer && mapLayer.source === sourceId) {
                map.current.removeLayer(mapLayer.id);
            }
        }
        map.current.removeSource(sourceId);
    };

    const openGroundTruthDialog = () => {
        return (
            blockInfo &&
            sectionGeojson &&
            sectionGeojson.features &&
            sectionGeojson.features.length > 0 &&
            stats &&
            stats.calibration_ratio === null &&
            sectionGeojson.features.some(feature => feature.properties && feature.properties.detected_count_id != null)
        );
    };

    // Effect to open dialog when conditions are met
    useEffect(() => {
        if (openGroundTruthDialog()) {
            setIsGroundTruthDialogOpen(true);
        } else {
            setIsGroundTruthDialogOpen(false);
        }
    }, [blockInfo, sectionGeojson, stats]);

    useEffect(() => {
        if (truthfulScanName && map.current) {
            setMapNeedsRedraw(true);
        }
    }, [viewTapeSections, viewZoneMap, view3DMap, selectedRow]);

    useEffect(() => {
        setMapNeedsRedraw(true);
    }, []); // Run once upon load

    useEffect(() => {
        if (map.current) {
            map.current.resize();
        }
    }, [sidebarWidth]);

    useEffect(() => {
        // Sum up the selected RLD options and set to rldTarg
        if (stats && selectedRLDOptions) {
            console.log(selectedRLDOptions);
            const sumRLD = Object.entries(selectedRLDOptions)
                .filter(([_, isSelected]) => isSelected)
                .reduce((sum, [field]) => {
                    console.log("FIELD",field);
                    const value = stats[field as keyof Statistics];
                    return sum + (typeof value === 'number' ? value : 0);
                }, 0);
       
            setRldTarg(sumRLD);
            setMapNeedsRedraw(true);
        }
    }, [selectedRLDOptions, stats]);
    
    useEffect(() => {
        if (stats === null) return;

        const safeNumber = (value: number) => {
            const num = Number(value);
            if (!Number.isFinite(num)) {
                return 0; 
            }
            return Number(num.toFixed(2)); 
        };
        setHeightTarg(safeNumber(stats.avg_canopy_height_m ?? 0));
        setSpacingTarg(safeNumber(stats.avg_tree_spacing_ft ?? 0));
        setVigorTarg(safeNumber(stats.avg_canopy_area_m2 ?? 0));
        setTreeDiamTarg(safeNumber(stats.avg_tree_diam_in ?? 0));
        setUniTarg(Math.round(stats.fruit_per_tree_calibrated > 0 ? stats.fruit_per_tree_calibrated : stats.fruit_per_tree_detected));
        if (entity === EntityType.Fruits) {
            if (stageType === StageType.EarlyFruitSet) {
                setUniSizeTarg(safeNumber(stats.avg_fruitlet_diam ?? 0));
            }
            if (stageType === StageType.Fruit) {
                setUniSizeTarg(safeNumber(stats.avg_fruit_diam ?? 0));
            }
        }
    }, [stats]);

    useEffect(() => {
        if (blockView == false) {
            setDrawEnabled(false);
        }
    }, [blockView]);

    useEffect(() => {
        if (!map.current) {
            return;
        }
        map.current.setStyle(style);
    }, [style]);

    const toggleStyle = () => {
        if (style === 'mapbox://styles/mapbox/satellite-streets-v9') {
            setStyle('mapbox://styles/mapbox/outdoors-v12'); // or any other style you want to switch to
        } else {
            setStyle('mapbox://styles/mapbox/satellite-streets-v9');
        }
    }

    useEffect(() => {
        if (mapCenter !== null) {
            setLat(mapCenter.lat.toFixed(4));
            setLng(mapCenter.lon.toFixed(4));
            if (map.current) {
                map.current.flyTo({
                    center: [mapCenter.lon.toFixed(4), mapCenter.lat.toFixed(4)],
                    zoom: 16.7, // Set your desired zoom level
                    speed: 1, // Speed at which the map moves, values between 0.1 and 1
                    curve: 1, // Controls the rate of zooming, a value of 1 is linear
                });
            }
        }
    }, [mapCenter]);

    useEffect(() => {
        if (mapCenter !== null) {
            if (map.current && downloadFormat === 'Report') {
                setStyle('mapbox://styles/mapbox/outdoors-v12');
                map.current.flyTo({
                    center: [treeViewLong, treeViewLat],
                    zoom: 17.14, // Set your desired zoom level
                    speed: 1, // Speed at which the map moves, values between 0.1 and 1
                    curve: 1, // Controls the rate of zooming, a value of 1 is linear
                });
            }
        }
    }, [downloadFormat]);
 

    useEffect(() => {
        removeMapSource('trees');
    }, [truthfulScanId]);

    const updateAreaSelection = useCallback(async (e: any) => {
        if (!draw.current) {
            return;
        }

        const data = draw.current.getAll() as FeatureCollection<Polygon, GeoJsonProperties>;

        fireAxiosCallRef.current = () => {
            if (!map.current) {
                return;
            }
            setConfirmDialog(false); 
            setAddToSidebar(false);

            let sourceFeatures = map.current.querySourceFeatures('trees', {sourceLayer: 'trees'}) as Feature<Point | MultiPoint, GeoJsonProperties>[];
            let sourceFeatureCollection: FeatureCollection<Point | MultiPoint, GeoJsonProperties> = {
                'type': 'FeatureCollection',
                'features': sourceFeatures
            };

            const pointsWithinPolygon = turf.pointsWithinPolygon(
                sourceFeatureCollection,
                turf.polygon([data.features[0].geometry.coordinates[0]])
            );
            
            let tree_ids = Array.from(new Set(pointsWithinPolygon.features.map(feature => feature.properties ? feature.properties.tree_id : null).filter(id => id !== null)));

            if (tree_ids.length === 0) {
                toast.error('No trees were selected. Please try again.');
                return;
            }

            toast.promise(
                axios.post('/util/get_stats', {
                    orchard_code: orchardCode,
                    scan_name: truthfulScanName,
                    tree_ids: tree_ids,
                    cache_result: false,
                }, {
                    timeout: 300000 // 5 min
                }),
                {
                    loading: 'Calculating statistics for the selected area',
                    success: <b>Done calculating data!</b>,
                    error: <b>An error occurred while calculating.</b>
                }
            ).then(res => {
                setPolygonStats(res.data);
                setPolygonStatDialog(true);
            });
        };

        if (plotType === 'row_audit') {
            const res = await toast.promise(
                axios.post('/audit/add_user_row', { block_id: blockName, polygon: data.features[0].geometry.coordinates[0] }),
                {
                    loading: 'Transferring row bounds to database...',
                    success: <b>Done transferring polygon bounds!</b>,
                    error: <b>An error occurred while transferring.</b>
                }
            );
        }
        else if (plotType === 'block_audit') {
            const res = await toast.promise(
                axios.post('/audit/add_block_audit', { block_id: blockName, polygon: data.features[0].geometry }),
                {
                    loading: 'Transferring block bounds to database...',
                    success: <b>Done transferring block audit bounds!</b>,
                    error: <b>An error occurred while transferring.</b>
                }
            );
            const block_points_response = await axios.get('/geojson/points_block');
            setBlockData(block_points_response.data);
        }
        else {
            setAddToSidebar(false);

            setConfirmDialog(true);
        }
    }, [truthfulScanName, blockName, plotType]);

    const checkForPaintDoneAndEnableControl = useCallback(() => {
        if (!map.current || !map.current.isStyleLoaded()) {
            setTimeout(checkForPaintDoneAndEnableControl, 200);
            return;
        }

        setControlEnabled(true);
    }, [setControlEnabled]);

    function applySelectedMapLayer(plotType: string | null, mapInstance: mapboxgl.Map, treeData: any, entity: EntityType, allowedVarieties: any[], selectedRow: number | null, setSelectedRow: React.Dispatch<React.SetStateAction<number | null>>, blockData: FeatureCollection<MultiPolygon, GeoJsonProperties>, blockName: string | null) {
        
        console.log("Attempting to load plot", plotType);
        
        if (!plotType || !stats) {
            return;
        }
        const allLayersConfig = getPlotTypeConfig(rldTarg, uniSizeTarg, uniTarg, lowerBound, upperBound, spacingTarg, heightTarg, treeDiamTarg, vigorTarg, allowedVarieties, viewAutoMovedTrees, viewZoneMap, selectedRLDOptions) as {[key: string]: AnyLayer};
        const selectedLayerConfig = allLayersConfig[plotType];
        console.log("PlotType", plotType);

        for (let configKey of Object.keys(allLayersConfig)) {
            const config = allLayersConfig[configKey];
            if (mapInstance.getLayer(config.id)) {
                mapInstance.removeLayer(config.id);
            }
        }

        if (treeData && truthfulScanId) {
            if (!mapInstance.getSource('trees')) {
                mapInstance.addSource('trees', {
                    'type': 'geojson',
                    'data': treeData
                });

            }
        }

        if (view3DMap) {
            if (!mapInstance.getSource('mapbox-dem')) {
                mapInstance.addSource('mapbox-dem', {
                    'type': 'raster-dem',
                    'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                    'tileSize': 512,
                    'maxzoom': 14
                });
            }
            mapInstance.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 3 });
        } else {
            // Remove 3d
            mapInstance.setTerrain();
        }

        if ('source' in selectedLayerConfig && typeof selectedLayerConfig.source === 'string') {
            const source = selectedLayerConfig.source;
            if (mapInstance.getSource(source)) {
                if (mapInstance.getSource(source)?.type === 'geojson') {
                    delete (selectedLayerConfig as any)['source-layer'];
                }
                mapInstance.addLayer(selectedLayerConfig);
            }
        }
        if (viewTapeSections) {
            if (!mapInstance.getSource('sections') && sectionGeojson) {
                mapInstance.addSource('sections', {
                    'type': 'geojson',
                    'data': sectionGeojson,
                });
            }
            if (!mapInstance.hasImage('green-static-dot')) {
                mapInstance.addImage('green-static-dot', greenStaticDot);
            }
            if (!mapInstance.hasImage('red-static-dot')) {
                mapInstance.addImage('red-static-dot', redStaticDot);
            }
            mapInstance.addLayer({
                'id': 'section-point',
                'type': 'symbol',
                'source': 'sections',
                'layout': {
                    'icon-image': [
                        'case',
                        ['==', ['get', 'calib_ratio'], null], 'red-static-dot',
                        'green-static-dot'
                    ],
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'text-field': [
                        'case',
                        ['all', 
                            ['!=', ['get', 'calib_ratio'], null],
                        ],
                        ['to-string', ['get', 'calib_ratio']],
                        ''
                    ],
                    'text-font': ['Open Sans Regular'],
                    'text-size': 12,
                    'text-offset': [0, 1.5],
                    'text-anchor': 'top',
                    'text-allow-overlap': true,
                    'text-ignore-placement': true
                },
                'paint': {
                    'text-color': '#000000',
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 1
                }
            });


            // TODO: Refactor to use addPopuphandlers
            mapInstance.on('mouseenter', 'section-point', function (event: MapLayerMouseEvent) {
                const layerConfig = popupConfigs['sections'];
                if (layerConfig && layerConfig.onMouseEnter) {
                    layerConfig.onMouseEnter.call(mapInstance, event);
                }
            });

            mapInstance.on('mouseup', 'section-point', function (event: MapLayerMouseEvent) {
                const layerConfig = popupConfigs['sections'];
                if (layerConfig && layerConfig.onClick && adminUser) {
                    layerConfig.onClick.call(mapInstance, event, setManualCalibrationDialog, setSelectedCalibrationSection);
                }
            });

            mapInstance.off('mouseleave', 'section-point', onMouseLeaveHandler);
            mapInstance.on('mouseleave', 'section-point', onMouseLeaveHandler);
        }
        else {
            addPopupHandlers(mapInstance, selectedLayerConfig.id, setTreeId, setImageURL, setOpen, truthfulScanName, entity, selectedRow, setSelectedRow);
        }
    }

    function onMapStyleLoad() {
        const layerIDsToRemove = ['block-poly-2', 'state-borders-2'];
        if (!map.current) {
            return;
        }
        layerIDsToRemove.forEach(id => {
            if (map.current && map.current.getLayer(id)) {
                map.current.removeLayer(id);
            }
        });
        if (map.current.getSource("blocks")) {
            map.current.removeSource("blocks");
        }
        map.current.addSource("blocks", {
            "type": "geojson",
            "data": blockData
        });
        map.current.addLayer({
            "id": "block-poly-2",
            "type": "fill",
            "source": "blocks",
            "layout": {},
            "paint": {
                "fill-color":
                    "#eef0ce",
                "fill-opacity":
                    0.2
            }
        });
        map.current.addLayer({
            "id": "state-borders-2",
            "type": "line",
            "source": "blocks",
            "layout": {},
            "paint": {
                "line-color": "#5b84c7", // #627BC1
                "line-width": 1 // 2
            }
        });

        // Sky background
        map.current.addLayer({
            'id': 'sky',
            'type': 'sky',
            'paint': {
                'sky-type': 'gradient',
                'sky-gradient': [
                    'interpolate',
                    ['linear'],
                    ['sky-radial-progress'],
                    0.8,
                    'rgba(135, 206, 235, 1.0)', // Light blue
                    1,
                    'rgba(0, 0, 139, 1.0)' // Dark blue
                ]
            }
        });
        setMapNeedsRedraw(true); // Draw the map now that it is initialized
    }

    async function redrawMap() {
        let shownTreeData = treeData;
        if (mapContainer === null) {
            return;
        }
        let mapCenter: LngLatLike = [0, 0];
        if (initialLocation) {
            mapCenter = [initialLocation.long, initialLocation.lat];
        }
        if (map.current == null) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current as HTMLElement,
                style: style,
                center: mapCenter,
                zoom: 16,
                attributionControl: false,
                logoPosition: 'bottom-left',
            });
            draw.current = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true
                },
                styles: MapboxGLDrawConfig
            });            
            map.current.on('move', () => {
                if (!map.current) {
                    return;
                }
                setLng(map.current.getCenter().lng);
                setLat(map.current.getCenter().lat);
                setZoom(map.current.getZoom().toFixed(2));
            });
            map.current.on('style.load', onMapStyleLoad);
            map.current.on('load', () => {
                if (!map.current) {
                    return;
                }
                if (map.current.getSource("blocks")) {
                    let blocksSource: GeoJSONSource = map.current.getSource("blocks") as GeoJSONSource;
                    if (blocksSource !== undefined) {
                        blocksSource.setData(blockData);
                    }
                } else {
                    map.current.addSource("blocks", {
                        "type": "geojson",
                        "data": blockData
                    });
                }
                addBlockLayers();
    
                let currentHoveredId: string | number | undefined | null = null;
                map.current.on('mouseenter', 'block-poly', (event: MapLayerMouseEvent) => {
                    if (!event.features || !map.current) {
                        return;
                    }
                    currentHoveredId = event.features[0].id;
                    if (currentHoveredId == null) {
                        return;
                    }
                    map.current.setFeatureState(
                        { source: 'blocks', id: currentHoveredId },
                        { hover: true }
                    );
                });
    
                map.current.on('mouseleave', 'block-poly', (event: MapLayerMouseEvent) => {
                    if (map.current && currentHoveredId != null) {
                        map.current.setFeatureState(
                            { source: 'blocks', id: currentHoveredId },
                            { hover: false }
                        );
                        currentHoveredId = null;
                    }
                });
    
                map.current.on('click', 'block-poly', (event: MapLayerMouseEvent) => {
                    if (!map.current) {
                        return;
                    }
                    if (!blockScanMap || !event.features || !event.features[0].properties || !blockScanMap[event.features[0].properties.block_id] || blockScanMap[event.features[0].properties.block_id].scans.length < 1) {
                        setNoScanWarningDialog(true);
                        return;
                    }
                    map.current.removeLayer('block-poly');
                    map.current.removeLayer('state-borders');
                    map.current.removeLayer('block-labels');
                    if (mapCenter === null) {
                        console.log("block clicked", event.features[0].properties);
                        setMapCenter({ lat: event.features[0].properties.center_lat, lon: event.features[0].properties.center_lon });
                    }
                    map.current.flyTo({
                        center: [event.features[0].properties.center_lon, event.features[0].properties.center_lat],
                        zoom: 16.7,
                        speed: 1,
                        curve: 1,
                    });
                    setTreeViewLat(event.features[0].properties.center_lat);
                    setTreeViewLong(event.features[0].properties.center_lon);
                    setSidebarViewMode('Block');
                    setBlockView(true);
                    setBlockName(event.features[0].properties.block_id);
                });

                map.current.on('draw.modechange', (e: DrawModeChangeEvent) => {
                    if (!draw.current) return;
                    const data = draw.current.getAll();
                    if (draw.current.getMode() === 'draw_polygon') {
                        let pids: string[] = [];
                        const lid = data.features[data.features.length - 1].id;
                        data.features.forEach((f: Feature<Geometry, GeoJsonProperties>) => {
                            if (f.geometry.type === 'Polygon' && f.id !== lid && f.id !== undefined) {
                                pids.push(f.id.toString());
                            }
                        });
                        draw.current.delete(pids);
                    }
                });
            });
            return;
        }
        if (blockView !== true) {
            if (initialLocation) {
                map.current.setZoom(16);
                mapCenter = [initialLocation.long, initialLocation.lat];
                map.current.setCenter(mapCenter);
                map.current.setMaxBounds(undefined);
            }
        }
        else if (plotType === 'trunk_audit') {
            // TODO: Do this better, auditTreeData isn't actually set after setAuditTreeData is called, so the first load is blank
            const res = await axios.get('/geojson/audit_tree_points', { params: { 'scan_id': truthfulScanName } });
            setAuditTreeData(res.data);
            shownTreeData = res.data;
            
            map.current.setZoom(17);
            mapCenter = [lng, lat];
            map.current.setCenter(mapCenter);
        }
        else if (plotType === 'row_audit') {
            map.current.setZoom(17);
            mapCenter = [lng, lat];
            map.current.setCenter(mapCenter);
        }
        else {
            map.current.setZoom(17);
            mapCenter = [treeViewLong, treeViewLat];
            map.current.setCenter(mapCenter);

            map.current.flyTo({
                center: mapCenter,
                zoom: 17.14, // Set your desired zoom level
                speed: 1, // Speed at which the map moves, values between 0.1 and 1
                curve: 1, // Controls the rate of zooming, a value of 1 is linear
            });
        }

        try {
            map.current.getStyle();
        } catch (e) {
            // Swallow error, stylesheet not done loading
            console.log("Stylesheet not done loading, cannot initialize map");
            return;
        }
        map.current.getStyle()?.layers.forEach((layer: AnyLayer) => {
            if (!map.current) {
                return;
            }
            // map.current.removeLayer(layer.id);
        });

        if (!map.current) {
            return;
        }
        
        mapInstance.current = map.current;
        
        if (shownTreeData) {
            console.log("tree data", shownTreeData);
        }

        // Remove existing layers if they exist before adding the new layer
        const layerIDsToRemove = ['trees-point', 'trees-point-diff', 'trees-for-row', 'block-audit', 'sections', 'section-point', 'trees-audit', 'trees'];
        layerIDsToRemove.forEach(id => {
            if (map.current && map.current.getLayer(id)) {
                map.current.removeLayer(id);
            }
        });

        if (blockView === true) {
            applySelectedMapLayer(plotType, map.current, shownTreeData, entity, allowedVarieties, selectedRow, setSelectedRow, blockData, blockName);

            const center = mapCenter;
            console.log(center);
            const maxDistance = 3000;
            const southwest: LngLatLike = [center[0] - maxDistance / 2 / 111319.9, center[1] - maxDistance / 2 / 111319.9];
            const northeast: LngLatLike = [center[0] + maxDistance / 2 / 111319.9, center[1] + maxDistance / 2 / 111319.9];
            const mapMaxBounds: LngLatBoundsLike = [southwest, northeast];
            map.current.setMaxBounds(mapMaxBounds);
        }

        let data = blockData;
        data.features = data.features.map((feature, index) => {
            // Copy the feature object
            let newFeature = { ...feature };

            // Create a new 'id' field
            // If 'block_id' is unique for each feature and is a number, you can use it directly
            // Otherwise, use the index or any other unique number
            newFeature.id = index;

            return newFeature;
        });

        if (updateAreaSelectionRef.current) {
            map.current.off('draw.create', updateAreaSelectionRef.current);
            map.current.off('draw.update', updateAreaSelectionRef.current);
            map.current.off('draw.delete', updateAreaSelectionRef.current);
        }
        updateAreaSelectionRef.current = updateAreaSelection;
        map.current.on('draw.create', updateAreaSelectionRef.current);
        map.current.on('draw.update', updateAreaSelectionRef.current);
        map.current.on('draw.delete', updateAreaSelectionRef.current);

        map.current.triggerRepaint();
        checkForPaintDoneAndEnableControl();

    }

    const addBlockLayers = () => {
        if (!map.current) {
            return;
        }
        if (!map.current.getLayer("block-poly")) {
            map.current.addLayer({
                "id": "block-poly",
                "type": "fill",
                "source": "blocks",
                "layout": {},
                "paint": {
                    "fill-color": [
                        "case",
                        ["boolean", ["feature-state", "hover"], false],
                        "#eef0ce",
                        "#27cc53"
                    ],
                    "fill-opacity": [
                        "case",
                        ["boolean", ["feature-state", "hover"], false],
                        0.5,
                        0.5
                    ]
                }
            });
        }

        if (!map.current.getLayer("block-labels")) {
            map.current.addLayer({
                id: "block-labels",
                type: "symbol",
                source: "blocks",
                layout: {
                    "text-field": ["get", "block_id"],
                    "text-size": 16,
                    "symbol-avoid-edges": true
                },
                paint: {
                    "text-color": "#000",
                    "text-halo-color": "#e3e3e3",
                    "text-halo-width": 3
                }
            });
        }

        if (!map.current.getLayer("state-borders")) {
            map.current.addLayer({
                "id": "state-borders",
                "type": "line",
                "source": "blocks",
                "layout": {},
                "paint": {
                    "line-color": "#5b84c7",
                    "line-width": 2
                }
            });
        }

        map.current.moveLayer("block-labels");
    };


    useEffect(() => {
        if (map.current && sectionGeojson) {
            if (map.current.getSource('sections')) {
                map.current.removeSource('sections');
            }
            map.current.addSource('sections', {
                'type': 'geojson',
                'data': sectionGeojson,
            });
        }
    }, [sectionGeojson]);


    useEffect(() => {
        if (blockData === null) return;
        if (!map.current || !initialLocation) {
            return;
        }

        if (map.current === null || initialLocation === null || blockView === false) return;
        setMapNeedsRedraw(true);
        map.current.triggerRepaint();
    }, [blockScanMap]);

    useEffect(() => {
        if (!map.current || !map.current.isStyleLoaded() || initialLocation === undefined) return;
        if (sidebarViewMode === 'Orchard' && blockView === false) {
            map.current.setZoom(initialZoom);
            map.current.setMaxZoom(20)
            map.current.setMinZoom(10)
            map.current.setCenter([initialLocation.long, initialLocation.lat]);
            addBlockLayers();
            setMapNeedsRedraw(true);
        }

        if (blockView === true) {
            map.current.setMaxZoom(20);
            map.current.setMinZoom(16);
            //Remove green block layers
            ['block-poly', 'block-labels', 'state-borders'].forEach(layerId => {
                if (map.current && map.current.getLayer(layerId)) {
                    map.current.removeLayer(layerId);
                }
            });
            setMapNeedsRedraw(true);
        }
    }, [style, allowedVarieties, checkForPaintDoneAndEnableControl, setControlEnabled, updateAreaSelection, plotType, uniTarg, treeDiamTarg, heightTarg, spacingTarg, vigorTarg, uniSizeTarg, upperBound, lowerBound]);
    
    useEffect(() => {
        if (uniTarg && uniTarg > 0 || treeDiamTarg && treeDiamTarg > 0) {
            setMapNeedsRedraw(true);
            if (map.current) {
                map.current.triggerRepaint();
            }
        }
    }
    , [uniTarg, treeDiamTarg]);

    // enable and disable MapboxDraw
    // we need to do this because touch events don't register when MapboxDraw is enabled on the map
    useEffect(() => {

        if (!map.current || !draw.current) {
            return;
        }

        if (drawEnabled) {
            if (!map.current.hasControl(draw.current)) {
                map.current.addControl(draw.current);
            }
            draw.current.changeMode('draw_polygon');
            if (plotType === 'block_audit') {
                let currentBoundary: Feature<MultiPolygon, GeoJsonProperties> | null = null;
                blockData.features.forEach((feature) => {
                    if (feature.properties !== null && stats !== null && feature.properties.block_id == stats.block_name) {
                        currentBoundary = feature;
                    }
                });
                if (currentBoundary !== null) {
                    draw.current.set({
                        "type": "FeatureCollection",
                        "features": [currentBoundary]
                    });
                }
            }
        } else {
            try {
                map.current.removeControl(draw.current);
                draw.current.changeMode('simple_select');
            } catch (err) {
                // initial 
            }
        }
    }, [drawEnabled]);
    
    useEffect(() => {
        removeMapSource('trees');
        setMapNeedsRedraw(true);
    }, [treeData]);



    useEffect(() => {
        if (!mapNeedsRedraw) {
            return;
        }
        redrawMap();
        setMapNeedsRedraw(false);
    }, [mapNeedsRedraw]);

    const handleCopyCoordinates = () => {
        const coordinates = `${lat.toFixed(4)}, ${lng.toFixed(4)}`;
        const textarea = document.createElement('textarea');
        textarea.value = coordinates;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            toast('Coordinates copied to clipboard', { icon: '📋' });
        } catch (error) {
            console.error('Failed to copy coordinates:', error);
            toast.error('Failed to copy coordinates');
        } finally {
            document.body.removeChild(textarea);
        }
    };

    const handlePregenerate = async (e: any, scanRawName: string) => {
        e.preventDefault();
        toast('Starting pregeneration', { icon: '🔄' });
        const [orchardCode, scanNumberStr] = scanRawName.split("_");
        try {
            await axios.post('/scans/delete_pregenerated', { orchard_code: orchardCode, raw_scan_name: scanRawName });
            window.location.reload();

            toast.success('Pregeneration completed, refreshing...');
        } catch (error) {
            console.error(error);
            toast.error('Error starting pregeneration');
            throw error; // Rethrow the error to be caught in handleSubmit
        }
    };

    return (
        <div className='relative col-span-2'>
            <div className="relative h-screen w-full">
                <div ref={mapContainer} className="h-screen w-full" />
                <div className="absolute inset-0 z-25 flex flex-col justify-start space-y-2 p-2 pointer-events-none">
                    <div className="pointer-events-auto inline-flex">
                        <button
                            className="bg-white border border-gray-700 text-black px-2 py-2 text-xs font-mono rounded-lg cursor-context-menu"
                            onClick={handleCopyCoordinates}
                        >
                            {lat.toFixed(4)}, {lng.toFixed(4)} | Zoom: {zoom}
                        </button>
                    </div>
                    {blockView && (
                        <div className="pointer-events-auto inline-flex w-fit">

                            <div
                                className='flex items-center px-2 py-2 font-mono bg-white text-xs cursor-pointer text-black border border-gray-900 rounded-lg'
                                onClick={() => setIsGroundTruthDialogOpen(true)}>
                                <div className='flex-shrink-0'>
                                    {/* <FontAwesomeIcon icon="fa-regular fa-bullseye-pointer" /> */}
                                </div>
                                <div className='ml-1'>
                                    Edit Ground Truths


                                </div>
                            </div>
                        </div>)}

                    {blockView && (
                        <div className="pointer-events-auto inline-flex w-fit">
                            <EnableDrawControl
                                drawEnabled={drawEnabled}
                                setDrawEnabled={setDrawEnabled}
                            />
                        </div>
                    )}

                    <div className="pointer-events-auto inline-flex">
                        <TreeImageDialog
                            open={open}
                            setOpen={setOpen}
                            treeId={treeId}
                            imageURL={imageURL}
                        />
                    </div>

                </div>

                {!mapContainer && (
                    <div className="grid h-screen place-items-center z-20">
                        <img src={loading} alt="loading..." />
                    </div>
                )}
            </div>
            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 justify-center">
                <LegendRenderer
                    plotType={plotType}
                    uniTarg={uniTarg}
                    treeDiamTarg={treeDiamTarg}
                    heightTarg={heightTarg}
                    spacingTarg={spacingTarg}
                    vigorTarg={vigorTarg}
                    uniSizeTarg={uniSizeTarg}
                    lowerBound={lowerBound}
                    upperBound={upperBound}
                    setLowerBound={setLowerBound}
                    setUpperBound={setUpperBound}
                    viewZoneMap={viewZoneMap}
                    treeData={treeData}
                    stats={stats}
                    rldTarg={rldTarg ?? 0}
                    selectedRLDOptions={selectedRLDOptions}
                    setSelectedRLDOptions={setSelectedRLDOptions}
                /> 
            </div>

            {blockView && <MapSettings exclusionArray={exclusionArray} setLowerBound={setLowerBound} setUpperBound={setUpperBound} toggleStyle={toggleStyle} style={style} entityType={entity} setPlotType={setPlotType} plotType={plotType} controlEnabled={controlEnabled} adminUser={adminUser} viewTapeSections={viewTapeSections} setViewTapeSections={setViewTapeSections} sectionGeojson={sectionGeojson} stageType={stageType} truthfulScanName={truthfulScanName} viewZoneMap={viewZoneMap} setViewZoneMap={setViewZoneMap} view3DMap={view3DMap} setView3DMap={setView3DMap} handlePregenerate={handlePregenerate} fruitType={fruitType} />}

            <div className="absolute right-2 bottom-2 flex flex-col items-end space-y-2 z-50">
                <HelpControl truthfulScanName={truthfulScanName} />
            </div>

            {entity === EntityType.Fruits && isGroundTruthDialogOpen && (
                <GroundTruthDialog
                    isGroundTruthDialogOpen={isGroundTruthDialogOpen}
                    setIsGroundTruthDialogOpen={setIsGroundTruthDialogOpen}
                    blockInfo={blockInfo}
                    sectionGeojson={sectionGeojson}
                    truthfulScanName={truthfulScanName}
                    handlePregenerate={handlePregenerate}
                    orchard={orchard}
                />
            )}
            {polygonStats !== null && <PolygonAreaStatsDialog polygonStats={polygonStats} entity={entity} setAddToSidebar={setAddToSidebar} polygonStatDialog={polygonStatDialog} setPolygonStatDialog={setPolygonStatDialog} entityType={entity} truthfulScanName={truthfulScanName} stageType={stageType} />}
            <AreaSelectionConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} fireAxiosCallRef={fireAxiosCallRef} />
            <SizeTargetAlertDialog sizeTargHeatmapDialog={sizeTargHeatmapDialog} setSizeTargHeatmapDialog={setSizeTargHeatmapDialog} />
            <NoScansWarningDialog noScanWarningDialog={noScanWarningDialog} setNoScanWarningDialog={setNoScanWarningDialog} />
            <ManualCalibrationDialog manualCalibrationDialog={manualCalibrationDialog} setManualCalibrationDialog={setManualCalibrationDialog} selectedCalibrationSection={selectedCalibrationSection} orchardCode={orchardCode} truthfulScanName={truthfulScanName}/>
        </div>
    )
}