import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import diff_legend from '../assets/diff_legend.png';
import covlegend from '../assets/legend.jpeg';
import { useMapState } from '../context/MapStateContext';

// public key on Mapbox website
mapboxgl.accessToken = 'pk.eyJ1IjoiYnJ5YW50LW9yY2hhcmQiLCJhIjoiY2xicXRnN2phMGxwdDNwcGNtdmJ3ZWN3YiJ9.F0kjsae7uDRFJpGuhfSh_Q';

const LegendWrapper = ({ children }) => (
    <div className="bg-white m-3 border flex flex-row bottom-0 border-gray-900 rounded-lg font-lato whitespace-nowrap" style={{ width: '500px' }}>
        {children}
    </div>
);

export const Legend = () => (
    <LegendWrapper>
        <span className='text-center'>Less fruit</span>
        <img src={diff_legend} alt='color scale legend for plots' width={300} className='mx-2 border-x border-gray-900' />
        <span className='text-center'>More fruit</span>
    </LegendWrapper>
)

export const CoverageLegend = () => (
    <LegendWrapper>
        <span className='text-center'>50% color coverage</span>
        <img src={covlegend} alt='color scale legend for plots' width={300} className='mx-2 border-x border-gray-900' />
        <span className='text-center'>100% color coverage</span>
    </LegendWrapper>
)

export const SizeLegend = ({ sizeDiffAverage }) => (
    <LegendWrapper>
        <span className='text-center'>Less Size Diff</span>
        <img src={diff_legend} alt='color scale legend for plots' width={200} className='mx-2 border-x border-gray-900' />
        <span className='text-center'>More Size Diff</span>
        <div className='fixed bottom-6 bg-white m-3 w-fit left-1/3 right-0 mx-auto px-2 border border-gray-900 rounded-lg font-lato'>
            <span className='text-center mt-1 pr-1'>Average Size Diff: {sizeDiffAverage.toFixed(0)} mm</span>
        </div>
    </LegendWrapper>
)

const StyledTextField = styled(TextField)({
    '& input[type=number]': {
        MozAppearance: 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    }
});

const StandardLegend = ({
    config,
    lowerBound,
    upperBound,
    setLowerBound,
    setUpperBound,
    applyPercentage,
    convertToPercentOfTarget,
    selectAll,
    stats
}) => (
    <div className='flex flex-col items-center'>
        <div className='text-sm mb-1'>{config.caption} {config.targetVar === "uniTarg" && stats && stats.total_fruit_calibrated_estimated !== null && stats.total_fruit_calibrated_estimated !== 0 ? `(calibrated)` : ''}</div>
        <div className='relative w-full mb-1' style={{ height: '20px' }}>
            <img src={diff_legend} alt='color scale legend for plots' className='w-full h-full object-cover' />
        </div>
        <div className='flex justify-between w-full'>
            <StyledTextField
                type="number"
                value={applyPercentage(lowerBound)}
                onChange={(e) => convertToPercentOfTarget(e, setLowerBound)}
                onFocus={selectAll}
                InputProps={{
                    endAdornment: <span>{config.unit}</span>,
                }}
                size="small"
                style={{ width: '70px' }}
                variant="standard"
            />
            <div className='flex items-center'>
                {applyPercentage((parseFloat(lowerBound) + parseFloat(upperBound)) / 2)}
                <span className='ml-1'>{config.unit}</span>
            </div>
            <StyledTextField
                type="number"
                value={applyPercentage(upperBound)}
                onChange={(e) => convertToPercentOfTarget(e, setUpperBound)}
                onFocus={selectAll}
                InputProps={{
                    endAdornment: <span>{config.unit}</span>,
                }}
                size="small"
                style={{ width: '70px' }}
                variant="standard"
            />
        </div>
    </div>
);

const ZoneMapLegend = ({
    config,
    lowerBound,
    upperBound,
    setLowerBound,
    setUpperBound,
    applyPercentage,
    convertToPercentOfTarget,
    selectAll,
    treeData,
}) => {

    const treeMetric = config.targetVar === "uniTarg" ? "num_buds"
        : config.targetVar === "uniSizeTarg" ? "avg_diam"
            : config.targetVar === "vigorTarg" ? "canopy_area_m2"
                : null;

    const [zonePercentages, setZonePercentages] = useState({ red: 0, green: 0, blue: 0 });

    useEffect(() => {
        const calculateZonePercentages = () => {
            console.log("config", config);
            console.log("lowerBound", lowerBound);

            if (!treeData || !treeData.features) return { red: 0, green: 0, blue: 0 };

            let redCount = 0, greenCount = 0, blueCount = 0;
            let validTreeCount = 0;

            treeData.features.forEach(feature => {
                const value = feature.properties[treeMetric];

                // Check if the value is not null and not undefined
                if (value !== null && value !== undefined) {
                    validTreeCount++;

                    if (value < applyPercentage(lowerBound)) {
                        redCount++;
                    } else if (value >= applyPercentage(lowerBound) && value < applyPercentage(upperBound)) {
                        greenCount++;
                    } else {
                        blueCount++;
                    }
                }
            });

            return {
                red: validTreeCount > 0 ? (redCount / validTreeCount) * 100 : 0,
                green: validTreeCount > 0 ? (greenCount / validTreeCount) * 100 : 0,
                blue: validTreeCount > 0 ? (blueCount / validTreeCount) * 100 : 0
            };
        };

        setZonePercentages(calculateZonePercentages());
    }, [treeData, lowerBound, upperBound, treeMetric]);

    return (
        <div className='flex flex-col items-center'>
            <div className='text-sm mb-1'>{config.caption}</div>
            <div className='w-full h-6 flex mb-1 relative'>
                <div className='flex-1 bg-red-500 flex items-center justify-center'>
                    <span className='text-white text-xs font-bold' style={{ textShadow: '-0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000' }}>Low - {zonePercentages.red.toFixed(2)}%</span>
                </div>
                <div className='flex-1 bg-green-500 flex items-center justify-center'>
                    <span className='text-white text-xs font-bold' style={{ textShadow: '-0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000' }}>Target - {zonePercentages.green.toFixed(2)}%</span>
                </div>
                <div className='flex-1 bg-blue-500 flex items-center justify-center'>
                    <span className='text-white text-xs font-bold' style={{ textShadow: '-0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000' }}>High - {zonePercentages.blue.toFixed(2)}%</span>
                </div>
            </div>
            <div className='flex justify-between w-full'>
                <div className='flex items-center' style={{ width: '44%', justifyContent: 'flex-end' }}>
                    <StyledTextField
                        type="number"
                        value={applyPercentage(lowerBound)}
                        onChange={(e) => convertToPercentOfTarget(e, setLowerBound)}
                        onFocus={selectAll}
                        InputProps={{
                            endAdornment: <span>{config.unit}</span>,
                        }}
                        size="small"
                        style={{ width: '70px' }}
                        variant="standard"
                    />
                </div>
                <div className='flex items-center' style={{ width: '38%', justifyContent: 'flex-start' }}>
                    <StyledTextField
                        type="number"
                        value={applyPercentage(upperBound)}
                        onChange={(e) => convertToPercentOfTarget(e, setUpperBound)}
                        onFocus={selectAll}
                        InputProps={{
                            endAdornment: <span>{config.unit}</span>,
                        }}
                        size="small"
                        style={{ width: '70px' }}
                        variant="standard"
                    />
                </div>
            </div>
        </div>
    );
};

export const LegendRenderer = ({
    plotType,
    uniTarg,
    uniSizeTarg,
    heightTarg,
    spacingTarg,
    vigorTarg,
    treeDiamTarg,
    lowerBound,
    upperBound,
    setLowerBound,
    setUpperBound,
    viewZoneMap,
    treeData,
    stats,
}) => {

    const { plotConfigs } = useMapState();
    const config = plotConfigs[plotType];
    if (!config) return null;

    const targetValue = config.targetVar === "heightTarg" ? heightTarg
        : config.targetVar === "spacingTarg" ? spacingTarg
            : config.targetVar === "vigorTarg" ? vigorTarg
                : config.targetVar === "treeDiamTarg" ? treeDiamTarg
                    : config.targetVar === "uniTarg" ? uniTarg
                        : config.targetVar === "uniSizeTarg" ? uniSizeTarg
                            : null;

    const convertToPercentOfTarget = (event, setBound) => {
        const value = event.target.value;

        if (value === '') {
            setBound('');
        } else {
            const numericValue = parseFloat(value);
            const percentage = (numericValue / targetValue) * 100;
            setBound(percentage);
        }
    };

    const applyPercentage = (bound) => {
        if (bound === '' || isNaN(parseFloat(bound))) return '';
        const value = (parseFloat(bound) / 100) * targetValue;
        return config.unit ? Number(value.toFixed(2)) : Math.round(value);
    };

    const selectAll = (event) => {
        event.target.select();
    };

    const commonLegendProps = {
        config,
        lowerBound,
        upperBound,
        setLowerBound,
        setUpperBound,
        applyPercentage,
        convertToPercentOfTarget,
        selectAll,
        stats,
    };

    return (
        <div className='absolute left-1/2 transform -translate-x-1/2 bottom-0 bg-white rounded-md font-lato pr-2 pl-2 pb-2 pt-1 mb-1 shadow-lg' style={{ width: '450px' }}>
            {viewZoneMap ? <ZoneMapLegend {...commonLegendProps} treeData={treeData} /> : <StandardLegend {...commonLegendProps} />}
        </div>
    );
}