import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { PolygonStats } from '../sidebar/PolygonStats';

function capitalizeFirstLetter(string) {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const TreeImageDialog = ({ open, setOpen, treeId, imageURL }) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogContent>
                <div className="flex justify-between items-center">
                    <p className='text-xl'>Tree Preview Image: {treeId} </p>
                    <IconButton edge="end" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>
                <p className='text-md text-slate-500'>Image is cropped to fit on screen. </p>
                <img className='mb-4' src={imageURL} alt="map point" />
            </DialogContent>
        </Dialog>
    )
}

export const PolygonAreaStatsDialog = ({ polygonStats, entity, setAddToSidebar, polygonStatDialog, setPolygonStatDialog, entityType, truthfulScanName, stageType }) => {
    return (
        <Dialog
            maxWidth='md'
            open={polygonStatDialog}
            onClose={() => setPolygonStatDialog(false)} >
            <div className='font-lato mt-2 min-w-[35vw] px-8 py-3 '>
                <p className='text-xl font-bold'>Area Selection {capitalizeFirstLetter(entityType)} Scan Statistics</p>
                <p className='text-xs mb-2 text-slate-500'>View statistics about the selected area of the scan.</p>
                <PolygonStats entityType={entity} stats={polygonStats} stageType={stageType} />
            </div>
            <DialogActions>
                <Button onClick={() => {
                    setAddToSidebar(true);
                    setPolygonStatDialog(false);
                }} color="primary">
                    View in Sidebar
                </Button>
                <Button onClick={() => setPolygonStatDialog(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export const AreaSelectionAlertDialog = ({ setDrawDialog, drawDialog }) => {
    return (
        <Dialog
            maxWidth='sm'
            open={drawDialog}
            onClose={() => setDrawDialog(false)}
        >
            <DialogTitle id="alert-dialog-title">
                {"Area Selection Stats Interface"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You have enabled the Area Selection Stats feature.
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    1. To use this feature, draw a polygon around the area of this current scan that you would like to see statistics about.
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    2. A popup will appear once the statistics have been calculated, and you can choose to view them in the sidebar with other statistics.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDrawDialog(false)} color="primary">
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const AreaSelectionConfirmationDialog = ({ confirmDialog, setConfirmDialog, fireAxiosCallRef }) => {
    return (
        <Dialog
            open={confirmDialog}
            onClose={() => setConfirmDialog(false)}
        >
            <DialogTitle>Area Selection Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This will start a new calculation for the selected area. Are you sure you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmDialog(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={fireAxiosCallRef.current} color="primary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export const SizeTargetAlertDialog = ({ sizeTargHeatmapDialog, setSizeTargHeatmapDialog }) => {
    return (
        <Dialog
            maxWidth='md'
            open={sizeTargHeatmapDialog}
            onClose={() => setSizeTargHeatmapDialog(false)}
        >
            <DialogTitle>Alert!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span className='text-red-500'> This feature is available <b>ONLY for fruit scans </b> (which are colored blue in the Block Timeline).</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSizeTargHeatmapDialog(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export const RowVarietyWarningDialog = ({ rowVarietyWarningDialog, setRowVarietyWarningDialog }) => {
    return (
        <Dialog
            maxWidth='md'
            open={rowVarietyWarningDialog}
            onClose={() => setRowVarietyWarningDialog(false)}
        >
            <DialogTitle>Alert!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You cannot use this feature without selecting at least one variety to filter by.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button fullWidth variant='contained' onClick={() => setRowVarietyWarningDialog(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const NoScansWarningDialog = ({ noScanWarningDialog, setNoScanWarningDialog }) => {
    return (
        <Dialog
            maxWidth='md'
            open={noScanWarningDialog}
            onClose={() => setNoScanWarningDialog(false)}
        >
            <h1 className='py-3 text-2xl px-4'>Alert!</h1>

            <p className='px-4 text-sm text-center'>There are no scans to view in this block. </p>
            <div className='px-4 py-3'>
                <Button fullWidth variant='contained' onClick={() => setNoScanWarningDialog(false)} color="primary">
                    Close
                </Button>
            </div>

        </Dialog>
    )
}

export const RowVarietyDialog = ({ rowVarietyDialog, setRowVarietyDialog }) => {
    return (
        <Dialog
            maxWidth='md'
            open={rowVarietyDialog}
            onClose={() => setRowVarietyDialog(false)}
        >
            <DialogTitle>Alert!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You have pressed the variety filter, which allows you to view variety-specific statistics, heatmap, and graphs.
                </DialogContentText>
                <DialogContentText>
                    <span className='text-red-500'> This feature is available <b>ONLY </b> after you have submitted your varieties and corresponding rows in the Variety Interface in the left Sidebar.</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button fullWidth variant='contained' onClick={() => setRowVarietyDialog(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const FruitSizeDiffDialog = ({ setSizeDiffAverage, setSizeDiffData, blockFruitScans, sizeDiffDialog, setSizeDiffDialog, setSizeScan1, setSizeScan2, sizeScan2, sizeScan1 }) => {

    const handleSizeDiff = () => {
        axios.post('/user/size_diff_heatmap', {
            scan1: sizeScan1,
            scan2: sizeScan2
        })
            .then(response => {
                let total = 0;
                let count = 0;
                for (let feature of response.data.features) {
                    if (feature.properties.diameter_diff) {
                        total += feature.properties.diameter_diff;
                        count++;
                    }
                }
                let average = total / count;
                setSizeDiffAverage(average)
                console.log("SIZE DIFF AVG", average)
                setSizeDiffData(response.data)
                console.log("COMP GEOJSON", response.data);
            })
            .catch(error => {
                console.error(error);
            });
        setTimeout(() => {
            setSizeDiffDialog(false);
            setSizeScan1('')
            setSizeScan2('')
        }, 2000);
    };

    return (
        <Dialog
            maxWidth='md'
            open={sizeDiffDialog}
            onClose={() => setSizeDiffDialog(false)}
        >
            <DialogTitle>Fruit Size Difference Heatmap Visualizer</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span className='text-red-500'> This feature is available <b>ONLY for fruit scans </b> (which are colored blue in the Block Timeline).</span>
                </DialogContentText>
                <DialogContentText>
                    Select two fruit scans within this block to visualize the fruit size differences between scans. <b className='text-red-500'> Select Scan 1 to be the more recent of the two scans. </b>
                </DialogContentText>

                <FormControl variant="outlined" style={{ minWidth: 300 }}>
                    <InputLabel id="scan1-label">Recent Scan to Compare</InputLabel>
                    <Select
                        labelId="scan1-label"
                        value={sizeScan1}
                        onChange={e => setSizeScan1(e.target.value)}
                        label="Scan 1"
                    >
                        {blockFruitScans.length > 0 && blockFruitScans.map((scan, index) => (
                            <MenuItem value={scan} key={index}>{scan}</MenuItem>
                        ))}

                    </Select>
                </FormControl>

                <FormControl variant="outlined" style={{ minWidth: 300 }}>
                    <InputLabel id="scan2-label">Past Scan to Compare</InputLabel>
                    <Select
                        labelId="scan2-label"
                        value={sizeScan2}
                        onChange={e => setSizeScan2(e.target.value)}
                        label="Scan 2"
                    >
                        {blockFruitScans.length > 0 && blockFruitScans.map((scan, index) => (
                            <MenuItem value={scan} key={index}>{scan}</MenuItem>
                        ))}

                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSizeDiffDialog(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSizeDiff} color="primary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const ManualCalibrationDialog = ({ manualCalibrationDialog, setManualCalibrationDialog, selectedCalibrationSection, orchardCode, truthfulScanName }) => {
    const [manualCount, setManualCount] = useState(0);
    const [detectedCount, setDetectedCount] = useState(0);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);

    useEffect(() => {
        if (selectedCalibrationSection !== null) {
            setManualCount(selectedCalibrationSection.manual_count)
            setDetectedCount(selectedCalibrationSection.detections)
        }
    }, [selectedCalibrationSection]);

    const handleManualCalibration = async () => {
        let updatePromises = []
        if (selectedCalibrationSection.manual_count !== manualCount) {
            updatePromises.push(
                axios.post('/calib/manual_count', {}, {
                    params: {
                        count: manualCount,
                        manual_count_id: selectedCalibrationSection.manual_count_id,
                        orchard_code: orchardCode,
                    }
                })
                    .then(response => {
                        // Do stuff
                    })
                    .catch(error => {
                        console.error(error);
                    })
            );
        }
        if (selectedCalibrationSection.detections !== detectedCount) {
            updatePromises.push(
                axios.post('/calib/set_detected_count', {
                    count: detectedCount,
                    detected_count_id: selectedCalibrationSection.detected_count_id,
                    orchard_code: orchardCode,
                })
                    .then(response => {
                        // Do stuff
                    })
                    .catch(error => {
                        console.error(error);
                    })
            );
        }
        await Promise.all(updatePromises);
        setManualCalibrationDialog(false);
    };

    const handleDeleteCalibrationSection = async () => {
        if (!selectedCalibrationSection || !selectedCalibrationSection.section_id) {
            console.error("No calibration section selected or section_id is missing");
            return;
        }

        try {
            const response = await axios.post(`/calibration_section/${selectedCalibrationSection.section_id}`);
            await axios.post('/scans/delete_pregenerated', { orchard_code: orchardCode, raw_scan_name: truthfulScanName });

            console.log(response.data.message);
            setManualCalibrationDialog(false);
            setDeleteConfirmOpen(false);
            setDeleteSuccessOpen(true);
        } catch (error) {
            console.error("Error deleting calibration section:", error.response ? error.response.data : error.message);
        }
    };

    return (
        <>
            <Dialog
                maxWidth='md'
                open={manualCalibrationDialog}
                onClose={() => setManualCalibrationDialog(false)}
            >
                <DialogTitle>Calibration Count Modifier</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the counts and hit confirm to change them. To view the changes you must pregenerate in the admin console.
                    </DialogContentText>
                    <br />

                    <FormControl variant="outlined" style={{ minWidth: 300 }}>
                        <TextField
                            value={manualCount}
                            onChange={e => setManualCount(parseInt(e.target.value))}
                            label="Manual Count"
                            variant="outlined"
                            style={{ width: '300px' }}
                        />
                    </FormControl>

                    <FormControl variant="outlined" style={{ minWidth: 300 }}>
                        <TextField
                            value={detectedCount}
                            onChange={e => setDetectedCount(parseInt(e.target.value))}
                            label="Detected Count"
                            variant="outlined"
                            style={{ width: '300px' }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setManualCalibrationDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleManualCalibration} color="primary">
                        Confirm
                    </Button>
                    <Button
                        onClick={() => setDeleteConfirmOpen(true)}
                        color="error"
                        variant="contained"
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '10px 10px',
                            fontSize: '1em'
                        }}
                    >
                        Delete Section
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this calibration section? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteCalibrationSection} color="error" autoFocus>
                        Confirm Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteSuccessOpen}
                onClose={() => setDeleteSuccessOpen(false)}
            >
                <DialogTitle>Deletion Successful</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Successfully deleted calibration section. Please refresh to see the changes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteSuccessOpen(false)} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}