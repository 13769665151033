/* Stores states of the current mapbox render, to be shared between Sidebar.js & Mapbox.js. Originally implemented for the zone heatmap shareable link feature*/
import convert from 'convert-units';
import mapboxgl from 'mapbox-gl';
import React, { createContext, MutableRefObject, useContext, useRef, useState } from 'react';

interface MapStateContextType {
  lowerBound: number;
  setLowerBound: React.Dispatch<React.SetStateAction<number>>;
  upperBound: number;
  setUpperBound: React.Dispatch<React.SetStateAction<number>>;
  viewZoneMap: boolean;
  setViewZoneMap: React.Dispatch<React.SetStateAction<boolean>>;
  map: MutableRefObject<mapboxgl.Map | null>;
  plotConfigs: {
    [key: string]: {
      caption: string;
      targetVar: string;
      feature: string;
      unit?: string;
    };
  };
  isImperial: boolean;
  setIsImperial: React.Dispatch<React.SetStateAction<boolean>>;
}

const MapStateContext = createContext<MapStateContextType | undefined>(undefined);

export const MapStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [lowerBound, setLowerBound] = useState(60);
  const [upperBound, setUpperBound] = useState(140);
  const [viewZoneMap, setViewZoneMap] = useState(false);
  const [isImperial, setIsImperial] = useState(true);
  const map = useRef<mapboxgl.Map | null>(null);

  const plotConfigs = {
    'height': {
        caption: 'Canopy Height',
        targetVar: 'heightTarg',
        feature: 'canopy_height_m',
        unit: 'm',
        legend: 'standard'
    },
    'spacing': {
        caption: 'Trunk Spacing',
        targetVar: 'spacingTarg',
        feature: 'tree_spacing',
        unit: 'ft',
        legend: 'standard'
    },
    'tree-vigor': {
        caption: 'Canopy Area',
        targetVar: 'vigorTarg',
        feature: 'canopy_area_m2',
        unit: 'm\u00B2',
        legend: 'standard'
    },
    'tree_diam': {
        caption: 'Trunk Diameter',
        targetVar: 'treeDiamTarg',
        feature: 'width',
        unit: 'in',
        legend: 'standard'
    },
    'uniform': {
        caption: 'Fruits per Tree',
        feature: 'num_buds',
        targetVar: 'uniTarg',
        unit: 'fruits',
        legend: 'standard'
    },
    'size': {
        caption: 'Fruit Size per Tree',
        targetVar: 'uniSizeTarg',
        feature: 'avg_diam', 
        unit: 'mm',
        legend: 'standard'
    },
    'rld': {
      caption: 'RLD',
      targetVar: 'rldTarg',
      feature: 'rld',
      unit: isImperial ? 'ft²' : 'm²',
      legend: 'RLD',
      convert: (value: number) => {
        return isImperial
          ? convert(value).from('m2').to('ft2')
          : convert(value).from('ft2').to('m2');
      }
    }
  };

  return (
    <MapStateContext.Provider value={{
      lowerBound,
      setLowerBound,
      upperBound,
      setUpperBound,
      viewZoneMap,
      setViewZoneMap,
      map,
      plotConfigs,
      isImperial,
      setIsImperial,
    }}>
      {children}
    </MapStateContext.Provider>
  );
};

export const useMapState = () => {
  const context = useContext(MapStateContext);
  if (context === undefined) {
    throw new Error('useMapState must be used within a MapStateProvider');
  }
  return context;
};