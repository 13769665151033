import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const EstimateBlockToggle = ({ estimateBlock, setEstimateBlock }) => {

    const handleEstimateBlockToggle = (event) => {
        setEstimateBlock(event.target.checked);
    };
    
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={estimateBlock}
                    onChange={handleEstimateBlockToggle}
                />
            }
            label={"Extrapolate to Full Block"}
        />
    );
};

export default EstimateBlockToggle;