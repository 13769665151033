import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Card } from './Cards';

const packoutSizes = [48, 56, 64, 72, 80, 88, 100, 113, 125, 138, 150];

export const PackoutCard = ({ stats }) => {
  const [packValues, setPackValues] = useState(
    packoutSizes.reduce((obj, size) => {
      obj[size] = '';
      return obj;
    }, {})
  );

  // Inputs
  const [averageBinWeight, setAverageBinWeight] = useState('');
  const [weightPerBox, setWeightPerBox] = useState(40);
  const [cullagePercent, setCullagePercent] = useState(5);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [binsPerAcre, setBinsPerAcre] = useState('');
  const [totalBins, setTotalBins] = useState(0);

  // Calculations
  const [totalFruitAfterCullage, setTotalFruitAfterCullage] = useState(0);

  const [totalFruitWeight, setTotalFruitWeight] = useState(0);

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    const total = Object.values(packValues).reduce((sum, value) => {
      return Number(sum) + Number(value);
    }, 0);
    // convert total to a number before setting
    setTotalPercentage(total);
  }, [packValues]);

  const handleInputChange = (event, size) => {
    let value = event.target.value;
    if (value.length > 1) {
      value = value.replace(/^0+/, '');
      event.target.value = value;
    }
    if (value === '') {
      setPackValues((prevValues) => ({
        ...prevValues,
        [size]: '',
      }));
    } else if (value >= 0 && value <= 100) {
      setPackValues((prevValues) => ({
        ...prevValues,
        [size]: parseFloat(value),
      }));
    }
  };


  const updateAverageBinWeight = (event) => {
    const value = event.target.value;
    if (value > 0) {
      setAverageBinWeight(value);
    } else {
      setAverageBinWeight('');
    }
  };

  const updateWeightPerBox = (event) => {
    const value = event.target.value;
    if (value > 0) {
      setWeightPerBox(value);
    } else {
      setWeightPerBox('');
    }
  };

  const updateCullagePercent = (event) => {
    const value = event.target.value;
    if (value >= 0 && value <= 100) {
      setCullagePercent(value);
    } else {
      setCullagePercent('');
    }
  };

  const calculateBinsPerAcre = async () => {
    setTotalFruitWeight(0);
    setTotalFruitAfterCullage(0);

    if (averageBinWeight === '' || averageBinWeight <= 0) {
      toast.error('Average bin weight must be greater than 0');
      return;
    }
    if (cullagePercent === '') {
      toast.error('Cullage percent must be provided');
      return;
    }
    if (stats.total_fruit_calibrated_estimated === 0) {
      toast.error('No fruit calibrations available');
      return;
    }
    if (averageBinWeight < weightPerBox) {
      toast.error('Average bin weight must be greater than weight per box');
      return;
    }
    setLoading(true)
    setShowModal(true);

    // Given: Total Fruit, Fruit volume distribution (calculated from packout values), and average bin weight
    console.log("Pack Values", packValues);
    console.log("total fruit", stats.total_fruit_calibrated_estimated);

    // Apply cullage percentage to the total fruit count
    const totalFruitAfterCullage = stats.total_fruit_calibrated_estimated * (1 - cullagePercent / 100);

    // For each packoutValue key, multiply the value as a percentage by the totalFruitAfterCullage to get fruit counts per key
    const fruitCounts = Object.keys(packValues).reduce((obj, size) => {
      obj[size] = packValues[size] / 100 * totalFruitAfterCullage;
      return obj;
    }, {});

    // Get total fruit weight per key by multiplying the value for each key by (weightPerBox(40) / size)
    const fruitWeights = Object.keys(fruitCounts).reduce((obj, size) => {
      obj[size] = fruitCounts[size] * (weightPerBox / parseInt(size, 10));
      return obj;
    }, {});

    // Sum the total fruit weight per key to get the total fruit weight
    const totalFruitWeight = Object.values(fruitWeights).reduce((sum, weight) => {
      return sum + weight;
    });

    const totalBins = totalFruitWeight / averageBinWeight;
    const binsPerAcre = totalBins / stats.block_acreage;

    // Modal output timing
    setTotalFruitAfterCullage(totalFruitAfterCullage);
    setTotalFruitWeight(totalFruitWeight);
    setBinsPerAcre(binsPerAcre.toFixed(2));
    setTotalBins(totalBins.toFixed(2));
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setLoading(false);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const nextIndex = (index + 1) % packoutSizes.length;
      const nextSize = packoutSizes[nextIndex];
      const nextInput = document.getElementById(`input-${nextSize}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  return (
    <Card
      title="Packout Estimate (from fruit count)"
      showButton
      defaultOpen={true}
      description={
        stats.total_fruit_calibrated_estimated !== 0
          ? <>
            Estimate bins per acre using historical packout data, and extrapolated calibrated fruit counts (See fruit scan statistics). <strong> This estimate should only be used for scans taken after the last round of thinning.</strong> Only after more fruit scans are taken, and a growth curve is established, you can also calculate a predicted size distribution for harvest.
          </>
          : 'Estimate bins per acre using historical packout data.'
      }
    >

      {stats.total_fruit_calibrated_estimated !== 0 ? (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
              <p style={{ fontSize: '14px' }}>Historical Avg Bin Weight</p>
              <OutlinedInput
                type="number"
                size="small"
                style={{ width: '110px' }}
                value={averageBinWeight}
                onChange={updateAverageBinWeight}
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  style: { textAlign: 'center' },
                  min: 0
                }}
                endAdornment={<InputAdornment position="end">lbs</InputAdornment>}

              />
              <p style={{ margin: '5px 0', fontSize: '14px' }}>Weight per box</p>
              <OutlinedInput
                type="number"
                size="small"
                style={{ width: '100px' }}
                value={weightPerBox}
                onChange={updateWeightPerBox}
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  style: { textAlign: 'center' },
                  min: 0
                }}
                endAdornment={<InputAdornment position="end">lbs</InputAdornment>}

              />
              <p style={{ margin: '5px 0', fontSize: '14px' }}>Estimated In-field Cullage</p>
              <OutlinedInput
                type="number"
                size="small"
                style={{ width: '100px' }}
                value={cullagePercent}
                onChange={updateCullagePercent}
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  style: { textAlign: 'center' },
                  min: 0,
                  max: 100
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
              <p style={{ margin: '5px 0', fontSize: '14px' }}>Historical Pack Size (by weight) Distribution</p>
              <TableContainer align="center">
                <Table size="small" padding="none" sx={{ width: 'auto', margin: '0 auto' }}>
                  <TableBody>
                    {packoutSizes.map((size, index) => (
                      <TableRow key={size}>
                        <TableCell component="th" scope="row" align="center" padding="none">
                          {size}
                        </TableCell>
                        <TableCell align="center" padding="none" sx={{ width: '120px' }}>
                          <OutlinedInput
                            type="number"
                            size="small"
                            value={packValues[size]}
                            onChange={(event) => handleInputChange(event, size)}
                            onKeyDown={(event) => handleKeyDown(event, index)}
                            onWheel={(e) => e.target.blur()}
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            inputProps={{
                              style: { textAlign: 'center' },
                              min: 0,
                              max: 100,
                              id: `input-${size}`,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5px' }}>
            <p>Total Percentage: {totalPercentage}%</p>
            <Button
              variant="contained"
              onClick={calculateBinsPerAcre}
              style={{ marginTop: '5px' }}
              disabled={totalPercentage > 100 || totalPercentage === 0}
            >
              Submit
            </Button>
          </div>
        </div>

      ) : (
        <div className="bg-yellow-50 border border-yellow-100 text-yellow-700 px-4 py-3 rounded text-center" role="alert">
          <p className="font-medium">
            Ground truth counts required for this calculation
          </p>
        </div>
      )
      }

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>Packout Estimation</DialogTitle>
        <DialogContent>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={Object.entries(packValues).map(([size, value]) => ({ size, value }))}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="size" label={{ value: 'Pack Size', position: 'insideBottomRight', offset: -5 }} />
                  <YAxis label={{ value: 'Percentage', angle: -90, position: 'insideLeft' }} />
                  <Tooltip />
                  <Bar dataKey="value" fill="0000FF" />
                </BarChart>
              </ResponsiveContainer>
              {stats.total_fruit_calibrated_estimated !== 0 && (
                <p style={{ marginTop: '10px' }}>
                  Extrapolated Calibrated Fruit: {stats.total_fruit_calibrated_estimated.toLocaleString(undefined, { maximumFractionDigits: 0 })} fruits
                </p>
              )}
              {totalPercentage === 100 ? (
                <p style={{ marginTop: '10px' }}>
                  Fruit After Cullage: {totalFruitAfterCullage.toLocaleString(undefined, { maximumFractionDigits: 0 })} fruits
                </p>
              ) : (
                <p style={{ marginTop: '10px' }}>
                  {totalPercentage}% of Fruit After Cullage: {(totalFruitAfterCullage * (totalPercentage / 100)).toLocaleString(undefined, { maximumFractionDigits: 0 })} fruits
                </p>
              )}
              <p style={{ marginTop: '10px' }}>
                Estimated Total Fruit Weight: {totalFruitWeight.toLocaleString(undefined, { maximumFractionDigits: 2 })} lbs
              </p>
              {totalBins !== 0 && (
                <p style={{ marginTop: '10px' }}>
                  Estimated Total Bins: {parseFloat(totalBins).toLocaleString(undefined, { maximumFractionDigits: 2 })} bins
                </p>
              )}
              {binsPerAcre && (
                <p style={{ marginTop: '10px' }}>
                  Estimated Bins per Acre: {Number(binsPerAcre).toFixed(2)} bins/acre
                </p>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </Card >
  );
};