import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { EntityType } from '../common/types';
import { Card } from './Cards';
import { ExportCard } from './ExportCard';

export const DownloadCard = ({ plotType, setPlotType, selectedVarieties, entityType, handleDownloadChange, downloadFormat, truthfulScanName, mapInstance, blockName, treeData, stats }) => {

    const getActionOptions = () => {
        if (entityType === EntityType.Fruits) {
            return [
                { value: 'Map_Image', label: `Map Image` },
                // { value: 'geojson', label: `Tree Geojson` },
                { value: 'CSV', label: 'Per-tree Spreadsheet' },
                { value: 'fruit_csv', label: 'Per-fruit Spreadsheet' },
                // { value: 'Bulk_Geo', label: 'Bulk Heatmap' },
                { value: 'smoothed', label: 'Spatial Region Heatmap' },
                { value: 'discrete', label: 'Zone Heatmap' },
                { value: 'share_geojson', label: <span>Shareable Link to Map<sup className="text-red-500 text-xs font-bold ml-1">New!</sup></span> },
                // { value: 'Report', label: 'Predicted Packout Report' },
            ];
        }
        return [
            { value: 'Map_Image', label: `Map Image` }, //Currently Broken
            { value: 'CSV', label: 'Per-tree Spreadsheet' },
            { value: 'Bulk_Geo', label: 'Bulk Heatmap' }, //Currently Broken
            { value: 'smoothed', label: 'Spatial Region Heatmap' },
            { value: 'discrete', label: 'Zone Heatmap' },
            { value: 'share_geojson', label: 'Shareable Link to Map' },
        ];
    };

    return (
        <Card title="Actions" showButton defaultOpen={true} description={'Select a file format to download or export data in.'}>
            <Box sx={{ minWidth: 100 }} className='py-4'>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Download Format</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={downloadFormat}
                        label="Download Format"
                        onChange={handleDownloadChange}
                    >
                        {getActionOptions().map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <ExportCard
                plotType={plotType}
                entityType={entityType}
                setPlotType={setPlotType}
                selectedVarieties={selectedVarieties}
                scanName={truthfulScanName}
                mapInstance={mapInstance}
                blockName={blockName}
                downloadFormat={downloadFormat}
                treeData={treeData}
                stats={stats}
            />
        </Card>
    );
}