import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import loading from './assets/loading.gif';
import { EnableDrawControl } from './map/MapControls';
import { MapboxGLDrawConfig } from './map/MapboxGLDrawConfig';

// public key on Mapbox website
mapboxgl.accessToken = 'pk.eyJ1IjoiYnJ5YW50LW9yY2hhcmQiLCJhIjoiY2xicXRnN2phMGxwdDNwcGNtdmJ3ZWN3YiJ9.F0kjsae7uDRFJpGuhfSh_Q';

const Legend = () => (
    <div className='absolute bottom-32 left-0 bg-white m-3 w-fit mx-auto px-4 py-2 border border-gray-900 rounded-lg font-lato'>
        <div className='text-lg font-semibold mb-2'>Map Legend</div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-red-500 inline-block mr-2'></span>Deleted </div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-green-500 inline-block mr-2'></span>Added </div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-cyan-300 inline-block mr-2'></span>Duplicate (Deleted)</div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-orange-500 inline-block mr-2'></span>Boundary (Deleted)</div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-yellow-500 inline-block mr-2'></span>Width Outlier (Candidate)</div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-purple-500 inline-block mr-2'></span>Row Spacing Outlier (Cand)</div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-pink-500 inline-block mr-2'></span>Row Position Outlier (Cand)</div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-neutral-500 inline-block mr-2'></span>Automatically Moved (Add)</div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-red-900 inline-block mr-2'></span>Automatically Moved (Deleted)</div>
        <div className='flex items-center mb-1'><span className='w-4 h-4 bg-sky-400 inline-block mr-2'></span>Unable to Move (Cand)</div>
    </div>
);


export const GuideBar = ({ blockName, scan }) => (
    <div className="bg-white border opacity-90 border-gray-700 text-black px-2 py-2 text-xs font-mono z-50 absolute top-10 left-0 m-2 rounded-lg">
        {scan ? <p> You are viewing scan {parseInt(scan.split("_")[1].replace(/^0+/, ""))} in block {blockName}</p> :
            <p> No scans in block {blockName}</p>}
    </div>
)

export const StyleToggle = ({ toggleStyle }) => (
    <div className="bg-white border opacity-90 border-gray-700 text-black px-2 py-2 text-xs font-mono z-50 absolute bottom-10 left-0 m-2 rounded-lg">
        <button onClick={toggleStyle}>Toggle Map Style</button>
    </div>
)

export const TreePlanter = ({ orchardCode, plotType, setPlotType, mapInstance, mapCenter, entity, setControlEnabled, setProgress, blockName, stats, truthfulScanName, controlEnabled, sidebarViewMode, setSidebarViewMode, setBlockName, blockView, setBlockView, initialLocation, setTreeData, treeData, blockData }) => {
    const initialZoom = 15.64;

    const [avgNumBuds, setAvgNumBuds] = useState(300)

    const mapContainer = useRef(null);
    const map = useRef(null);
    const draw = useRef(null);
    const [drawEnabled, setDrawEnabled] = useState(false);
    const [treesLayer, setTreesLayer] = useState(false)
    // references to event handler functions -- required to de-register them when changing scans
    const updateAreaSelectionRef = useRef(null);
    const [drawDialog, setDrawDialog] = useState(false)

    const [lng, setLng] = useState(initialLocation.long.toFixed(4));
    const [lat, setLat] = useState(initialLocation.lat.toFixed(4));
    const [zoom, setZoom] = useState(initialZoom);
    const [treeViewLong, setTreeViewLong] = useState(initialLocation.long.toFixed(4));
    const [treeViewLat, setTreeViewLat] = useState(initialLocation.lat.toFixed(4));
    const marker = useRef(null);
    const [plantedTrees, setPlantedTrees] = useState([]);
    const [loadingTrees, setLoadingTrees] = useState(false)
    const [openTreeDialog, setOpenTreeDialog] = useState(false);
    const [deleteTreeDialog, setDeleteTreeDialog] = useState(false);
    const [deletedTrees, setDeletedTrees] = useState([])
    const [style, setStyle] = useState('mapbox://styles/mapbox/satellite-streets-v9')
    const [entityType, setEntityType] = useState(entity)
    const [clickedTree, setClickedTree] = useState()
    const [auditTreeData, setAuditTreeData] = useState(null)
    const [submitSuccessDialogOpen, setSubmitSuccessDialogOpen] = useState(false);
    const [startEndPoints, setStartEndPoints] = useState([])
    const [lineConfirm, setLineConfirm] = useState(false)
    const [viewAutoMovedTrees, setViewAutoMovedTrees] = useState(false)

    useEffect(() => {

        if (blockName !== null && stats) {
            if (stats.fruit_per_tree_calibrated !== '') {
                var avgNumBud = stats.fruit_per_tree_calibrated
            }
            else {
                var avgNumBud = stats.fruit_per_tree_detected
            }
        }
        else {
            var avgNumBud = 300
        }
        if (avgNumBud < 1) {
            avgNumBud = 300
        }
        console.log("????", avgNumBud)
        setAvgNumBuds(avgNumBud)
    }, [stats])

    useEffect(() => {
        console.log(deletedTrees)
    }, [deletedTrees])

    useEffect(() => {
        (async () => {
            if (truthfulScanName === null) return;
            const scanInfo = await axios.get('/util/get_scan_info', { params: { 'scan_id': truthfulScanName } });
            const entity = scanInfo.data.entity_type ? scanInfo.data.entity_type : 'buds';
            setEntityType(entity)

            const res = await axios.get('/geojson/audit_tree_points', { params: { 'scan_id': truthfulScanName } })
            setAuditTreeData(res.data)
            console.log("audit geojson", res.data)
        })();
    }, [truthfulScanName])

    useEffect(() => {
        if (mapCenter !== null) {
            setLat(mapCenter.lat.toFixed(4));
            setLng(mapCenter.lon.toFixed(4));
            if (map.current) {
                map.current.flyTo({
                    center: [mapCenter.lon.toFixed(4), mapCenter.lat.toFixed(4)],
                    zoom: 16.7, // Set your desired zoom level
                    speed: 0.7, // Speed at which the map moves, values between 0.1 and 1
                    curve: 1, // Controls the rate of zooming, a value of 1 is linear
                    logoPosition: 'top-left',
                    preserveDrawingBuffer: true
                });
            }
        }
    }, [mapCenter]);

    const toggleStyle = () => {
        if (style === 'mapbox://styles/mapbox/satellite-streets-v9') {
            setStyle('mapbox://styles/mapbox/outdoors-v12'); // or any other style you want to switch to
        } else {
            setStyle('mapbox://styles/mapbox/satellite-streets-v9');
        }
    }

    useEffect(() => {
        console.log("start end points", startEndPoints)
        // send data to flask
        console.log("pairs", startEndPoints);
    }, [startEndPoints]);


    const updateAreaSelection = useCallback(async (e) => {
        const data = draw.current.getAll();
        if (plotType === 'fruits') {
            console.log("drawn line", data.features[0].geometry.coordinates)
            const line = data.features[0].geometry.coordinates;
            if (line.length < 2) {
                console.log("A line needs at least two points");
                return;
            }

            // Convert to the format you specified
            let startEndPair = [
                [line[0][1], line[0][0]],  // start point
                [line[line.length - 1][1], line[line.length - 1][0]]  // end point
            ];
            console.log("pair", startEndPair)
            const newStartEndPoints = [...startEndPoints, startEndPair];
            console.log("new start end points", newStartEndPoints)

            setStartEndPoints(newStartEndPoints);
            console.log("start end points", startEndPoints)

            setLineConfirm(true)
        }
    }, [truthfulScanName, startEndPoints, setStartEndPoints, blockName, treeData, plotType]);


    const checkForPaintDoneAndEnableControl = useCallback(() => {
        if (!map.current.isStyleLoaded()) {
            setTimeout(checkForPaintDoneAndEnableControl, 200);
            return;
        }

        setControlEnabled(true);
    }, [setControlEnabled]);

    const undo = () => {
        // Remove last element from plantedTrees
        setPlantedTrees(plantedTrees.slice(0, -1));

        // Remove the last added tree from the map's data.
        const data = map.current.getSource('trees')._data;
        data.features.pop();
        map.current.getSource('trees').setData(data);

        // Close the dialog
        setOpenTreeDialog(false);
    };

    const continueAdding = () => {
        setOpenTreeDialog(false);
    };

    const handleUpdateTrees = async () => {
        setLoadingTrees(true)
        // Make the Flask call
        console.log("deleted trees sent to flask", deletedTrees)
        console.log("pleanted trees sent to flask", plantedTrees)
        try {
            const requestData = {
                orchard_code: orchardCode,
                scan_name: truthfulScanName,
                planted_trees: plantedTrees,
                deleted_trees: deletedTrees,
            };

            const response = await axios.post('/update_trees', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            console.log('Success:', response.data);
            setSubmitSuccessDialogOpen(true);
        } catch (error) {
            console.error('Error:', error);
        }
        // Close the dialog
        setPlantedTrees([])
        setDeletedTrees([])
        setOpenTreeDialog(false);
        setLoadingTrees(false)
    };

    const handleRefetch = async () => {
        setSubmitSuccessDialogOpen(false);
        setOpenTreeDialog(false);
        setDeleteTreeDialog(false);
        const validTrees = await axios.get('/geojson/points', { params: { 'orchard_code': orchardCode, 'scan_id': truthfulScanName } })
        setTreeData(validTrees.data)
        const auditTrees = await axios.get('/geojson/audit_tree_points', { params: { 'scan_id': truthfulScanName } })
        setAuditTreeData(auditTrees.data)
        redrawMap();
    }

    const submitLineString = async () => {
        console.log("line points to flask", startEndPoints)
        try {
            const requestData = {
                scan_id: truthfulScanName,
                startEndPoints: startEndPoints,
            };

            const response = await axios.post('/tree_generator', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            console.log('Success:', response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleDelete = () => {
        const clickedTreeNum = clickedTree.properties.tree_id;
        const deletedTreeIndex = deletedTrees.findIndex(tree => tree.properties.tree_id === clickedTreeNum);

        const data = map.current.getSource('trees')._data;
        const treeInMapIndex = data.features.findIndex(feature => feature.properties.tree_id === clickedTreeNum);
    
        data.features[treeInMapIndex].properties.audit_type = 'delete';

        setDeletedTrees(deletedTrees => [...deletedTrees, data.features[treeInMapIndex]]);

        map.current.getSource('trees').setData(data);

        setClickedTree(null);
        setDeleteTreeDialog(false);
    };

    function redrawMap() {
        if (blockView !== true) {
            map.current.remove()
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: style,
                center: [initialLocation.long, initialLocation.lat],
                zoom: 16,
                attributionControl: false,
                logoPosition: 'top-left',
            });
        }
        else {
            map.current.remove()
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: style,
                center: [treeViewLong, treeViewLat],
                zoom: 17,
                attributionControl: false,
                logoPosition: 'top-left',
                preserveDrawingBuffer: true,
            });
        }
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
            map.current.setMaxZoom(22)
            map.current.setMinZoom(16)
        });

        function removeLayers(layers) {
            layers.forEach(layer => {
                if (map.current.getLayer(layer)) {
                    map.current.removeLayer(layer);
                }
            });
        }

        if (plotType === 'regular') {
            map.current.on('load', () => {
                console.log("TREEDATA", treeData)
                mapInstance.current = map.current;
                removeLayers(['trees-point-diff', 'trees-point-uni']); // Remove other layers before adding new layer
                map.current.addSource('trees', {
                    'type': 'geojson',
                    'data': treeData
                });
                updateAreaSelectionRef.current = updateAreaSelection;
                map.current.on('draw.create', updateAreaSelectionRef.current);
                map.current.on('draw.update', updateAreaSelectionRef.current);
                map.current.on('draw.delete', updateAreaSelectionRef.current);
                map.current.addLayer({
                    'id': 'trees-point',
                    'type': 'circle',
                    'source': 'trees',
                    'minzoom': 16,
                    'paint': {
                        'circle-opacity': [
                            'case',
                            ['==', ['get', 'num_buds'], 0],
                            1,
                            1
                        ],
                        'circle-color': [
                            'case',
                            ['==', ['get', 'audit_type'], 'delete'], 'red',
                            ['==', ['get', 'audit_type'], 'add'], '#00FF00', // Green
                            [
                                "interpolate",
                                ["linear"],
                                ["get", "num_buds"],
                                0, "black",
                                1, "hsl(54, 59%, 51%)",
                                avgNumBuds * .4, "hsl(54, 59%, 51%)",
                                avgNumBuds * .8, "hsl(122, 42%, 47%)",
                                avgNumBuds * 1.2, "hsl(200, 73%, 45%)",
                                avgNumBuds * 1.6, "hsl(226, 82%, 43%)",
                                avgNumBuds * 2, "hsl(275, 89%, 25%)"
                            ]
                        ],
                        // Size circle radius by fruits and zoom level
                        'circle-radius': ['interpolate', ['linear'], ['zoom'],
                            14, 2,
                            18, 3,
                            20, 6,
                            22, 8
                        ],
                        // 'circle-opacity': 0.8,
                    }
                },
                    'waterway-label'
                );
            });
            setTreesLayer(true)
        }
        else if (plotType === 'audit') {
            map.current.on('load', () => {
                console.log("AUDIT TREEDATA", auditTreeData)
                mapInstance.current = map.current;
                removeLayers(['trees-point-diff', 'trees-point-uni']); // Remove other layers before adding new layer
                map.current.addSource('trees', {
                    'type': 'geojson',
                    'data': auditTreeData
                });
                updateAreaSelectionRef.current = updateAreaSelection;
                map.current.on('draw.create', updateAreaSelectionRef.current);
                map.current.on('draw.update', updateAreaSelectionRef.current);
                map.current.on('draw.delete', updateAreaSelectionRef.current);

                let featureFilters = ['all'];
                if (viewAutoMovedTrees == false) {
                    featureFilters.push(["!in", "audit_type", "automatically_moved_delete"]);
                }
                
                map.current.addLayer({
                    'id': 'trees-audit',
                    'type': 'circle',
                    'source': 'trees',
                    'minzoom': 16,
                    "filter": featureFilters,
                    'paint': {
                        'circle-opacity': [
                            'case',
                            ['==', ['get', 'num_buds'], 0],
                            1,
                            1
                        ],
                        'circle-color': [
                            'case', // TODO: Handle location & boundary in pregenerator
                            ['==', ['get', 'audit_type'], 'delete'], 'red',
                            ['==', ['get', 'audit_type'], 'add'], '#00FF00', // Green
                            ['==', ['get', 'audit_type'], 'location'], '#00FFFF', // Cyan
                            ['==', ['get', 'audit_type'], 'boundary'], 'orange',
                            ['==', ['get', 'audit_type'], 'row_spacing'], 'purple',
                            ['==', ['get', 'audit_type'], 'row_position'], 'pink',
                            ['==', ['get', 'audit_type'], 'automatically_moved_delete'], 'maroon',
                            ['==', ['get', 'audit_type'], 'automatically_moved_add'], '#606060',
                            ['==', ['get', 'audit_type'], 'unable_to_map_to_slot'], 'deepskyblue',
                            ['==', ['get', 'audit_type'], 'unable_to_map_to_slot_delete'], '#900C3F',
                            ['<', ['get', 'width'], 1.0], 'yellow',
                            ['>', ['get', 'width'], 4.5], 'yellow',
                            [
                                "interpolate",
                                ["linear"],
                                ["get", "num_buds"],
                                0, "gray",
                                1, "hsl(54, 59%, 51%)",
                                avgNumBuds * .4, "hsl(54, 59%, 51%)",
                                avgNumBuds * .8, "hsl(122, 42%, 47%)",
                                avgNumBuds * 1.2, "hsl(200, 73%, 45%)",
                                avgNumBuds * 1.6, "hsl(226, 82%, 43%)",
                                avgNumBuds * 2, "hsl(275, 89%, 25%)"
                            ]
                        ],
                        // Size circle radius by fruits and zoom level
                        'circle-radius': ['interpolate', ['linear'], ['zoom'],
                            14, 2,
                            18, 3,
                            20, 6,
                            22, 8
                        ],
                        // 'circle-opacity': 0.8,
                    }
                },
                    'waterway-label'
                );
            });
            setTreesLayer(true)
        }


        const center = map.current.getCenter();

        // Set the maximum distance in meters that the map can be panned from the center
        const maxDistance = 3000;

        // Get the southwest and northeast coordinates of the bounding box based on the current center and maximum distance
        const southwest = [center.lng - maxDistance / 2 / 111319.9, center.lat - maxDistance / 2 / 111319.9];
        const northeast = [center.lng + maxDistance / 2 / 111319.9, center.lat + maxDistance / 2 / 111319.9];

        // Set the maximum bounds of the map to the bounding box
        map.current.setMaxBounds([southwest, northeast]);
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
            map.current.setMaxZoom(22)
            map.current.setMinZoom(16)
        });
        let data = blockData
        data.features = data.features.map((feature, index) => {
            // Copy the feature object
            let newFeature = { ...feature };

            newFeature.id = index;

            return newFeature;
        });
        map.current.on('load', () => {
            map.current.addSource("blocks", {
                "type": "geojson",
                "data": data
            });
            map.current.addLayer({
                "id": "block-poly-2",
                "type": "fill",
                "source": "blocks",
                "layout": {},
                "paint": {
                    "fill-color":
                        "#eef0ce",
                    "fill-opacity":
                        0.2
                }
            });
            map.current.addLayer({
                "id": "state-borders-2",
                "type": "line",
                "source": "blocks",
                "layout": {},
                "paint": {
                    "line-color": "#5b84c7", // #627BC1
                    "line-width": 1 // 2
                }
            });
        });

        map.current.on('click', (e) => {
            const pointFeatures = map.current.queryRenderedFeatures(e.point, { layers: ['trees-point'] });
            const auditFeatures = map.current.queryRenderedFeatures(e.point, { layers: ['trees-audit'] });

            if (pointFeatures.length > 0 || auditFeatures.length > 0) {
                // If a point is clicked, delete the tree
                const clickedPoint = pointFeatures.length > 0 ? pointFeatures[0] : auditFeatures[0];
                const clickedTreeNum = clickedPoint.properties.tree_id;
                const cleanedPoint = {
                    type: clickedPoint.type,
                    properties: clickedPoint.properties,
                    geometry: clickedPoint.geometry,
                };

                setDeletedTrees(deletedTrees => [...deletedTrees, cleanedPoint]);

                const data = map.current.getSource('trees')._data;

                data.features.forEach(feature => {
                    if (feature.properties.tree_id === clickedTreeNum) {
                        feature.properties.deleted = true;
                    }
                });

                map.current.getSource('trees').setData(data);

                setClickedTree(clickedPoint);
                setDeleteTreeDialog(true);
            } else {
                const coordinates = [e.lngLat.lng, e.lngLat.lat];

                const properties = {
                    'tree_id': (Math.random() * 1000).toFixed(0),
                    'entity_type': '',
                    'fruits': '',
                    'width': '',
                    'xs_area': stats.avg_trunk_xsarea,
                    'num_buds': -1,
                    'target': '',
                    'target_fruiting_bud': '',
                    'target_blossom': '',
                    'target_fruit': '',
                    'diff_from_target': '',
                    'percentage_of_target': '',
                    'calibrated_diff': '',
                    'calibrated_count': '',
                    'ground_truth': '',
                    'row': '',
                    'north_id': '',
                    'south_id': '',
                    'west_id': '',
                    'east_id': '',
                    'row_num': '',
                    'avg_diam': '',
                    'variety': '',
                    'audit_type': 'add',
                };

                const newTree = {
                    type: 'Feature',
                    properties: properties,
                    geometry: {
                        type: 'Point',
                        coordinates: coordinates,
                    },
                };

                setPlantedTrees(plantedTrees => [...plantedTrees, newTree]);

                const data = map.current.getSource('trees')._data;
                data.features.push(newTree);
                map.current.getSource('trees').setData(data);
                setOpenTreeDialog(true);
            }
        });

        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        const handleTreeMouseEnter = (event) => {
            map.current.getCanvas().style.cursor = 'pointer';
            popup.current = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            })
                .setLngLat(event.features[0].geometry.coordinates);
            let html_str = '<strong>Tree ' + event.features[0].properties.tree_id + '</strong>';
            if ('frame_id' in event.features[0].properties && event.features[0].properties.frame_id !== '') {
                html_str += '<p>Frame ID: ' + event.features[0].properties.frame_id + '</p>';
            }
            if ('north_id' in event.features[0].properties && event.features[0].properties.north_id !== '') {
                html_str += '<p>North ID: ' + event.features[0].properties.north_id + '</p>';
            }
            if ('row_num' in event.features[0].properties && event.features[0].properties.row_num !== '') {
                html_str += '<p>Row Num: ' + event.features[0].properties.row_num + '</p>';
            }
            if ('geometry' in event.features[0] && 'coordinates' in event.features[0].geometry && event.features[0].geometry.coordinates[0] !== '') {
                html_str += '<p>Longitude: ' + event.features[0].geometry.coordinates[0] + '</p>';
            }
            if ('geometry' in event.features[0] && 'coordinates' in event.features[0].geometry && event.features[0].geometry.coordinates[1] !== '') {
                html_str += '<p>Latitude: ' + event.features[0].geometry.coordinates[1] + '</p>';
            }
            if ('width' in event.features[0].properties && event.features[0].properties.width !== '') {
                html_str += '<p>Width: ' + event.features[0].properties.width + '</p>';
            }
            popup.current.setHTML(html_str).addTo(map.current);
        };

        const handleTreeMouseLeave = () => {
            map.current.getCanvas().style.cursor = '';
            popup.current.remove();
        };

        const handleTreeClick = (e) => {
            // Get the coordinates of the click.
            const clickedPoint = e.features[0];
            const clickedTreeNum = clickedPoint.properties.tree_id;
            const cleanedPoint = {
                type: clickedPoint.type,
                properties: clickedPoint.properties,
                geometry: clickedPoint.geometry,
            };

            // Remove the tree from plantedTrees.
            setPlantedTrees(plantedTrees => plantedTrees.filter(tree => tree.properties.tree_id !== clickedTreeNum));
            setDeletedTrees(deletedTrees => [...deletedTrees, cleanedPoint]);

            const data = map.current.getSource('trees')._data;

            data.features.forEach(feature => {
                if (feature.properties.tree_id === clickedTreeNum) {
                    feature.properties.deleted = true;
                }
            });

            map.current.getSource('trees').setData(data);

            setClickedTree(clickedPoint);
            setDeleteTreeDialog(true);
        };

        map.current.on('mouseenter', 'trees-point', handleTreeMouseEnter);
        map.current.on('mouseleave', 'trees-point', handleTreeMouseLeave);
        map.current.on('click', 'trees-point', handleTreeClick);

        map.current.on('mouseenter', 'trees-audit', handleTreeMouseEnter);
        map.current.on('mouseleave', 'trees-audit', handleTreeMouseLeave);
        map.current.on('click', 'trees-audit', handleTreeClick);

        if (marker.current) marker.current.remove();
        // update area selection listeners
        map.current.off('draw.create', updateAreaSelectionRef.current);
        map.current.off('draw.update', updateAreaSelectionRef.current);
        map.current.off('draw.delete', updateAreaSelectionRef.current);
        updateAreaSelectionRef.current = updateAreaSelection;
        map.current.on('draw.create', updateAreaSelectionRef.current);
        map.current.on('draw.update', updateAreaSelectionRef.current);
        map.current.on('draw.delete', updateAreaSelectionRef.current);

        map.current.triggerRepaint();
        checkForPaintDoneAndEnableControl();

    }

    const addBlockLayers = () => {
        map.current.addLayer({
            "id": "block-poly",
            "type": "fill",
            "source": "blocks",
            "layout": {},
            "paint": {
                "fill-color": [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    "#eef0ce",
                    "#27cc53"
                ], //"#27cc53", // #d13126 // #627BC1
                "fill-opacity": [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    0.5,
                    0.5
                ]
            }
        });
        map.current.addLayer({
            id: "block-labels",
            type: "symbol",
            source: "blocks", // use the same source as the polygon layer
            layout: {
                "text-field": ["get", "Block ID"], // specify the attribute to use for labels
                "text-size": 16,
                "symbol-avoid-edges": true
            },
            paint: {
                "text-color": "#000",
                "text-halo-color": "#e3e3e3",
                "text-halo-width": 3
            }
        });

        map.current.addLayer({
            "id": "state-borders",
            "type": "line",
            "source": "blocks",
            "layout": {},
            "paint": {
                "line-color": "#5b84c7", // #627BC1
                "line-width": 2 // 2
            }
        });
    }

    useEffect(() => {
        if (map.current || blockData === null) return;
        console.log("BLOCK DATA", blockData)
        let data = blockData
        data.features = data.features.map((feature, index) => {
            // Copy the feature object
            let newFeature = { ...feature };

            // Create a new 'id' field
            // If 'block_id' is unique for each feature and is a number, you can use it directly
            // Otherwise, use the index or any other unique number
            newFeature.id = index;

            return newFeature;
        });
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: style,
            center: [initialLocation.long, initialLocation.lat],
            zoom: initialZoom,
            attributionControl: false,
            logoPosition: 'top-left',
            preserveDrawingBuffer: true,
        });
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
            map.current.setMaxZoom(22)
            map.current.setMinZoom(14)
        });
        map.current.on('load', () => {
            map.current.addSource("blocks", {
                "type": "geojson",
                "data": data
            });

            addBlockLayers();
        });
        if (marker.current) marker.current.remove();

        // console.log(blockData)
        let currentHoveredId = null;
        map.current.on('mouseenter', 'block-poly', (event) => {
            currentHoveredId = event.features[0].id;
            map.current.setFeatureState(
                { source: 'blocks', id: event.features[0].id },
                { hover: true }
            );
        });
        map.current.on('mouseleave', 'block-poly', (event) => {
            if (currentHoveredId !== null) {
                map.current.setFeatureState(
                    { source: 'blocks', id: currentHoveredId },
                    { hover: false }
                );
                // Reset currentHoveredId
                currentHoveredId = null;
            }
        });

        map.current.on('click', 'block-poly', (event) => {
            map.current.removeLayer('block-poly');
            map.current.removeLayer('state-borders');
            let coordinates = event.lngLat;
            setTreeViewLat(coordinates.lat.toFixed(4))
            setTreeViewLong(coordinates.lng.toFixed(4))
            setSidebarViewMode('Block')
            setBlockView(true);
            setBlockName(event.features[0].properties.block_id);
        });
        draw.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                line_string: true,
            },
            defaultMode: 'draw_line_string',
            styles: MapboxGLDrawConfig
        });
        map.current.addControl(draw.current);
        map.current.on('draw.modechange', (e) => {
            if (!draw.current) return;
            const data = draw.current.getAll();
            if (draw.current.getMode() === 'draw_line_string') {
                let pids = []
                // ID of the added template empty feature
                const lid = data.features[data.features.length - 1].id
                data.features.forEach((f) => {
                    if (f.geometry.type === 'LineString' && f.id !== lid) { // Check for LineString instead of Polygon
                        pids.push(f.id);
                    }
                })
                draw.current.delete(pids);
            }
        });

        if (map.current === null || treeData === null || initialLocation === null || blockView === false) return;
        redrawMap()
        map.current.triggerRepaint();
    }, [style, treeData]);

    useEffect(() => {
        if (!map.current || !map.current.isStyleLoaded() || !treeData) return;

        if (sidebarViewMode === 'Orchard' && blockView === false) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: style,
                center: [initialLocation.long, initialLocation.lat],
                zoom: initialZoom,
                attributionControl: false,
                logoPosition: 'top-left'
            });
            draw.current = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    line_string: true,
                },
                defaultMode: 'draw_line_string',
                styles: MapboxGLDrawConfig
            });
            map.current.addControl(draw.current);
            map.current.on('draw.modechange', (e) => {
                if (!draw.current) return;
                const data = draw.current.getAll();
                if (draw.current.getMode() === 'draw_line_string') {
                    let pids = []
                    // ID of the added template empty feature
                    const lid = data.features[data.features.length - 1].id
                    data.features.forEach((f) => {
                        if (f.geometry.type === 'LineString' && f.id !== lid) { // Check for LineString instead of Polygon
                            pids.push(f.id);
                        }
                    })
                    draw.current.delete(pids);
                }
            });
            map.current.on('move', () => {
                setLng(map.current.getCenter().lng.toFixed(4));
                setLat(map.current.getCenter().lat.toFixed(4));
                setZoom(map.current.getZoom().toFixed(2));
                map.current.setMaxZoom(22)
                map.current.setMinZoom(14)
            });
            let data = blockData;

            data.features = data.features.map((feature, index) => {
                let newFeature = { ...feature };
                newFeature.id = index;

                return newFeature;
            });

            map.current.on('load', () => {
                map.current.addSource("blocks", {
                    "type": "geojson",
                    "data": data
                });

                addBlockLayers();
            });
            let currentHoveredId = null;
            map.current.on('mouseenter', 'block-poly', (event) => {
                currentHoveredId = event.features[0].id
                map.current.setFeatureState(
                    { source: 'blocks', id: event.features[0].id },
                    { hover: true }
                );
            });
            map.current.on('mouseleave', 'block-poly', (event) => {
                if (currentHoveredId !== null) {
                    map.current.setFeatureState(
                        { source: 'blocks', id: currentHoveredId },
                        { hover: false }
                    );
                    currentHoveredId = null;
                }
            });

            map.current.on('click', 'block-poly', (event) => {
                map.current.removeLayer('block-poly');
                map.current.removeLayer('state-borders');
                let coordinates = event.lngLat;
                setBlockName(event.features[0].properties.block_id);
                setTreeViewLat(coordinates.lat.toFixed(4))
                setTreeViewLong(coordinates.lng.toFixed(4))
                setSidebarViewMode('Block')
                setBlockView(true);
            });
        }

        if (blockView === true) {
            console.log("block clicked, redraw trees?")
            redrawMap()
            map.current.triggerRepaint();

        }
    }, [style, treeData, checkForPaintDoneAndEnableControl, setControlEnabled, updateAreaSelection, blockView, plotType, viewAutoMovedTrees]);

    useEffect(() => { // reset area selection whenever scanName changes
        if (!draw.current || !map.current || !map.current.isStyleLoaded() || !map.current.hasControl(draw.current)) return;
        const data = draw.current.getAll();
        let pids = []
        data.features.forEach(f => {
            if (f.geometry.type === 'LineString') { // Check for LineString instead of Polygon
                pids.push(f.id);
            }
        });
        draw.current.delete(pids);
        if (marker.current && marker.current.remove !== undefined) marker.current.remove();
    }, [truthfulScanName]);

    // enable and disable MapboxDraw
    // we need to do this because touch events don't register when MapboxDraw is enabled on the map
    useEffect(() => {
        if (!map.current || !draw.current) {
            return;
        }

        if (drawEnabled) {
            map.current.addControl(draw.current);
            draw.current.changeMode('draw_line_string');
        } else {
            try {
                map.current.removeControl(draw.current);
            } catch (err) {
                // initial 
            }
        }
    }, [drawEnabled]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            continueAdding();
        }
    };

    return (
        <div className='relative col-span-2'>
            <div id='map-info'>
                Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
            </div>
            {blockView === true && plotType === 'fruits' ? <EnableDrawControl drawEnabled={drawEnabled} plotType={plotType} setDrawDialog={setDrawDialog} setDrawEnabled={setDrawEnabled} /> : null}
            {blockView === true ?
                <GuideBar
                    blockName={blockName}
                    scan={truthfulScanName}> </GuideBar> : null}
            <StyleToggle
                toggleStyle={toggleStyle}>
            </StyleToggle>
            {!mapContainer ? <div className='grid h-screen place-items-center'>
                <img src={loading} alt='loading...' />
            </div> :
                <div ref={mapContainer} id='map-container' className='col-span-2' />}

            {plotType === 'audit' && blockView === true ? <Legend /> : null}
            {/* {blockView === true ?
                <>
                    <MapSettings setPlotType={setPlotType} plotType={plotType} controlEnabled={controlEnabled} viewAutoMovedTrees={viewAutoMovedTrees} setViewAutoMovedTrees={setViewAutoMovedTrees} />
                </>
                : null
            } */}
            <Dialog open={openTreeDialog} onClose={continueAdding} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" onKeyDown={handleKeyDown}>
                <DialogTitle id="alert-dialog-title">Tree Planted!!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Undo last planted tree, keep planting trees, or submit planted trees
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ m: 2 }}>
                    <Button onClick={undo} size='large' color="primary" fullWidth variant='contained' disabled={loadingTrees}>
                        Undo
                    </Button>
                    <Button onClick={continueAdding} size='large' fullWidth variant='contained' color="primary" autoFocus disabled={loadingTrees}>
                        Continue
                    </Button>
                    <Button onClick={handleUpdateTrees} size='large' fullWidth variant='contained' color="primary" disabled={loadingTrees}>
                        {loadingTrees ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteTreeDialog} onClose={() => setDeleteTreeDialog(false)}>
                <DialogTitle>{`Are you sure you want to delete this tree? Frame ID: ${clickedTree ? clickedTree.properties.frame_id : null}`}</DialogTitle>
                {clickedTree ? <img src={`/util/get_pano?frame_id=${clickedTree.properties.frame_id}&raw_scan_name=${truthfulScanName}`} /> : null}
                <DialogActions>
                    <Button onClick={() => setDeleteTreeDialog(false)} size='large' fullWidth variant='contained' color="primary" disabled={loadingTrees}>
                        No
                    </Button>
                    <Button onClick={handleDelete} size='large' fullWidth variant='contained' color="primary" disabled={loadingTrees}>
                        Yes
                    </Button>
                    <Button onClick={handleUpdateTrees} size='large' fullWidth variant='contained' color="primary" disabled={loadingTrees}>
                        {loadingTrees ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={lineConfirm} onClose={() => setLineConfirm(false)}>
                <DialogTitle>{"Line Submitted"}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setLineConfirm(false)} size='large' fullWidth variant='contained' color="primary">
                        Continue
                    </Button>
                    <Button onClick={submitLineString} size='large' fullWidth variant='contained' color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={submitSuccessDialogOpen}
                onClose={() => setSubmitSuccessDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Submit Successful!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your trees have been successfully audited.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth variant='contained' onClick={
                        () => handleRefetch()
                    } color="primary">
                        Fetch new data
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}