import { library } from '@fortawesome/fontawesome-svg-core';
import { faAppleWhole, faArrowDown, faArrowRight, faBorderAll, faCalendar, faCalendarDays, faCamera, faCaretLeft, faCaretRight, faChartArea, faChartSimple, faDownload, faEnvelope, faHardDrive, faHashtag, faHome, faLeaf, faLink, faLinkSlash, faLocationCrosshairs, faLocationDot, faMessage, faPaperPlane, faPrint, faQuestion, faRuler, faSeedling, faSpa, faStopwatch, faTag, faTowerCell, faTree, faVectorSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { BulkExport } from './BulkExport';
import { TreePlanter } from './TreePlanter';
import MadeByOrchard from './assets/MadeByOrchard.png';
import loading from './assets/loading.gif';
import { Block, BlockScanMap, Coordinate, EntityType, Orchard, StageType, Statistics } from './common/types';
import { MapStateProvider } from './context/MapStateContext';
import firebase from './firebase';
import { Admin } from './routes/AdminMode';
import { Home } from './routes/Home';
import Login from './routes/Login';
import { Setup } from './routes/Setup';
import { Sidebar } from './sidebar/Sidebar';


// is there a better way to library.add?
library.add(faSeedling, faStopwatch, faRuler, faCaretRight, faLocationDot, faCaretLeft, faMessage, faEnvelope, faPaperPlane, faXmark, faQuestion, faVectorSquare, faCamera, faLocationCrosshairs, faHardDrive, faTowerCell, faTree, faLink, faLinkSlash, faHome, faAppleWhole, faTag, faPrint, faDownload, faSpa, faChartArea, faLeaf, faHashtag, faCalendarDays, faBorderAll, faArrowRight, faArrowDown, faCalendar, faChartSimple);

const App = () => {
    /* eslint-disable */

    //TODO, apply where possible
    const [orchard, setOrchard] = useState<Orchard | null>(null);
    const [stats, setStats] = useState<Statistics | null>(null);
    const [sdmTableView, setSdmTableView] = useState<boolean>(false)
    const [truthfulScanName, setTruthfulScanName] = useState<string | null>(null)
    const [truthfulScanId, setTruthfulScanId] = useState<number | null>(null)
    const [user, setUser] = useState<firebase.User | null>(null);
    const [initLocation, setInitLocation] = useState<Coordinate>();
    const [orchardCode, setOrchardCode] = useState<string>('');
    const [sidebarViewMode, setSidebarViewMode] = useState<string>('Orchard');
    const [treeData, setTreeData] = useState(null);
    const [blockData, setBlockData] = useState(null);
    const [blockView, setBlockView] = useState<boolean>(false);
    const [blockName, setBlockName] = useState<string | null>(null);
    const [blockInfo, setBlockInfo] = useState<Block | null>(null);
    let time = new Date()
    const [timeFilter, setTimeFilter] = useState(() => {
        const storedTime = localStorage.getItem('timestamp');
        const defaultTime = time.toISOString();
        if (storedTime !== null) {
            const storedDateTime = new Date(storedTime);
            return storedDateTime.toISOString() !== '1970-01-01T00:00:00.000Z' ? storedDateTime.toISOString() : defaultTime;
        }
        return defaultTime;
    });
    const [authUser, setAuthUser] = useState<boolean>(false)
    const [adminUser, setAdminUser] = useState<boolean>(false)
    const [controlEnabled, setControlEnabled] = useState<boolean>(true);
    const [uniformTarget, setUniformTarget] = useState(null)
    const [progress, setProgress] = useState(0);

    const [entity, setEntity] = useState<EntityType>(EntityType.Fruits);
    const [blockScanMap, setBlockScanMap] = useState<BlockScanMap>({});
    const [stageType, setStageType] = useState<StageType | null>(null);
    const [scanLoading, setScanLoading] = useState<boolean>(false)
    const [mapCenter, setMapCenter] = useState(null)
    const [fruitletDF, setFruitletDF] = useState(null)
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const mapInstance = useRef<mapboxgl.Map | null>(null);
    const [chartType, setChartType] = useState("row_sizes_")
    const [polygonStatDialog, setPolygonStatDialog] = useState<boolean>(false)
    const [addToSidebar, setAddToSidebar] = useState<boolean>(false)
    const [polygonStats, setPolygonStats] = useState<Statistics | null>(null);
    const [plotType, setPlotType] = useState<string | null>(null);
    const [plotTypePlanter, setPlotTypePlanter] = useState('regular');
    const [avgFruitCount, setAvgFruitCount] = useState(null);
    const [downloadFormat, setDownloadFormat] = useState('share_geojson')
    const [avgTrunkDiam, setAvgTrunkDiam] = useState<number>(0)
    const [avgTrunkHeight, setAvgTrunkHeight] = useState<number>(0)
    const [avgTrunkSpacing, setAvgTrunkSpacing] = useState(0)
    const [avgVigor, setAvgVigor] = useState(0)
    const [varietyList, setVarietyList] = useState<any[]>([])
    const [selectedVarieties, setSelectedVarieties] = useState<any[]>([]);
    const [allowedVarieties, setAllowedVarieties] = useState<any[]>([]);
    const [hasRowVarieties, setHasRowVarieties] = useState(false); // TODO, remove 
    const [enableRowFilter, setEnableRowFilter] = useState(false);
    const [exclusionArray, setExclusionArray] = useState<string[]>([])
    const [initialLoad, setInitialLoad] = useState<number>(0);
    const [treeViewLong, setTreeViewLong] = useState<number>(0);
    const [treeViewLat, setTreeViewLat] = useState<number>(0);
    const [sectionGeojson, setSectionGeojson] = useState(null);
    const [homePageLoaded, setHomePageLoaded] = useState<boolean>(false);
    const [sidebarWidth, setSidebarWidth] = useState(window.innerWidth * .33);
    const [scanInfo, setScanInfo] = useState(null);

    const dbName = useRef('')
    let didInit = false;

    useEffect(() => {
        if (!didInit) {
            // Only run this once
            didInit = true;
            firebase.auth().onAuthStateChanged(async (firebase_user) => {
                if (authUser !== true) {
                    setUser(firebase_user);
                    const authenticateUser = await axios.post('/auth_user', {
                        username: firebase_user,
                        timestamp: timeFilter,
                        loggedIn: authUser,
                    });
                    if (authenticateUser.data.error) {
                        console.log('User not authenticated');
                        return;
                    }
                    dbName.current = authenticateUser.data.db;
                    setAuthUser(true);
                    setAdminUser(authenticateUser.data.is_admin ? true : false);
                }
                setInitialLoad(20);

                try {
                    const [
                        coordsResponse,
                        orchDataResponse,
                        blockPointsResponse,
                        blocksToScansResponse,
                    ] = await Promise.all([
                        axios.get('/util/get_init_location').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 20);
                            return response;
                        }),
                        axios.get('/util/get_orchard').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 20);
                            return response;
                        }),
                        axios.get('/geojson/points_block').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 20);
                            return response;
                        }),
                        axios.get('/util/get_block_scan_map').then((response) => {
                            setInitialLoad((prevLoad) => prevLoad + 10);
                            return response;
                        }),
                    ]);

                    const coords = coordsResponse.data;
                    setInitLocation(coords);
                    setTreeViewLat(coords.lat);
                    setTreeViewLong(coords.long);

                    const orchData = orchDataResponse.data;
                    setOrchard({
                        name: orchData.orchard_name,
                        address: orchData.address,
                        code: orchData.orchard_id,
                        initialLocation: {
                            lat: orchData.location.lat,
                            long: orchData.location.long,
                        },
                        total_acres: orchData.total_acres,
                        section_code_prefix: orchData.section_code_prefix,
                    });
                    setOrchardCode(orchData.orchard_id);

                    setBlockData(blockPointsResponse.data);
                    setBlockScanMap(blocksToScansResponse.data);

                    setInitialLoad(100);
                    setProgress(100);
                    setHomePageLoaded(true);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            });
        }
    }, []);

    useEffect(() => {
        (async () => {
            setIsLoad(true)

            setEnableRowFilter(false)
            setAllowedVarieties([])
            if (blockName && homePageLoaded) {
                setBlockView(true)
                setSidebarViewMode('Block')
                
                setTruthfulScanName(null);
                setTruthfulScanId(null);
                setAddToSidebar(false)
                const truthfulRes = await axios.get('/util/block_truthful_scans', { params: { 'orchard_code': orchardCode, 'block': blockName } });
                setTruthfulScanName(truthfulRes.data.raw_scan_name);
                setTruthfulScanId(truthfulRes.data.scan_id);

                const blockInfo = await axios.get('/util/get_block_info', { params: { 'block_name': blockName } });
                setBlockInfo(blockInfo.data);
                setTreeViewLat(blockInfo.data.location.lat)
                setTreeViewLong(blockInfo.data.location.long)

                const varietyinfo = await axios.get('/util/variety_list', { params: { 'block_id': blockName } })
                setVarietyList(varietyinfo.data)
                setSelectedVarieties(varietyinfo.data)
            }
                setIsLoad(false)

        })();
    }, [blockName, homePageLoaded]);

    useEffect(() => {
        (async () => {
            setProgress(0);
            setScanLoading(true);
            if (truthfulScanName) {
                console.log("Scan Name: ", truthfulScanName);
                const apiCalls = [
                    // TODO: Rename scan_id param to scan_name
                    axios.get('/geojson/points', { params: { 'orchard_code': orchardCode, 'scan_id': truthfulScanName } }),
                    axios.get('/util/get_scan_info', { params: { 'scan_id': truthfulScanName } }),
                    axios.post('/util/get_stats', { orchard_code: orchardCode, scan_name: truthfulScanName })
                ];
                const [
                    treedata,
                    scanInfo,
                    stats,
                ] = await Promise.all(apiCalls);

                console.log("Stats: ", stats)
                setEntity(scanInfo.data.entity_type);
                setScanInfo(scanInfo.data);
                setStageType(scanInfo.data.stage_type);
                setTreeData(treedata.data);

                if (scanInfo.data.entity_type === EntityType.Fruits) {
                    if (scanInfo.data.stage_type === StageType.Fruit) {
                        setPlotType('uniform')
                        setChartType('fruit_count_')
                    }
                    else if (scanInfo.data.stage_type === StageType.EarlyFruitSet) {
                        setPlotType('size')
                        setChartType('fruitlet_histogram_')
                    }
                    else {
                        setPlotType('uniform')
                        setChartType('row_bars_')
                    }
                } else {
                    setPlotType('tree_diam')
                }

                setAvgTrunkDiam(stats.data.avg_tree_diam_in)
                setAvgTrunkSpacing(stats.data.avg_tree_spacing_ft);
                setAvgVigor(stats.data['avg_canopy_area_m2'])
                setAvgTrunkHeight(stats.data['avg_canopy_height_m'])


                console.log("avg fruit count", stats.data.fruit_per_tree_calibrated, stats.data.fruit_per_tree_detected)
                if (stats.data.fruit_per_tree_calibrated > 0) {
                    setAvgFruitCount(stats.data.fruit_per_tree_calibrated)
                }
                else {
                    setAvgFruitCount(stats.data.fruit_per_tree_detected)
                }

                let exclusions = [];
                // Check for the presence of specific fields and update the exclusion array
                if (!stats.data.avg_hue || stats.data.avg_hue === null || stats.data.avg_hue === undefined || isNaN(stats.data.avg_hue)) {
                    exclusions.push('canopy_hue'); // Exclude 'Canopy Hue Heatmap' if avg_hue is missing
                    exclusions.push('color'); // Exclude 'Canopy Hue Heatmap' if avg_hue is missing
                    exclusions.push('coverage')
                }
                if (!stats.data.avg_canopy_area_m2 || stats.data.avg_canopy_area_m2 === null || stats.data.avg_canopy_area_m2 === undefined || isNaN(stats.data.avg_canopy_area_m2)) {
                    exclusions.push('tree-vigor'); // Exclude 'Tree Vigor Heatmap' if avg_canopy_area_m2 is missing
                }
                setExclusionArray(exclusions);
                setStats(stats.data)

                // Get calibration sections
                try {
                    if (truthfulScanName) {           
                        const response = await axios.get('/geojson/section_geojson', {
                            params: {
                                'orchard_code': orchardCode,
                                'scan_id': truthfulScanName
                            }
                        });
                        console.log("Section Geojson", response.data)
                        setSectionGeojson(response.data);
                    }
                    else {
                        setSectionGeojson(null)
                    }
                } catch (error) {
                    console.error("Failed to fetch section geojson:", error);
                }

            }
            setScanLoading(false)
            setProgress(100)
        })();

    }, [truthfulScanName]);
    
    //TODO: Make into a component
    const Plant = () => (
        blockData === null ? (
            <div className="h-screen flex flex-col justify-center items-center mx-auto mt-auto mb-auto">
                <img className="max-w-[250px] max-h-[250px]" src={loading} alt="loading..." />
                <p>Loading... {progress}%</p>
                <div className="progress-bar-container justify-center items-center w-full">
                    <div className="mx-auto relative pt-1 max-w-[500px]">
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200">
                            <div
                                style={{ width: `${progress}%` }}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                            ></div>
                        </div>
                    </div>
                </div>
                <img className='' src={MadeByOrchard}></img>
            </div>
        ) : (
            <div className='grid grid-cols-3 overflow-hidden'>
                <Toaster position='top-left' toastOptions={{
                    success: { duration: 2000 },
                    className: 'min-w-[23%]'
                }} />
                <Sidebar
                    orchard={orchard}
                    plotType={plotType}
                    selectedVarieties={selectedVarieties}
                    varietyList={varietyList}
                    downloadFormat={downloadFormat}
                    setDownloadFormat={setDownloadFormat}
                    setPlotType={setPlotType}
                    chartType={chartType}
                    setChartType={setChartType}
                    mapInstance={mapInstance}
                    scanLoading={scanLoading}
                    setScanLoading={setScanLoading}
                    blockScanMap={blockScanMap}
                    entity={entity}
                    setProgress={setProgress}
                    progress={progress}
                    stats={stats}
                    setTruthfulScanName={setTruthfulScanName}
                    setTruthfulScanId={setTruthfulScanId}
                    blockView={blockView}
                    blockName={blockName}
                    setBlockName={setBlockName}
                    sidebarViewMode={sidebarViewMode}
                    setSidebarViewMode={setSidebarViewMode}
                    setBlockView={setBlockView}
                    truthfulScanName={truthfulScanName}
                    uniformTarget={uniformTarget}
                    addToSidebar={addToSidebar}
                    polygonStats={polygonStats}
                    enableRowFilter={enableRowFilter}
                    stageType={stageType}
                    blockInfo={blockInfo}
                    setBlockInfo={setBlockInfo}
                    setTreeViewLat={setTreeViewLat}
                    setTreeViewLong={setTreeViewLong}
                    setSectionGeojson={setSectionGeojson}
                    sidebarWidth={sidebarWidth}
                    setSidebarWidth={setSidebarWidth}
                    scanInfo={scanInfo}
                    adminUser={adminUser}
                    treeData={treeData}
                />
                <div style={{ visibility: (scanLoading || isLoad || progress !== 100) ? 'hidden' : 'visible' }}>
                    <TreePlanter
                        orchardCode={orchardCode}
                        plotType={plotTypePlanter}
                        setPlotType={setPlotTypePlanter}
                        entity={entity}
                        mapInstance={mapInstance}
                        mapCenter={mapCenter}
                        blockName={blockName}
                        setProgress={setProgress}
                        truthfulScanName={truthfulScanName}
                        controlEnabled={controlEnabled}
                        sidebarViewMode={sidebarViewMode}
                        stats={stats}
                        setSidebarViewMode={setSidebarViewMode}
                        setBlockName={setBlockName}
                        blockView={blockView}
                        setBlockView={setBlockView}
                        initialLocation={initLocation}
                        blockData={blockData}
                        treeData={treeData}
                        setTreeData={setTreeData}
                        setControlEnabled={setControlEnabled}
                    />
                </div>

                {(scanLoading || isLoad || progress !== 100) && (
                    <div className="absolute inset-0 left-1/3 flex flex-col justify-center items-center">
                        <p className='font-lato text-gray-500'>Reloading map with new scan data...</p>
                        <img className="max-w-[250px] max-h-[250px]" src={loading} alt="loading..." />
                    </div>
                )}

            </div>)
    );

    if (user) {
        return (
            <Router>
                <MapStateProvider>
                    <Routes>
                        <Route path="/setup" element={<Setup />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route
                            path="/bulk"
                            element={<BulkExport truthfulScanName={truthfulScanName} />}
                        />
                        <Route path="/plant" element={Plant()} />
                        <Route
                            path="/block/:blockName?"
                            element={
                                <Home
                                    orchard={orchard}
                                    orchardCode={orchardCode}
                                    fruitletDF={fruitletDF}
                                    blockData={blockData}
                                    initialLoad={initialLoad}
                                    dbName={dbName}
                                    plotType={plotType}
                                    selectedVarieties={selectedVarieties}
                                    varietyList={varietyList}
                                    downloadFormat={downloadFormat}
                                    setDownloadFormat={setDownloadFormat}
                                    setPlotType={setPlotType}
                                    chartType={chartType}
                                    setChartType={setChartType}
                                    mapInstance={mapInstance}
                                    scanLoading={scanLoading}
                                    setScanLoading={setScanLoading}
                                    blockScanMap={blockScanMap}
                                    entity={entity}
                                    setProgress={setProgress}
                                    progress={progress}
                                    stats={stats}
                                    setTruthfulScanName={setTruthfulScanName}
                                    setTruthfulScanId={setTruthfulScanId}
                                    blockView={blockView}
                                    blockName={blockName}
                                    setBlockName={setBlockName}
                                    sidebarViewMode={sidebarViewMode}
                                    setSidebarViewMode={setSidebarViewMode}
                                    setBlockView={setBlockView}
                                    truthfulScanName={truthfulScanName}
                                    truthfulScanId={truthfulScanId}
                                    uniformTarget={uniformTarget}
                                    setSdmTableView={setSdmTableView}
                                    sdmTableView={sdmTableView}
                                    addToSidebar={addToSidebar}
                                    polygonStats={polygonStats}
                                    enableRowFilter={enableRowFilter}
                                    stageType={stageType}
                                    blockInfo={blockInfo}
                                    setBlockInfo={setBlockInfo}
                                    setTreeViewLat={setTreeViewLat}
                                    setTreeViewLong={setTreeViewLong}
                                    setSectionGeojson={setSectionGeojson}
                                    exclusionArray={exclusionArray}
                                    setEnableRowFilter={setEnableRowFilter}
                                    hasRowVarieties={hasRowVarieties}
                                    allowedVarieties={allowedVarieties}
                                    setAllowedVarieties={setAllowedVarieties}
                                    setSelectedVarieties={setSelectedVarieties}
                                    avgTrunkSpacing={avgTrunkSpacing}
                                    avgVigor={avgVigor}
                                    avgTrunkDiam={avgTrunkDiam}
                                    avgTrunkHeight={avgTrunkHeight}
                                    avgFruitCount={avgFruitCount}
                                    setPolygonStats={setPolygonStats}
                                    polygonStatDialog={polygonStatDialog}
                                    setPolygonStatDialog={setPolygonStatDialog}
                                    setAddToSidebar={setAddToSidebar}
                                    setVarietyList={setVarietyList}
                                    setIsLoad={setIsLoad}
                                    isLoad={isLoad}
                                    mapCenter={mapCenter}
                                    setMapCenter={setMapCenter}
                                    controlEnabled={controlEnabled}
                                    initialLocation={initLocation}
                                    treeData={treeData}
                                    setUniformTarget={setUniformTarget}
                                    setControlEnabled={setControlEnabled}
                                    setBlockData={setBlockData}
                                    adminUser={adminUser}
                                    treeViewLat={treeViewLat}
                                    treeViewLong={treeViewLong}
                                    sectionGeojson={sectionGeojson}
                                    sidebarWidth={sidebarWidth}
                                    setSidebarWidth={setSidebarWidth}
                                    scanInfo={scanInfo}
                                />
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <Home
                                    orchard={orchard}
                                    orchardCode={orchardCode}
                                    fruitletDF={fruitletDF}
                                    blockData={blockData}
                                    initialLoad={initialLoad}
                                    dbName={dbName}
                                    plotType={plotType}
                                    selectedVarieties={selectedVarieties}
                                    varietyList={varietyList}
                                    downloadFormat={downloadFormat}
                                    setDownloadFormat={setDownloadFormat}
                                    setPlotType={setPlotType}
                                    chartType={chartType}
                                    setChartType={setChartType}
                                    mapInstance={mapInstance}
                                    scanLoading={scanLoading}
                                    setScanLoading={setScanLoading}
                                    blockScanMap={blockScanMap}
                                    entity={entity}
                                    setProgress={setProgress}
                                    progress={progress}
                                    stats={stats}
                                    setTruthfulScanName={setTruthfulScanName}
                                    setTruthfulScanId={setTruthfulScanId}
                                    blockView={blockView}
                                    blockName={blockName}
                                    setBlockName={setBlockName}
                                    sidebarViewMode={sidebarViewMode}
                                    setSidebarViewMode={setSidebarViewMode}
                                    setBlockView={setBlockView}
                                    truthfulScanName={truthfulScanName}
                                    truthfulScanId={truthfulScanId}
                                    uniformTarget={uniformTarget}
                                    setSdmTableView={setSdmTableView}
                                    sdmTableView={sdmTableView}
                                    addToSidebar={addToSidebar}
                                    polygonStats={polygonStats}
                                    enableRowFilter={enableRowFilter}
                                    stageType={stageType}
                                    blockInfo={blockInfo}
                                    setBlockInfo={setBlockInfo}
                                    setTreeViewLat={setTreeViewLat}
                                    setTreeViewLong={setTreeViewLong}
                                    setSectionGeojson={setSectionGeojson}
                                    exclusionArray={exclusionArray}
                                    setEnableRowFilter={setEnableRowFilter}
                                    hasRowVarieties={hasRowVarieties}
                                    allowedVarieties={allowedVarieties}
                                    setAllowedVarieties={setAllowedVarieties}
                                    setSelectedVarieties={setSelectedVarieties}
                                    avgTrunkSpacing={avgTrunkSpacing}
                                    avgVigor={avgVigor}
                                    avgTrunkDiam={avgTrunkDiam}
                                    avgTrunkHeight={avgTrunkHeight}
                                    avgFruitCount={avgFruitCount}
                                    setPolygonStats={setPolygonStats}
                                    polygonStatDialog={polygonStatDialog}
                                    setPolygonStatDialog={setPolygonStatDialog}
                                    setAddToSidebar={setAddToSidebar}
                                    setVarietyList={setVarietyList}
                                    setIsLoad={setIsLoad}
                                    isLoad={isLoad}
                                    mapCenter={mapCenter}
                                    setMapCenter={setMapCenter}
                                    controlEnabled={controlEnabled}
                                    initialLocation={initLocation}
                                    treeData={treeData}
                                    setUniformTarget={setUniformTarget}
                                    setControlEnabled={setControlEnabled}
                                    setBlockData={setBlockData}
                                    adminUser={adminUser}
                                    treeViewLat={treeViewLat}
                                    treeViewLong={treeViewLong}
                                    sectionGeojson={sectionGeojson}
                                    sidebarWidth={sidebarWidth}
                                    setSidebarWidth={setSidebarWidth}
                                    scanInfo={scanInfo}
                                />
                            }
                        />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </MapStateProvider>
            </Router>
        );
    } else {
        return (
            <div className="bg-orchardGreen font-lato grid h-screen flex flex-col place-items-center">
                <Login />
            </div>
        );
    }
}

export default App;