import { Button, TextField } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { EntityType } from '../common/types';
import { entityTypeConfig } from '../constants/entityTypeConfig';
import { Card } from './Cards';
import { MetricItem } from './Sidebar';
import CalibratedStatsToggle from './toggles/CalibratedStatsToggle.js';
import EstimateBlockToggle from "./toggles/EstimateBlockToggle.js";
import UnitToggle from './toggles/UnitToggle.js';

function capitalizeFirstLetter(string) {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const ScanViewStats = ({ entityType, stats, stageType }) => {
    const [isImperial, setIsImperial] = useState(false);
    const [estimateBlock, setEstimateBlock] = useState(false);
    const hasCalibrations = stats.total_fruit_calibrated > 0;
    const [viewCalibratedStats, setViewCalibratedStats] = useState(hasCalibrations)
    const entityConfig = entityTypeConfig(isImperial, stageType, estimateBlock, viewCalibratedStats)[entityType].metrics || []; // default to an empty array if no configuration is found

    const isMalformed = (value) => {
        return value === null || value === undefined || Number.isNaN(value) || value === '' || value === 0 || value === -1;
    };

    return (
        <>
            <Card title={`${capitalizeFirstLetter(entityType)} Scan Statistics`} showButton defaultOpen={true} description={'View statistics about the selected scan.'}>
                {entityConfig.map((metricConfig, index) => {
                    const metricValue = stats[metricConfig.metricPath];
                    if (isMalformed(metricValue)) {
                        return null;
                    }

                    return (
                        <MetricComponent
                            key={index}
                            config={metricConfig}
                            data={stats}
                        />
                    );
                })}

                <UnitToggle isImperial={isImperial} setIsImperial={setIsImperial} />

                {entityType === EntityType.Fruits && <EstimateBlockToggle estimateBlock={estimateBlock} setEstimateBlock={setEstimateBlock} />}

                {hasCalibrations && <CalibratedStatsToggle viewCalibratedStats={viewCalibratedStats} setViewCalibratedStats={setViewCalibratedStats} />}
            </Card>
        </>
    );
}

export const FeedbackForm = ({ truthfulScanName }) => {
    const [feedback, setFeedback] = useState("");

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            await axios.post('/user/send_feedback', { message: feedback, scan: truthfulScanName });
            toast.success('Feedback sent!');
            setFeedback("");
        } catch (error) {
            console.error(error);
            toast.error('Failed to submit feedback.');
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <TextField
                autoFocus
                margin="dense"
                id="feedback"
                label="I have some feedback about..."
                type="text"
                fullWidth
                value={feedback}
                onChange={handleFeedbackChange}
            />
            <Button onClick={handleSubmit} color="primary" style={{ marginTop: '8px' }}>
                Submit
            </Button>
        </div>
    );
};

export const MetricComponent = ({ config, data }) => {
    const isRendered = config.condition(data);

    let value;
    if (config.metricPath) {
        value = data[config.metricPath];
        if (config.valueTransform && value) {
            value = config.valueTransform(value, data);
        }
    }

    if (!isRendered) {
        return null;
    }

    return (
        <Tooltip title={config.description} placement="top-start" arrow={true}>
            <div className={`${config.color ? config.color(data) : ''}`}>
                <MetricItem
                    key={config.key}
                    icon={config.icon}
                    metric={value}
                    label={config.label}
                    units={config.units}
                    infoTip={config.infoTip}
                />
                {config.sublabel && (
                    <div className="mt-1 text-xs text-red-500">{config.sublabel(data)}</div>
                )}
            </div>
        </Tooltip>
    );
};
