import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { TextField, styled } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { useMapState } from '../../context/MapStateContext';
import RLDLegend from './RLDLegend';
import StandardLegend from './StandardLegend';
import ZoneLegend from './ZoneLegend';

// public key on Mapbox website
mapboxgl.accessToken = 'pk.eyJ1IjoiYnJ5YW50LW9yY2hhcmQiLCJhIjoiY2xicXRnN2phMGxwdDNwcGNtdmJ3ZWN3YiJ9.F0kjsae7uDRFJpGuhfSh_Q';

const StyledTextField = styled(TextField)({
    '& input[type=number]': {
        MozAppearance: 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
    }
});

export const LegendRenderer = ({
    plotType,
    uniTarg,
    uniSizeTarg,
    heightTarg,
    spacingTarg,
    vigorTarg,
    treeDiamTarg,
    lowerBound,
    upperBound,
    setLowerBound,
    setUpperBound,
    viewZoneMap,
    treeData,
    stats,
    rldTarg,
    selectedRLDOptions,
    setSelectedRLDOptions,
}) => {
    const { plotConfigs } = useMapState();
    const config = plotConfigs[plotType];
    if (!config) return null;    

    const targetMap = {
        heightTarg: heightTarg,
        spacingTarg: spacingTarg,
        vigorTarg: vigorTarg,
        treeDiamTarg: treeDiamTarg,
        uniTarg: uniTarg,
        uniSizeTarg: uniSizeTarg,
        rldTarg: rldTarg,
    };

    let targetValue = targetMap[config.targetVar] || null;

    // Convert unit w/r to imperial if needed
    targetValue = config.convert ? config.convert(targetValue) : targetValue;

    const convertToBoundOfTarget = (event, setBound) => {
        const value = event.target.value;
        if (value === '') {
            setBound('');
        } else {
            const numericValue = parseFloat(value);
            const percentage = (numericValue / targetValue) * 100;
            setBound(percentage);
        }
    };

    const applyPercentageToTarget = (percent) => {
        if (percent === '' || isNaN(parseFloat(percent))) return '';
        const value = (parseFloat(percent) / 100) * targetValue;
        return config.unit ? Number(value.toFixed(3)) : Math.round(value);
    };

    const selectAll = (event) => {
        event.target.select();
    };

    const commonLegendProps = {
        config,
        lowerBound,
        upperBound,
        setLowerBound,
        setUpperBound,
        applyPercentageToTarget,
        convertToBoundOfTarget,
        selectAll,
        stats,
        StyledTextField,
    };

    const renderLegend = () => {
        if (viewZoneMap) {
            return <ZoneLegend {...commonLegendProps} treeData={treeData} />;
        } else {
            switch (config.legend) {
                case 'RLD':
                    return <RLDLegend {...commonLegendProps} selectedRLDOptions={selectedRLDOptions} setSelectedRLDOptions={setSelectedRLDOptions} />;
                case 'standard':
                default:
                    return <StandardLegend {...commonLegendProps} />;
            }
        }
    };

    return (
        <div className='absolute left-1/2 transform -translate-x-1/2 bottom-0 bg-white rounded-md font-lato pr-2 pl-2 pb-2 pt-1 mb-1 shadow-lg' style={{ width: '450px' }}>
            {renderLegend()}
        </div>
    );
}