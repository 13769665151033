import React, { useState } from 'react';
import { entityTypeConfig } from '../constants/entityTypeConfig';
import { MetricComponent } from './ScanViewStats';
import CalibratedStatsToggle from './toggles/CalibratedStatsToggle.js';
import UnitToggle from './toggles/UnitToggle.js';

const isMalformed = (value) => {
    return value === null || value === undefined || Number.isNaN(value) || value === '' || value === 0 || value === -1;
};

export const PolygonStats = ({ entityType, stats, stageType }) => {
    const [isImperial, setIsImperial] = useState(false);
    const hasCalibrations = stats.total_fruit_calibrated > 0;
    const [viewCalibratedStats, setViewCalibratedStats] = useState(hasCalibrations)
    const entityConfig = entityTypeConfig(isImperial, stageType, false, viewCalibratedStats)[entityType].metrics || []; 

    return (
        <>
            {entityConfig.map(metricConfig => {
                const metricValue = stats[metricConfig.metricPath];
                if (isMalformed(metricValue)) {
                    return null;
                }

                return (
                    <MetricComponent
                        key={metricConfig.key} 
                        config={metricConfig}
                        data={stats}
                    />
                );
            })}

            <UnitToggle isImperial={isImperial} setIsImperial={setIsImperial} />
            {hasCalibrations && <CalibratedStatsToggle viewCalibratedStats={viewCalibratedStats} setViewCalibratedStats={setViewCalibratedStats} />}


        </>
    );
}