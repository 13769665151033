// constants.js
export const MEDIAN_TRUNK_XS_AREA= "median_trunk_xsarea";
export const AVG_TRUNK_XSAREA = "avg_trunk_xsarea";
export const TOTAL_FRUITS_DETECTED = "total_fruit_detected";
export const TOTAL_FRUITS_CALIBRATED = "total_fruit_calibrated";
export const TOTAL_TREES = "total_trees";
export const PERCENT_TREES = "percent_trees";
export const FRUITS_PER_TREE_DETECTED = "fruit_per_tree_detected";
export const FRUITS_PER_TREE_CALIBRATED = "fruit_per_tree_calibrated";
export const CALIBRATION_RATIO = "calibration_ratio";
export const AVG_FRUIT_VOL = "avg_fruit_vol";
export const AVG_HUE = "avg_hue";
export const AVG_COVERAGE = "avg_coverage";
export const AVG_FRUIT_DIAM = "avg_fruit_diam";
export const AVG_FRUITLET_DIAM = "avg_fruitlet_diam";
export const TOTAL_YIELD = "total_yield";
export const AVG_FRUIT_WEIGHT = "avg_fruit_weight";
export const P_PACKABLE = "percent_packable";
export const STAGE_TYPE = "entity_type";
export const RUN_MASTER = "run_master";
export const VARIETY = "variety_type";
export const PACK_SIZE = "pack_size";
export const BLOCK_SCAN_MAP = "block_scan_map";
export const ADDRESS = "address";
export const PLANTED_TREES = "planted_trees";
export const BLOCK_ID = 'block_id'
export const AVG_TREE_DIAM_IN = 'avg_tree_diam_in'
export const MEDIAN_TREE_DIAM_IN = 'median_tree_diam_in'
export const AVG_TREE_SPACING_FT = 'avg_tree_spacing_ft'
export const AVG_TREE_AREA_IN2 = 'avg_tree_area_in2'
export const AVG_CANOPY_HEIGHT_M = 'avg_canopy_height_m'
export const AVG_CANOPY_AREA_M2 = 'avg_canopy_area_m2'
export const STD_FRUITS_PER_TREE = 'std_fruits_per_tree'
export const STD_FRUIT_DIAM = 'std_fruit_diam'
export const Q25_FRUIT_DIAM = 'q25_fruit_diam'
export const Q75_FRUIT_DIAM = 'q75_fruit_diam'
export const Q25_FRUITS_PER_TREE = 'q25_fruits_per_tree'
export const Q75_FRUITS_PER_TREE = 'q75_fruits_per_tree'
export const STD_FRUITS_PER_TREE_CALIBRATED = 'std_fruits_per_tree_calibrated'
export const Q25_FRUITS_PER_TREE_CALIBRATED = 'q25_fruits_per_tree_calibrated'
export const Q75_FRUITS_PER_TREE_CALIBRATED = 'q75_fruits_per_tree_calibrated'
export const STD_TREE_DIAM_IN = 'std_tree_diam_in'
export const STD_TREE_AREA_IN2 = 'std_tree_area_in2'
export const STD_CANOPY_HEIGHT_M = 'std_canopy_height_m'
export const STD_CANOPY_AREA_M2 = 'std_canopy_area_m2'
export const P25_TREE_DIAM_IN = 'p25_tree_diam_in'
export const P75_TREE_DIAM_IN = 'p75_tree_diam_in'
export const P25_TREE_AREA_CM2 = 'p25_trunk_xsarea'
export const P75_TREE_AREA_CM2 = 'p75_trunk_xsarea'
export const P25_CANOPY_HEIGHT_M = 'p25_canopy_height_m'
export const P75_CANOPY_HEIGHT_M = 'p75_canopy_height_m'
export const P25_CANOPY_AREA_M2 = 'p25_canopy_area_m2'
export const P75_CANOPY_AREA_M2 = 'p75_canopy_area_m2'

// Estimated constants
export const PERCENT_TREES_ESTIMATED = "percent_trees_estimated";
export const TOTAL_TREES_ESTIMATED = "total_trees_estimated";
export const TOTAL_FRUITS_DETECTED_ESTIMATED = "total_fruit_detected_estimated";
export const TOTAL_FRUITS_CALIBRATED_ESTIMATED = "total_fruit_calibrated_estimated";
export const TOTAL_YIELD_ESTIMATED = "total_yield_estimated";