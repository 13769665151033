import React, { useState } from 'react';
import { useTable } from 'react-table';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { HelpControl } from '../map/HelpControl';

export const FruitletExport = ({ truthfulScanName, setSdmTableView, blockName, data }) => {
    const [copyPressed, setCopyPressed] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false);
    function convertToCSV(objArray) {
        let result = '';
        //for paul, CSV, else TSV
        if (truthfulScanName.includes('CHI')) {
            // Append the column headers
            result += "Block Name,Fruitlet ID,Tree ID,Diameter Width (mm),Fruitlet Length (mm),Volume (mm^3)\n";

            // Append each row
            formattedData.forEach((row) => {
                result += `${row.block_id},${row.bud_id},${row.tree_id},${row.minor_ax},${row.major_ax},${row.volume_mm}\n`;
            });

        }
        else {
            // Append the column headers
            result += "Block Name\tFruitlet ID\tTree ID\tDiameter Width (mm)\tFruitlet Length (mm)\tVolume (mm^3)\n";

            // Append each row
            formattedData.forEach((row) => {
                result += `${row.block_id}\t${row.bud_id}\t${row.tree_id}\t${row.minor_ax}\t${row.major_ax}\t${row.volume_mm}\n`;
            });
        }
        return result;
    }

    // Format data
    const formattedData = data.map(row => ({
        ...row,
        minor_ax: parseFloat(row.minor_ax).toFixed(4),
        major_ax: parseFloat(row.major_ax).toFixed(4),
        volume_mm: parseFloat(row.volume_mm).toFixed(4),
        block_id: blockName // Add the block ID to each row
    }));

    const columns = React.useMemo(
        () => [
            {
                Header: 'Block Name', // Add new column
                accessor: 'block_id', // new accessor
            },
            {
                Header: 'Fruitlet ID',
                accessor: 'bud_id',
            },
            {
                Header: 'Tree ID',
                accessor: 'tree_id',
            },
            {
                Header: 'Diameter Width (mm)',
                accessor: 'minor_ax',
            },
            {
                Header: 'Fruitlet Length (mm)',
                accessor: 'major_ax',
            },
            {
                Header: 'Volume (mm\u00B3)',
                accessor: 'volume_mm',
            },
        ],
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: formattedData });

    return (
        <div className='font-lato overflow-hidden relative w-[60vw] max-h-[100vh] col-span-2 m-6' >
            <div className="flex items-center">
                <h1 className="text-3xl font-bold mb-2 mr-4 ">Fruitlet Size Distribution Model</h1>
                <span className="inline-block bg-rose-500 text-white rounded-full px-6 py-1 text-sm font-bold mb-2">BETA</span>
            </div>
            <hr className='border-gray-300 my-2' />
            <p className="my-1 text-md">
                1.  Credit for the Fruitlet Size Distribution (FSD) Model goes to Laura Hillmann and {' '}
                <a href="https://www.canr.msu.edu/people/dr_todd_einhorn" target="_blank" rel="noopener noreferrer" className="text-red-500 underline">
                    Todd Einhorn
                </a>
                {' '} at MSU for creating this model and easy-to-use Excel implementation.
            </p>
            <p className="my-1 text-md">
                2. Instructions to use the FSD Model can be found {' '}
                <a href="https://pacman.extension.org/2023/03/06/the-fruitlet-size-distribution-fsd-model-a-how-to-guide/" target="_blank" rel="noopener noreferrer" className="text-red-500 underline">
                    here
                </a>
                , {' '} on the PACMAN Extension website.
            </p>
            <p className="my-1 text-md">
                3. You can download the {' '}
                <a href="https://www.dropbox.com/scl/fi/x61v11umcczszo8rl68f4/FS-Predict.xlsm?dl=0&rlkey=nrctykltjyon8m79uvvvh69t9" target="_blank" rel="noopener noreferrer" className="text-red-500 underline">
                    Fruitlet Size Distribution Model Excel
                </a>
                {' '} file here.
            </p>
            <p className="my-1 text-md">4. Below are fruitlet diameter width values, along with fruitlet volume values, that can be copied directly into the model, using the <i>Copy Data</i> button.</p>
            <p className="my-1 text-md ">5. If you need any support or have any questions, feel free to contact us below at the <i>Need Help?</i> button in the bottom right. </p>
            <hr className='border-gray-300 my-2' />
            <button className='btn-primary my-1'
                onClick={() => {
                    setCopyPressed(true)
                    if (navigator.clipboard) {
                        navigator.clipboard.writeText(convertToCSV(data))
                    } else {
                        // Fallback for older browsers
                        var textarea = document.createElement("textarea");
                        textarea.textContent = convertToCSV(data);
                        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                        document.body.appendChild(textarea);
                        textarea.select();
                        try {
                            document.execCommand("copy");  // Security exception may be thrown by some browsers.
                        } catch (ex) {
                            console.warn("Copy to clipboard failed.", ex);
                        } finally {
                            document.body.removeChild(textarea);
                        }
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            setCopyPressed(false)
                            setOpenSnackbar(false);
                        }, 2000);
                    }
                }}>
                {!copyPressed ? <p> Copy Data</p> : <p> Data Copied! </p>}
            </button>
            <button className='btn-primary m-2  '
                onClick={() => setSdmTableView(false)}> Close Fruitlet Size Distribution Model</button>

            <div className='mt-4 overflow-y-auto min-h-[40vh] max-h-[50vh] max-w-[63vw] min-w-full scrollbar-thin scrollbar-visible scrollbar-thumb-gray-400 scrollbar-track-gray-200"'>
                <table {...getTableProps()} className='min-w-full divide-y divide-gray-200'>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id} className='divide-x divide-gray-200'>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} key={column.id} className='px-4 py-3 text-sm bg-slate-200 font-bold tracking-wider text-left text-gray-700 uppercase'>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className='min-w-full bg-white divide-y divide-gray-200'>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={row.id} className='min-w-full hover:bg-emerald-50 divide-x divide-gray-200'>
                                    {row.cells.map(cell => (
                                        <td key={cell.column.id} {...cell.getCellProps()} className='min-w-full px-4 py-2 text-sm text-gray-500'>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <HelpControl />

            {/* {copyPressed === true ? <p className='ml-1 text-sm text-red-500'> Data copied!</p> : null} */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    Data copied!
                </Alert>
            </Snackbar>
        </div>
    );
};