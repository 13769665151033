import React from 'react';
import { Bar, BarChart, CartesianGrid, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export const PackoutChart = ({ data }) => {
  const chartData = Object.entries(data).map(([size, value]) => ({ size, value }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={chartData} margin={{ left: 4, bottom: 15, top: 30 }}>
        <text
          x={300}
          textAnchor="middle"
          dominantBaseline="hanging"
          fill="#333"
          fontSize="16"
          fontWeight="bold"
        >
          Estimated Packout Distribution
        </text>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="size">
          <Label value="Pack Size" position="insideBottom" offset={-8} />
        </XAxis>
        <YAxis label={{ value: 'Percentage', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Bar dataKey="value" fill="#0000FF">
          <LabelList dataKey="value" position="top" formatter={(value) => `${Number(value).toFixed(1)}%`} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};