import firebase from 'firebase/compat/app';
import React, { useState } from 'react';
import { auth } from '../firebase';
import logo from '../assets/logo.svg';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const Login = () => {
	// Inputs
	const [mynumber, setnumber] = useState("");
	const [otp, setotp] = useState('');
	const [show, setshow] = useState(false);
	const [final, setfinal] = useState('');

	// Sent OTP
	const signin = () => {

		if (mynumber === "" || mynumber.length < 10) return;
		let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
		auth.signInWithPhoneNumber("+" + mynumber, verify).then((result) => {
			setfinal(result);
			alert("Login code sent! Please check +" + mynumber)
			setshow(true);
		})
			.catch((err) => {
				alert(err);
				window.location.reload()
			});
	}

	// Validate OTP
	const ValidateOtp = () => {
		if (otp === null || final === null)
			return;
		final.confirm(otp).then((result) => {
			// success
		}).catch((err) => {
			alert("Wrong code");
		})
	}

	return (

		<div style={{ "marginTop": "50px", "textAlign": "center" }}>

			<img style={{ maxWidth: '120px', maxHeight: '120px' }} className='inline' src={logo} alt='Orchard Robotics Logo' />
			<div className='font-bold text-3xl m-3 text-white '> Welcome to FruitScope Cloud! </div>
			<br /><br />



			<div style={{ padding: '5px' }} className='text-white '>Sign in with SMS</div>

			<div className='p-2' style={{ display: !show ? "block" : "none" }}>
				<div style={{ "textAlign": "left", "display": "inline-block" }}>


					<PhoneInput
						country={'us'}
						value={mynumber}
						onChange={setnumber}
						containerStyle={{ width: '350px', height: '45px' }}
						inputStyle={{ width: '100%', height: '100%' }}
					/>
				</div>

				<div style={{ paddingTop: '10px', paddingBottom: '5px', display: "flex", justifyContent: "center", alignItems: "center" }} id="recaptcha-container"></div>

				<button style={{ maxWidth: '350px' }}
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={signin}
				>Login</button>

			</div>
			<div style={{ display: show ? "block" : "none" }}>
				<input style={{ width: '350px', height: '45px' }} className='py-2 px-4 rounded w-full mt-2' type="text" placeholder={"Enter the 6-digit code sent to your phone"}
					onChange={(e) => { setotp(e.target.value) }}></input>
				<div style={{ paddingTop: '10px', paddingBottom: '5px' }} />
				<button style={{ maxWidth: '350px' }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-2" onClick={ValidateOtp}>Verify</button>
			</div>
			{/* </center > */}
		</div >
	);
}

export default Login;