import { faInfoCircle, faMapLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { toast } from 'react-hot-toast';
import loading from '../assets/loading.gif';
import { StageType } from '../common/types';
import * as Constants from '../constants/constants';
import * as GraphNames from '../constants/graphNames';
import { iconNames } from '../constants/iconNames';
import { BlockTimeline } from './BlockTimeline';
import { Branding, ImageDisplayModes } from './Branding';
import { Card, OrchardCard } from './Cards';
import { Charts } from './ChartCard';
import { DownloadCard } from './DownloadCard';
import { HarvestEstimateCard } from './HarvestEstimateCard';
import { OrchardView } from './OrchardView';
import { PackoutCard } from './PackoutCard';
import { PolygonStats } from './PolygonStats';
import { ScanViewStats } from './ScanViewStats';


export const MetricItem = ({ metric, label, icon, units, infoTip }) => (
    <div className='flex flex-row justify-between items-baseline mt-1'>
        <span className='flex flex-row'>
            {icon ? <FontAwesomeIcon size='lg' icon={icon} fixedWidth /> : null} {' '}
            <span className={'text-l ' + (icon ? ' pl-2' : '')}>{label}</span>
        </span>
        <span className='pl-2 text-l flex items-center'>
            {metric} {units}
            {infoTip && (
                <Tooltip title={infoTip} arrow>
                    <span className="ml-1">
                        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                    </span>
                </Tooltip>
            )}
        </span>
    </div>
);

function capitalizeFirstLetter(string) {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const LoadView = ({ blockName, handlePressBack }) => (
    <>
        <Card title='Load View'>
            <p className='pb-1 text-xs italic text-slate-500 pt-2 text-left text-gray-500'>    A view of your entire block. Statistics are displayed by individual scans taken within this block.</p>
            <button className='btn-primary my-2 absolute top-1 right-3' onClick={handlePressBack}> Back </button>
            <></>
            {blockName ?
                <div className="flex flex-col items-center">
                    <p className='pb-1 text-xs italic text-slate-500 pt-1 text-left text-gray-500'>Updating all scan data...</p>
                    <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={loading} alt='loading...' />
                </div>
                : <p> No scans to show</p>}
        </Card>
    </>
);

const BlockView = ({ blockView, handleIconClick, isExpanded, setIsExpanded, timelineData, truthfulScanName, handlePressBack, blockInfo }) => (

    <>
        <OrchardCard blockView={blockView} isExpanded={isExpanded} title='Block View' description={' A view of your entire block. Statistics are displayed by individual scans taken within this block.'}>
            <button className='btn-primary my-2 absolute top-0 right-3' onClick={handlePressBack}> Back </button>
            <></>
            {blockInfo && blockInfo.block_id ? (
                <MetricItem key={Constants.BLOCK_ID} icon={iconNames[Constants.BLOCK_ID]} metric={blockInfo.block_name.toLocaleString('en-US')} label={'Block ID'} />
            ) : null}
            {blockInfo && blockInfo.acreage ? (
                <MetricItem key={"Block Acreage"} icon={faMapLocationDot} metric={blockInfo.acreage.toFixed(1)} label={"Acreage"} units={'acres'} />
            ) : null}
            <h1 className='text-center font-bold text-xl font-lato'> Scan Timeline</h1>
            <hr className='border-gray-300 mt-1' />

            {timelineData ?
                <>
                    <BlockTimeline
                        timelineData={timelineData}
                        handleIconClick={handleIconClick}
                        truthfulScanName={truthfulScanName}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                    >
                    </BlockTimeline>
                </> : null
            }

            {!isExpanded ?
                <button className='mx-4 btn-primary mb-2' onClick={() => setIsExpanded(true)}> Show full timeline </button> : null}
        </OrchardCard>
    </>
);


const ScanNotes = ({ scanInfo }) => (
    <Card title='Scan Info' showButton description={'Additional details about the scan.'}>
        {scanInfo.technician_name ? (
            <MetricItem key={"Technician Name"} metric={scanInfo.technician_name} label={"Scanned By: "} />
        ) : null}
        {scanInfo.tree_structure ? (
            <MetricItem key={"Trellis Type"} metric={scanInfo.tree_structure} label={"Trellis Type: "} />
        ) : null}
        {scanInfo.avg_tree_spacing ? (
            <MetricItem key={"Tree Spacing"} metric={scanInfo.avg_tree_spacing.toFixed(2)} label={"Reported tree spacing: "} units={"m"} />
        ) : null}
        {scanInfo.rows_in_block ? (
            <MetricItem key={"Rows in Block"} metric={scanInfo.rows_in_block} label={"Reported total rows in block: "} units={"rows"} />
        ) : null}
        {scanInfo.rows_scanned ? (
            <MetricItem key={"Rows Scanned"} metric={scanInfo.rows_scanned} label={"Reported rows scanned: "} units={"rows"} />
        ) : null}
    </Card>
)

export const Sidebar = ({ setBlockName, orchard, setSectionGeojson, plotType, selectedVarieties, varietyList, downloadFormat, setDownloadFormat, setPlotType, polygonStats, addToSidebar, chartType, setChartType, mapInstance, scanLoading, setScanLoading, blockScanMap, entity, setProgress, progress, uniformTarget, setTruthfulScanName, setTruthfulScanId, stats, truthfulScanName, setSidebarViewMode, sidebarViewMode, setBlockView, blockName, blockView, enableRowFilter, stageType, blockInfo, setBlockInfo, setTreeViewLat, setTreeViewLong, sidebarWidth, setSidebarWidth, scanInfo, adminUser, treeData }) => {
    const [imgDisplayMode, setImgDisplayMode] = useState(ImageDisplayModes.FULL_A);
    const [isExpanded, setIsExpanded] = useState(false);
    const [timelineData, setTimelineData] = useState([]);

    const sidebarRef = useRef(null);
    const dragHandleRef = useRef(null);

    useEffect(() => {
        if (entity === 'trees') {
            setChartType('trunk_diam_hist_')
        }
        else if (entity === 'fruits') {
            if (plotType === 'color') {
                setChartType('fruit_color_histogram_')
            }
            else{
                setChartType('row_bars_')
            }
        }
        
    }, [plotType, entity])

    useEffect(() => {
        (async () => {
            if (blockName) {
                try {
                    const response = await axios.get('/util/block_timeline', {
                        params: { 'block_id': blockName },
                    });

                    if (response.status === 200) {
                        setTimelineData(response.data);
                    }
                } catch (error) {
                    toast.error('Error fetching block data, hit back to return to the orchard view.');
                }
            }
        })();
    }, [blockName]);

    const chartConfigurations = {
        'fruit_histogram_': {
            defaultChartType: 'fruit_histogram_'
        },
        'fruitlet_histogram_': {
            defaultChartType: 'fruitlet_histogram_'
        },
        'packout_histogram_': {
            defaultChartType: 'packout_histogram_'
        },
        'plu_histogram_': {
            defaultChartType: 'plu_histogram_'
        },
        'row_bars_': {
            defaultChartType: 'row_bars_'
        },
        'seen_count_': {
            defaultChartType: 'seen_count_'
        },
        'fruit_count_': {
            defaultChartType: 'fruit_count_'
        },
        'row_sizes_': {
            defaultChartType: 'row_sizes_'
        },
        'fruit_sizes_': {
            defaultChartType: 'fruit_sizes_'
        },
        'weight_oz_histogram_': {
            defaultChartType: 'weight_oz_histogram_'
        },
        'fruit_color_histogram_': {
            defaultChartType: 'fruit_color_histogram_'
        },
        'weight_g_histogram_': {
            defaultChartType: 'weight_g_histogram_'
        },
        'fruit_color_coverage_': {
            defaultChartType: 'fruit_color_coverage_'
        },
        'fruit_width_over_time_': {
            defaultChartType: 'fruit_width_over_time_'
        },
        'fruit_volume_over_time_': {
            defaultChartType: 'fruit_volume_over_time_'
        },
        'fruit_per_tree_over_time_': {
            defaultChartType: 'fruit_per_tree_over_time_'
        },
        'trunk_diam_hist_': {
            defaultChartType: 'trunk_diam_hist_'
        },
        'trunk_size_hist_': {
            defaultChartType: 'trunk_size_hist_'
        },
        'trunk_height_hist_': {
            defaultChartType: 'trunk_height_hist_'
        },
        'trunk_row_diams_': {
            defaultChartType: 'trunk_row_diams_'
        },
        'trunk_row_height_': {
            defaultChartType: 'trunk_row_height_'
        },
        'trunk_row_count_': {
            defaultChartType: 'trunk_row_count_'
        },
        'trunk_spacing_hist_': {
            defaultChartType: 'trunk_spacing_hist_'
        },
        'trunk_row_spacing_': {
            defaultChartType: 'trunk_row_spacing_'
        },
        'trunk_row_vigor_': {
            defaultChartType: 'trunk_row_vigor_'
        },
        'trunk_vigor_hist_': {
            defaultChartType: 'trunk_vigor_hist_'
        },
        'canopy_hue_hist_': {
            defaultChartType: 'canopy_hue_hist_'
        },
        [GraphNames.FRUIT_VOLUME_TIME_HISTOGRAM_PREFIX]: {
            defaultChartType: GraphNames.FRUIT_VOLUME_TIME_HISTOGRAM_PREFIX
        },
    };

    const handleIconClick = (scanName, scanId) => {
        setTruthfulScanName(scanName);
        setTruthfulScanId(scanId);
        if (isExpanded) {
            setScanLoading(true)
        }
        setIsExpanded(false);
        setTimeout(() => {
            if (isExpanded) {
                setScanLoading(false)
            }
        }, 4000);
    };

    const handleChange = (event) => {
        setChartType(event.target.value);
    };

    const handleDownloadChange = (event) => {
        setDownloadFormat(event.target.value);
    };

    const handlePressBack = () => {
        // TODO: Some of these are redundant, see blockName useEffect in App.tsx
        setBlockView(false);
        setPlotType('uniform')
        setSidebarViewMode('Orchard');
        setTruthfulScanName(null);
        setTruthfulScanId(null);
        setBlockName(null);
        setProgress(100);
        setTimelineData([]);
        setIsExpanded(false);
        setBlockInfo(null)
        setSectionGeojson(null)
    }

    const handleDrag = (e, { deltaX }) => {
        setSidebarWidth(prevWidth => {
            const newWidth = prevWidth + deltaX;
            const maxWidth = window.innerWidth - 340;
            return newWidth >= 340 ? (newWidth <= maxWidth ? newWidth : maxWidth) : 340;
        });
    };

    const SidebarView = () => {
        if ((blockView || sidebarViewMode === 'Block')) {
            return stats === null ? (
                <div className='grid h-screen place-items-center'>
                    <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={loading} alt='loading...' />
                </div>
            ) : (
                <>


                    {!isExpanded && !scanLoading && stats ?
                        <ScanViewStats
                            entityType={entity}
                            stats={stats}
                            stageType={stageType}
                        />
                        : null
                    }
                    {!isExpanded && scanInfo && scanInfo.avg_tree_spacing && !scanLoading ?
                        <>
                            <ScanNotes scanInfo={scanInfo} >
                            </ScanNotes>
                        </>
                        : null}
                </>
            );
        }
        return <>
            <OrchardView className='text-sm'
                orchard={orchard}
                blockScanMap={blockScanMap}
                blockView={blockView}
                setBlockView={setBlockView}
                setTreeViewLat={setTreeViewLat}
                setTreeViewLong={setTreeViewLong}
                setSidebarViewMode={setSidebarViewMode}
                setBlockName={setBlockName}
            >
            </OrchardView>
        </>
    }

    const ChartView = ({ chartType }) => {
        const config = chartConfigurations[chartType];
        if (!config) return null;

        const imgPath = config.imgPathKey ? config.imgPathKey() : null;

        if (blockView || sidebarViewMode === 'Block') {

            return stats === null ? (
                <div className='grid h-screen place-items-center'>
                    <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={loading} alt='loading...' />
                </div>
            ) : (
                <Charts
                    entityType={entity}
                    scanId={truthfulScanName}
                    handleChange={handleChange}
                    chartType={chartType || config.defaultChartType}
                    blockId={blockName}
                    imgPath={imgPath}
                    sidebarViewMode={sidebarViewMode}
                    selectedVarieties={selectedVarieties}
                    enableRowFilter={enableRowFilter}
                    stageType={stageType}
                    stats={stats}
                />
            );
        }
        return null;
    };

    const toggleImgDisplayMode = (mode) => {
        setImgDisplayMode((mode + 1) % 6);
    }

    return (
        <div
            ref={sidebarRef}
            style={{ width: sidebarWidth, minWidth: '340px' }}
            className='font-lato col-span-1 flex flex-col h-screen bg-gray-300 relative z-30 border-x-orchardGreen border-x-2'>
            <Branding imgDisplayMode={imgDisplayMode} toggleImgDisplayMode={toggleImgDisplayMode} scanInfo={scanInfo} />
            <div className={blockView && !isExpanded ? 'overflow-y-auto flex-grow h-0' : 'overflow-hidden flex-grow h-0'}>
                <div className='flex flex-col h-full py-3 space-y-3'>
                    {progress === 100 ? (
                        <>
                            {blockView === true && !scanLoading && (
                                <BlockView className='text-sm'
                                    blockView={blockView}
                                    handlePressBack={handlePressBack}
                                    truthfulScanName={truthfulScanName}
                                    timelineData={timelineData}
                                    handleIconClick={handleIconClick}
                                    isExpanded={isExpanded}
                                    setIsExpanded={setIsExpanded}
                                    blockInfo={blockInfo}
                                />
                            )}
                            {addToSidebar === true && !isExpanded && blockView === true && !scanLoading && polygonStats !== null && (
                                <Card title={`Area Selection ${capitalizeFirstLetter(entity)} Scan Statistics`} showButton defaultOpen={true} description={'View statistics about the selected area of the scan.'}>
                                    <PolygonStats entityType={entity} stageType={stageType} stats={polygonStats} />
                                </Card>
                            )}
                            <SidebarView />
                            {blockView === true && scanLoading && (
                                <BlockView className='text-sm'
                                    blockView={blockView}
                                    handlePressBack={handlePressBack}
                                    truthfulScanName={truthfulScanName}
                                    timelineData={timelineData}
                                    handleIconClick={handleIconClick}
                                    isExpanded={isExpanded}
                                    setIsExpanded={setIsExpanded}
                                    blockInfo={blockInfo}
                                />
                            )}

                            {stageType === StageType.Fruit && blockView === true && (
                                <HarvestEstimateCard stats={stats} scanInfo={scanInfo}/>
                            )}

                            {stageType === StageType.Fruit && blockView === true && (
                                <PackoutCard stats={stats} />
                            )}
                            {stats && !isExpanded && !scanLoading && (
                                <ChartView chartType={chartType}
                                    isCalibrated={!!stats.total_fruits_calibrated} />
                            )}
                            {!isExpanded && !scanLoading && varietyList && (
                                <>
                                    {/* <VarietyCard setVarietyList={setVarietyList} varietyList={varietyList} truthfulScanName={truthfulScanName} blockName={blockName} entityType={entityType} > */}
                                    {/* </VarietyCard> */}
                                    {/* TODO: This is showing up in orchard view as well */}
                                </>
                            )}
                            {stats && !isExpanded && !scanLoading && blockView === true && (
                                <DownloadCard
                                    plotType={plotType}
                                    setPlotType={setPlotType}
                                    selectedVarieties={selectedVarieties}
                                    entityType={entity}
                                    handleDownloadChange={handleDownloadChange}
                                    downloadFormat={downloadFormat}
                                    truthfulScanName={truthfulScanName}
                                    mapInstance={mapInstance}
                                    uniformTarget={uniformTarget}
                                    blockName={blockName}
                                    treeData={treeData}
                                    stats={stats}
                                />
                            )}
                        </>
                    ) : progress !== 100 ? (
                        <LoadView blockName={blockName} handlePressBack={handlePressBack} />
                    ) : null}
                    <div className='text-lg py-5' />
                </div>
            </div>
            <Draggable
                axis='x'
                handle='.sidebar-handle'
                onDrag={handleDrag}
                position={{ x: 0, y: 0 }}
                nodeRef={dragHandleRef}
            >
                <div
                    ref={dragHandleRef}
                    className='sidebar-handle absolute top-0 right-0 w-2 h-full cursor-col-resize'
                />
            </Draggable>
        </div>
    )
}