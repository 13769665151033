export const iconNames = {
    'fruitingbuds': 'fa-solid fa-seedling',
    'ruler': 'fa-solid fa-ruler',
    'house': "fa-solid fa-house",
    'apple': 'fa-solid fa-apple-whole',
    'fruitlets': 'fa-solid fa-apple-whole',
    'early fruitlets': 'fa-solid fa-apple-whole',
    'tree': 'fa-solid fa-tree',
    'blossoms': 'fa-solid fa-spa',
    'block_id': 'fa-tag',
    "Selected Point": 'fa-solid fa-location-dot',
    "area": "fa-solid fa-chart-area",
    "buds": "fa-solid fa-leaf",
    'number': "fa-solid fa-hashtag",
    "year": 'fa-solid fa-calendar-days',
    "grid": 'fa-solid fa-border-all',
    'arrowright': 'fa-solid fa-arrow-right',
    'blooms': 'fa-solid fa-spa',
    'calendar': 'fa-solid fa-calendar',
    'fruits': 'fa-solid fa-apple-whole',
    'chart': 'fa-solid fa-chart-simple'
};