import { faCrateApple, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    InputAdornment,
    OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import convert from 'convert-units';
import { differenceInDays, parseISO, startOfDay } from 'date-fns';
import dayjs from 'dayjs';
import * as math from 'mathjs';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import regression from 'regression';
import { Card } from './Cards';
import { PackoutChart } from './components/PackoutChart';
import { VolumeChart } from './components/VolumeChart';
import { FeedbackForm } from './components/FeedbackForm';
export const HarvestEstimateCard = ({ stats, scanInfo, qualifyingBlocks }) => {
    const scanDate = dayjs(parseISO(startOfDay(stats.timestamp).toISOString()));

    // Inputs
    const [volumeGrowthRate, setVolumeGrowthRate] = useState(null);
    const [numQualityScans, setNumQualityScans] = useState(0);
    const [dateHarvested, setDateHarvested] = useState(null);
    const [density, setDensity] = useState(null);
    const [volumeChartData, setVolumeChartData] = useState(null);

    const [averageBinWeight, setAverageBinWeight] = useState(880);
    const [weightPerBox, setWeightPerBox] = useState(40);
    const [cullagePercent, setCullagePercent] = useState(5);
    const [binsPerAcre, setBinsPerAcre] = useState(0);
    const [totalBins, setTotalBins] = useState(0);
    const total_fruit = stats.total_fruit_calibrated_estimated !== 0 ? numeral(Math.round(stats.total_fruit_calibrated_estimated)).format('0,0') : numeral(Math.round(stats.total_fruit_detected)).format('0,0');
    const [treesPerAcre, setTreesPerAcre] = useState(0);
    const [linearRegression, setLinearRegression] = useState(null);
    const avgFruitDiam = stats.avg_fruit_diam !== null ? stats.avg_fruit_diam : 0;
    const [optimalDates, setOptimalDates] = useState(null);

    // Outputs
    const [peakPackSize, setPeakPackSize] = useState(null);
    const [packoutData, setPackoutData] = useState(null);
    const [estimatedVolume, setEstimatedVolume] = useState(null); // mm

    const [totalFruitWeight, setTotalFruitWeight] = useState(0);

    // Render Conditions
    const [renderCard, setRenderCard] = useState(false);
    const [loading, setLoading] = useState(true);
    const [packoutDataLoading, setPackoutDataLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [minHarvestDate, setMinHarvestDate] = useState(null);

    const [daysDifference, setDaysDifference] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/util/get_harvest_estimate_info_for_scan', {
                params: { 'block_id': scanInfo.block_id }
            });


            setRenderCard(response.data.is_qualifying);
            setNumQualityScans(response.data.qualifying_scans_count);
            
            if (response.data.is_qualifying) {
                setDensity(response.data.density);
                setTreesPerAcre(response.data.trees_per_acre);


                const chartData = Object.entries(response.data.volume_data).map(([date, value]) => ({
                    date: new Date(Date.parse(date + 'T00:00:00-08:00')).getTime(), 
                    volume: parseFloat(value)
                })).sort((a, b) => a.date - b.date);

                const chartDataForRegression = chartData.map(({ date, volume }) => {
                    const daysSinceStart = differenceInDays(date, chartData[0].date);
                    return [daysSinceStart, volume];
                });
                const regressionResult = regression.linear(chartDataForRegression);
                // console.log("Regression", regressionResult);
                setLinearRegression(regressionResult);
                setVolumeChartData(chartData);
                setVolumeGrowthRate(Math.round(regressionResult.equation[0]));

                // Set the default harvest date to the day after the scan date
                const nextDay = scanDate.add(1, 'day');
                setDateHarvested(dayjs(nextDay));
                setMinHarvestDate(dayjs(nextDay));
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log("ScanInfo", scanInfo);
        if (scanInfo) {
            fetchData();
        }
    }, [scanInfo]);

    useEffect(() => {
        const fetchOptimalDates = async () => {
            if (volumeGrowthRate && stats) {
                try {
                    console.log("stats ", stats);
                    console.log("volumeGrowthRate", volumeGrowthRate);
                    const optimalDatesResponse = await axios.get('/util/get_optimal_packout_dates', {
                        params: { 'scan_id': stats.scan_id, 'growth_rate': volumeGrowthRate }
                    });
                    setOptimalDates(optimalDatesResponse.data);
                } catch (error) {
                    console.error("Error fetching optimal dates:", error);
                    setOptimalDates([]);
                }
            }
        };
        fetchOptimalDates();
    }, [volumeGrowthRate, stats]);

    useEffect(() => {
        if (packoutData) {
            const peak = Object.entries(packoutData).reduce((a, b) => a[1] > b[1] ? a : b);
            setPeakPackSize(peak[0]);
        }
    }, [packoutData]);

    const handleDateHarvestedChange = (date) => {
        setDateHarvested(date);
        setErrorMessage('');
    };

    const handleSubmit = async (harvestDate) => {
        if (!harvestDate) {
            setErrorMessage("Please enter a harvest date.");
            return;
        }
        if (harvestDate.isBefore(scanDate) || harvestDate.isSame(scanDate)) {
            setErrorMessage("Harvest date must be after the scan date.");
            return;
        }
        const daysDifference = harvestDate.diff(scanDate, 'day');
        setDaysDifference(daysDifference);
        setDialogLoading(true);
        setOpenDialog(true);
        const volumeOffset = volumeGrowthRate * daysDifference;
        const projectedVolume = stats.avg_fruit_vol + volumeOffset;
        setEstimatedVolume(projectedVolume);

        setDialogLoading(false);

        // Start loading packout data
        setPackoutDataLoading(true);
        const packout_response = await axios.get('/util/get_packout_data', { params: { scan_id: stats.scan_id, volume_offset: volumeOffset } });
        setPackoutData(packout_response.data);
        if (stats.total_fruit_calibrated_estimated) {
            calculateBinsPerAcre(packout_response.data);
        }
        setPackoutDataLoading(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setPeakPackSize(null);
    };

    const updateAverageBinWeight = (event) => {
        const value = event.target.value;
        if (value > 0) {
            setAverageBinWeight(value);
        } else {
            setAverageBinWeight('');
        }
    };

    const updateWeightPerBox = (event) => {
        const value = event.target.value;
        if (value > 0) {
            setWeightPerBox(value);
        } else {
            setWeightPerBox('');
        }
    };

    const updateCullagePercent = (event) => {
        const value = event.target.value;
        if (value >= 0 && value <= 100) {
            setCullagePercent(value);
        } else {
            setCullagePercent('');
        }
    };

    const renderBlockLinks = () => {
        const protocol = window.location.protocol;
        const host = window.location.host;
        const rootUrl = `${protocol}//${host}/block/`;

        return (
            <div className="mt-2">
                <details className="cursor-pointer">
                    <summary className="text-blue-600 hover:text-blue-800">
                        View Qualifying Blocks
                    </summary>
                    <ul className="mt-2 pl-4 list-disc">
                        {Object.entries(qualifyingBlocks).map(([blockId, blockName]) => (
                            <li key={blockId} className="mt-1">
                                <a
                                    href={`${rootUrl}${encodeURIComponent(blockName)}`}
                                    className="text-blue-600 hover:text-blue-800 underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {blockName}
                                </a>
                            </li>
                        ))}
                    </ul>
                </details>
            </div>
        );
    };

    const formatVolume = (volume) => {
        const mm3 = Math.round(volume);
        const in3 = Number(convert(mm3).from('mm3').to('in3').toFixed(2));
        return { mm3, in3 };
    };

    const calculateDiameterFromVolume = (volume) => {
        // Volume of a sphere: V = (4/3) * π * r^3
        const radius = math.cbrt((3 * volume) / (4 * Math.PI));
        const diameter = 2 * radius;
        return diameter;
    };

    const calculateBinsPerAcre = (packoutValues) => {
        if (averageBinWeight === '' || averageBinWeight <= 0) {
            toast.error('Average bin weight must be greater than 0');
            return;
        }
        if (cullagePercent === '') {
            toast.error('Cullage percent must be provided');
            return;
        }
        if (stats.total_fruit_calibrated_estimated === 0) {
            toast.error('No fruit calibrations available');
            return;
        }
        if (averageBinWeight < weightPerBox) {
            toast.error('Average bin weight must be greater than weight per box');
            return;
        }

        // Given: Total Fruit, Fruit volume distribution (calculated from packout values), and average bin weight
        console.log("Pack Values", packoutValues);
        console.log("total fruit", stats.total_fruit_calibrated_estimated);

        // Apply cullage percentage to the total fruit count
        const totalFruitAfterCullage = stats.total_fruit_calibrated_estimated * (1 - cullagePercent / 100);

        // For each packout value percentage, multiply the value as a percentage by the totalFruitAfterCullage to get fruit counts per value
        const fruitCounts = Object.keys(packoutValues).reduce((obj, size) => {
            obj[size] = packoutValues[size] / 100 * totalFruitAfterCullage;
            return obj;
        }, {});

        // Get total fruit weight per key by multiplying the value for each key by (weightPerBox(40) / size)
        const fruitWeights = Object.keys(fruitCounts).reduce((obj, size) => {
            obj[size] = fruitCounts[size] * (weightPerBox / parseInt(size, 10));
            return obj;
        }, {});

        // Sum the total fruit weight per key to get the total fruit weight
        const totalFruitWeight = Object.values(fruitWeights).reduce((sum, weight) => {
            return sum + weight;
        });
        console.log(averageBinWeight);
        console.log(totalFruitWeight);
        const totalBins = totalFruitWeight / averageBinWeight;
        const binsPerAcre = totalBins / stats.block_acreage;
        console.log(typeof totalBins, totalBins);

        setTotalFruitWeight(totalFruitWeight);
        setBinsPerAcre(binsPerAcre);
        setTotalBins(totalBins);
    };


    const renderOptimalDatesTable = () => {
        if (optimalDates === null) {
            return (
                <div className="flex justify-center items-center h-20">
                    <Typography 
                        variant="body2" 
                        className="text-gray-500 text-center animate-pulse"
                    >
                        Calculating the best harvest dates for each packout size...
                    </Typography>
                </div>
            );
        }

        const sortedDates = [...optimalDates].sort((a, b) => {
            const dateComparison = new Date(a.date) - new Date(b.date);
            if (dateComparison === 0) {
                return b.size - a.size; // Tie-break on target size, decreasing order
            }
            return dateComparison;
        });

        return (
            <div className="mt-2">
                <details className="cursor-pointer">
                    <summary className="text-blue-600 hover:text-blue-800">
                        Estimated Harvest Date by Packout Size
                    </summary>
                    <Typography variant="caption" className="text-gray-500 mt-1 block">
                        Predicts when each pack size will reach its maximum percentage of fruit in the block. Click an entry on the table to jump right to the harvest estimate on that date
                    </Typography>
                    <TableContainer component={Paper} className="mt-4">
                        <Table size="small" aria-label="optimal harvest dates table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="font-semibold">Harvest Date</TableCell>
                                    <TableCell align="right" className="font-semibold">Target Size</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedDates.map(({ date, size }) => (
                                    <TableRow 
                                        key={`${date}-${size}`}  // Changed this line
                                        hover
                                        onClick={() => {
                                            const selectedDate = dayjs(date);
                                            setDateHarvested(selectedDate);
                                            handleSubmit(selectedDate);
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <TableCell component="th" scope="row">
                                            {dayjs(date).format('MMM D, YYYY')}
                                        </TableCell>
                                        <TableCell align="right">{size}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </details>
            </div>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Card
                title={
                    <>
                        Estimate {scanInfo.variety_type && scanInfo.variety_type !== "OTHER" ? `${scanInfo.variety_type} ` : ''}Packout at Harvest
                        <sup className="text-red-500 text-xs font-bold ml-1">New!</sup>
                    </>
                }
                showButton
                defaultOpen={true}
                description={'Use volume growth rate to estimate fruit volume on a given harvest date'}
            >
                {loading ? (
                    <div className="flex justify-center items-center h-24">
                        <CircularProgress />
                    </div>
                ) : renderCard ? (
                    <div className="space-y-2">
                        <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
                            <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="body2" style={{ margin: '5px 0', fontSize: '14px', textAlign: 'center' }}>Estimated Harvest Date</Typography>
                                    <DatePicker
                                        value={dateHarvested}
                                        onChange={handleDateHarvestedChange}
                                        minDate={minHarvestDate}
                                        slotProps={{
                                            textField: {
                                                size: "small",
                                                error: !!errorMessage,
                                                helperText: errorMessage,
                                                sx: { 
                                                    width: '125px',
                                                    '& .MuiInputBase-root': {
                                                        height: '40px'
                                                    }
                                                }
                                            }
                                        }}
                                    />
                            </Grid>
                            {stats.total_fruit_calibrated_estimated !== 0 ? (
                                <>
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="body2" style={{ margin: '5px 0', fontSize: '14px', textAlign: 'center' }}>Historical Avg Bin Weight</Typography>
                                        <OutlinedInput
                                            type="number"
                                            size="small"
                                            style={{ width: '125px' }}
                                            value={averageBinWeight}
                                            onChange={updateAverageBinWeight}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                style: { textAlign: 'center' },
                                                min: 0
                                            }}
                                            endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="body2" style={{ margin: '5px 0', fontSize: '14px', textAlign: 'center' }}>Weight per box</Typography>
                                        <OutlinedInput
                                            type="number"
                                            size="small"
                                            style={{ width: '125px' }}
                                            value={weightPerBox}
                                            onChange={updateWeightPerBox}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                style: { textAlign: 'center' },
                                                min: 0
                                            }}
                                            endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="body2" style={{ margin: '5px 0', fontSize: '14px', textAlign: 'center' }}>Estimated In-field Cullage</Typography>
                                        <OutlinedInput
                                            type="number"
                                            size="small"
                                            style={{ width: '125px' }}
                                            value={cullagePercent}
                                            onChange={updateCullagePercent}
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                style: { textAlign: 'center' },
                                                min: 0,
                                                max: 100
                                            }}
                                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <Box className="bg-yellow-50 border border-yellow-100 text-yellow-700 px-4 py-3 rounded text-center" role="alert">
                                        <Typography className="font-medium">
                                            Ground truth counts required to calculate bins / acre
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit(dateHarvested)}
                                sx={{ width: '220px' }}
                            >
                                Calculate Estimate
                            </Button>
                        </Box>
               
                    </div>
                ) : (
                    <div className="bg-yellow-50 border border-yellow-100 text-yellow-700 px-4 py-3 rounded relative" role="alert">
                        <p className="font-medium">To qualify for this estimate, this block needs 3+ previous fruit scans, on unique days, with 100,000+ detected fruit, after July 1st, 2024. </p>
                        <p className="font-medium">(As of the time of this scan, {numQualityScans} scan(s) fit this criteria in this block. Expand &apos;Scan Timeline&apos; for more context)</p>
                    </div>
                )}
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                    maxWidth="lg"
                    fullWidth
                    scroll="paper"
                    PaperProps={{
                        style: {
                            maxHeight: '100vh',
                            overflowY: 'hidden'
                        }
                    }}
                >
                    <DialogTitle id="dialog-title">
                        Harvest Estimation for Block {stats.block_name}
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDialog}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {dialogLoading ? (
                            <CircularProgress />
                        ) : (
                            <Box>
                                <Grid container rowSpacing={2} columnSpacing={8} sx={{ mb: 2 }}>
                                    {/* Top Left Quadrant */}
                                    <Grid item xs={6}>
                                        <Typography variant="h6" fontWeight="bold" sx={{ textAlign: 'center' }}>Measurements</Typography>
                                        <Typography variant="body2" sx={{ textAlign: 'center', mb: 2, color: 'text.secondary' }}>
                                            Scan Date: {scanDate.format('MMMM D, YYYY')}
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>Trees per acre</span>
                                            <span>
                                                {treesPerAcre} trees/acre
                                                <Tooltip title="If this number does not look right, it is possible that not all trees in the block have been scanned, and will thus underestimate harvest" arrow>
                                                    <span style={{ marginLeft: '5px' }}>
                                                        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Variety</span>
                                            <span>{scanInfo.variety_type !== null ? scanInfo.variety_type : 'N/A'}</span>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>Fruit density</span>
                                            <span>
                                                {density !== null ? `${density.toFixed(2)} g/cm³` : 'N/A'}
                                                <Tooltip title="Density measurement taken after cold storage and retail display. While not representative of harvest density, it reflects the fruit's condition during packing and distribution." arrow>
                                                    <span style={{ marginLeft: '5px' }}>
                                                        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Average fruit diameter</span>
                                            <span>{avgFruitDiam.toFixed(2)}mm ({convert(avgFruitDiam).from('mm').to('in').toFixed(2)}in)</span>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Average fruit volume</span>
                                            <span>{formatVolume(stats.avg_fruit_vol).in3} in³</span>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Volume growth rate</span>
                                            <span>{volumeGrowthRate !== null ? `${Math.round(volumeGrowthRate)} mm³/day` : 'N/A'}</span>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>Growth rate reliability score (R²)</span>
                                            <span>
                                                {linearRegression ? linearRegression.r2.toFixed(2) : 'N/A'}
                                                <Tooltip title="R² score closer to 1.00 yields better harvest estimates (ideally 0.95+)" arrow>
                                                    <span style={{ marginLeft: '5px' }}>
                                                        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </Typography>
                                    </Grid>

                                    {/* Top Right Quadrant */}
                                    <Grid item xs={6}>
                                        <Typography variant="h6" fontWeight="bold" sx={{ textAlign: 'center' }}>Estimations</Typography>
                                        <Typography variant="body2" sx={{ textAlign: 'center', mb: 2, color: 'text.secondary' }}>
                                            Harvest Date: {dateHarvested ? `${dateHarvested.format('MMMM D, YYYY')} (${daysDifference} day(s) after scan)` : 'Not set'}
                                        </Typography>

                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <span>Estimated fruit diameter</span>
                                            <span>
                                                {calculateDiameterFromVolume(estimatedVolume).toFixed(2)}mm ({convert(calculateDiameterFromVolume(estimatedVolume)).from('mm').to('in').toFixed(2)}in)
                                                <Tooltip title="Derived from assuming a spherical estimated volume" arrow>
                                                    <span style={{ marginLeft: '5px' }}>
                                                        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </Typography>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>Average fruit volume at harvest</span>
                                            <span>{formatVolume(estimatedVolume).in3} in³</span>
                                        </Typography>

                                        {!packoutDataLoading && stats.total_fruit_calibrated_estimated !== 0 && (
                                            <>
                                                <hr className="mt-2 mb-2"/>
                                                {peakPackSize && (
                                                    <Typography>
                                                        Peak pack size <span style={{ float: 'right' }}>{peakPackSize || 'N/A'}</span>
                                                    </Typography>
                                                )}
                                                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>Total fruit weight</span>
                                                    <span>{Math.round(totalFruitWeight).toLocaleString()} lbs</span>
                                                </Typography>
                                                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>Estimated total bins</span>
                                                    <span>{Number(totalBins.toFixed(2)).toLocaleString()} bins</span>
                                                </Typography>
                                                <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <span>Bins per acre</span>
                                                    <span>{binsPerAcre.toFixed(2)} bins/acre</span>
                                                </Typography>
                                            </>
                                        )}
                                    </Grid>

                                    {/* Bottom Left Quadrant */}
                                    <Grid item xs={6}>
                                        <Box sx={{ width: '100%', height: 400 }}>
                                            <VolumeChart data={volumeChartData} linearRegression={linearRegression} />
                                        </Box>
                                    </Grid>

                                    {/* Bottom Right Quadrant */}
                                    <Grid item xs={6}>
                                        <Box sx={{ width: '100%', height: 400 }}>
                                            {packoutDataLoading ? (
                                                <div className="flex flex-col justify-center items-center h-full">
                                                    <p> Calculating Packout Estimate for {total_fruit} fruits</p>
                                                    <br />
                                                    <p> This may take a moment</p>
                                                    <br />
                                                    <FontAwesomeIcon icon={faCrateApple} size="5x" beatFade />
                                                </div>
                                            ) : packoutData ? (
                                                <>
                                                    <PackoutChart data={packoutData} />
                                                </>
                                            ) : null}
                                        </Box>
                                    </Grid>
                                </Grid>  
                            </Box>
                        )}
                    </DialogContent>
                </Dialog>

                {renderCard && (<> 
                    <hr className="mt-2"/>
                    {renderOptimalDatesTable()}
                </>)}

                {qualifyingBlocks && (
                    <>
                        <hr className="mt-2"/>
                        {Object.keys(qualifyingBlocks).length > 0 && renderBlockLinks()}
                    </>
                )}
                <hr className="mt-2"/>
                <details className="cursor-pointer mt-2">
                    <summary className="text-blue-600 hover:text-blue-800">
                        Tell us what you think
                    </summary>
                    <div className="mt-2">
                        <FeedbackForm truthfulScanName={scanInfo.scan_name} />
                    </div>
                </details>
            </Card>
        </LocalizationProvider>
    );
};