import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button, Checkbox, FormControl, FormControlLabel, FormGroup
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { React, useEffect, useRef, useState } from 'react';
import legend from '../assets/legend.jpeg';
import { EntityType, StageType } from '../common/types';
import { RowVarietyDialog, RowVarietyWarningDialog } from './MapDialogs';

export const EnableDrawControl = ({ drawEnabled, setDrawEnabled, plotType, setDrawDialog }) => {
    return (
        <div
            className='flex items-center px-2 py-2 font-mono bg-white text-xs cursor-pointer text-black border border-gray-900 rounded-lg'
            onClick={() => {
                setDrawEnabled((b) => !b);
                if (plotType === 'regular') {
                    setDrawDialog(true);
                }
            }}
        >
            <div className='flex-shrink-0'>
                <FontAwesomeIcon icon={'fa-solid fa-vector-square'} size='xl' fixedWidth />
            </div>
            <div className='ml-1'>
                Area Selection
                <br />
                {drawEnabled && (
                    <span className='font-mono text-gray-600'>Click the first node to confirm selection. Press delete to cancel selection</span>
                )}
            </div>
        </div>
    );

}

export const VarietyTypeControl = ({ checkedValues, setCheckedValues, hasRowVarieties, setEnableRowFilter, setAllowedVarieties, setSelectedVarieties, selectedVarieties, varietyList, setVarietyList, setIsLoad, isLoad, scanLoading, setScanLoading, truthfulScanName, controlEnabled }) => {
    const initialVarieties = useRef(varietyList);
    const [visible, setVisible] = useState(true);

    const [rowVarietyDialog, setRowVarietyDialog] = useState(false);
    const [rowVarietyWarningDialog, setRowVarietyWarningDialog] = useState(false)

    const toggleVisible = () => {
        setVisible(v => !v);
    };

    const handleChange = (event) => {
        if (controlEnabled) {
            if (event.target.checked) {
                setCheckedValues(prev => [...prev, event.target.name]);
            } else {
                setCheckedValues(prev => prev.filter(variety => variety !== event.target.name));
            }
        }
    };

    const handleFilterClick = () => {
        if (!hasRowVarieties) {
            setRowVarietyDialog(true);
            return;
        }
        if (checkedValues.length === 0) {
            setRowVarietyWarningDialog(true)
            return
        }
        setSelectedVarieties(checkedValues)
        setAllowedVarieties(checkedValues)
        setEnableRowFilter(true)
    }

    return (
        <div className='font-lato bg-white border border-gray-900 rounded-lg m-2 px-2 pb-2 pt-1 fixed left-1/3 bottom-0 text-lg opacity-80 flex flex-row drop-shadow-md'>
            {rowVarietyDialog && <RowVarietyDialog rowVarietyDialog={rowVarietyDialog} setRowVarietyDialog={setRowVarietyDialog}></RowVarietyDialog>}
            {rowVarietyWarningDialog && <RowVarietyWarningDialog rowVarietyWarningDialog={rowVarietyWarningDialog} setRowVarietyWarningDialog={setRowVarietyWarningDialog}></RowVarietyWarningDialog>}
            {visible ?
                <div>
                    <div className='font-bold text-center'>
                        Variety Filter
                        <FontAwesomeIcon className='px-2 text-2xl align-middle' icon='fa-solid fa-caret-right' onClick={toggleVisible} />
                    </div>
                    {<p className='text-xs mb-1'> Input varieties to use filter </p>}
                    <FormControl component="fieldset">
                        <FormGroup>
                            {initialVarieties.current.map(e => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedValues.includes(e)}
                                            onChange={handleChange}
                                            name={e}
                                            disabled={!controlEnabled}
                                        />
                                    }
                                    label={e}
                                    key={e}
                                />
                            ))}
                            <Button variant="outlined" color="primary" onClick={handleFilterClick}>
                                Filter
                            </Button>
                        </FormGroup>
                    </FormControl>
                </div>
                :
                <div>
                    <FontAwesomeIcon className='text-2xl align-middle px-2' icon='fa-solid fa-caret-left' onClick={toggleVisible} />
                </div>
            }
        </div>
    )
}

export const Legend = () => (
    <div className='fixed bottom-0 bg-white m-2 w-fit flex flex-row left-0 right-0 mx-auto px-2 border border-gray-900 rounded-lg font-lato'>
        <span className='text-center'>Less fruit</span>
        <img src={legend} alt='color scale legend for plots' width={400} className='mx-2 border-x border-gray-900' />
        <span className='text-center'>More fruit</span>
    </div>
)

export const SizeLegend = () => (
    <div className='fixed bottom-0 bg-white m-2 w-fit flex flex-row left-0 right-0 mx-auto px-2 border border-gray-900 rounded-lg font-lato'>
        <span className='text-center'>Less Size Diff</span>
        <img src={legend} alt='color scale legend for plots' width={400} className='mx-2 border-x border-gray-900' />
        <span className='text-center'>More Size Diff</span>
    </div>
)

export const HelpButton = () => (
    <div className='bg-white border border-gray-900 rounded-lg py-2 px-3 fixed bottom-0 z-10 m-2'>
        <a href="sms:+13153597337">
            <FontAwesomeIcon icon='fa-solid fa-question' size='xl' />
        </a>
    </div>
)
const regularHeatmap = {
    value: 'regular',
    label: 'Regular Heatmap',
    description: "Heatmap that shows total number of blossoms per tree"
};

const uniformTargetHeatmap = {
    value: 'uniform',
    label: 'Uniform Target Heatmap',
    description: "Heatmap that shows the difference between current number of blossoms and target blossoms, where the target is set by the user and identical for every tree"
};

const rowAudit = {
    value: 'row_audit',
    label: 'Row Audit Interface (admin)',
    description: "Interface for setting rows."
};

const blockAudit = {
    value: 'block_audit',
    label: 'Block Audit Interface (admin)',
    description: "Interface for modifying blocks."
};

const trunkAudit = {
    value: 'trunk_audit',
    label: 'Trunk Audit Interface (admin)',
    description: "Interface for modifying trunks."
};

const fruitSizeTargetHeatmap = {
    value: 'size',
    label: 'Fruit Size Heatmap',
    description: "Heatmap that shows the average fruit size per tree"
};

// Keep commented visualizations in case they're needed for fruit-level exported data later in the season
export const visualizationConfigSubset = {
    trees: [
        // {
        //     value: 'tree-vigor',
        //     label: 'Tree Vigor Heatmap',
        //     description: "Heatmap that shows the canopy area of a tree"
        // },
        // {
        //     value: 'height',
        //     label: 'Canopy Height Heatmap',
        //     description: "Heatmap that shows the canopy height of a tree"
        // },
        {
            value: 'tree_diam',
            label: 'Trunk Diameter Heatmap',
            description: "Heatmap that shows trunk diameter per tree"
        },
        // {
        //     value: 'canopy_hue',
        //     label: 'Canopy Hue Heatmap',
        //     description: "Heatmap that shows the canopy hue of a tree"
        // },
        // { // TODO: Commented for CHA delivery, uncomment when trunk spacing is handled
        //     value: 'spacing',
        //     label: 'Trunk Spacing Heatmap',
        //     description: "Heatmap that shows the spacing between trees"
        // }
    ],
    fruits: [
        {
            value: 'regular',
            label: 'Regular Heatmap',
            description: "Heatmap that shows total number of buds per tree"
        },
        {
            value: 'size',
            label: 'Fruit Size Heatmap',
            description: "Heatmap that shows the average fruit size per tree"
        },
        {
            value: 'color',
            label: 'Color Heatmap',
            description: "Heatmap that shows the average color of fruits per tree"
        },
        {
            value: 'coverage',
            label: 'Color Coverage',
            description: "Heatmap that shows the average coverage of fruits per tree"
        },
    ],
}
// Keep commented visualizations in case they're needed for fruit-level maps later in the season
export const visualizationConfig = {
    trees: [
        // {
        //     value: 'tree-vigor',
        //     label: 'Tree Vigor Heatmap',
        //     description: "Heatmap that shows the canopy area of a tree"
        // },
        // {
        //     value: 'height',
        //     label: 'Canopy Height Heatmap',
        //     description: "Heatmap that shows the canopy height of a tree"
        // },
        {
            value: 'tree_diam',
            label: 'Trunk Diameter Heatmap',
            description: "Heatmap that shows trunk diameter per tree"
        },
        {
            value: 'canopy_hue',
            label: 'Canopy Hue Heatmap',
            description: "Heatmap that shows the canopy hue of a tree"
        },
        {
            value: 'spacing',
            label: 'Trunk Spacing Heatmap',
            description: "Heatmap that shows the spacing between trees"
        },
    ],
    fruits: [
        // {
        //     value: 'regular',
        //     label: 'Regular Heatmap',
        //     description: "Heatmap that shows total number of buds per tree"
        // },
        {
            value: 'uniform',
            label: 'Fruit Count Heatmap',
            description: "Heatmap that shows the average fruit count per tree"
        },
        // {
        //     value: 'size',
        //     label: 'Fruit Size Target Heatmap',
        //     description: "Heatmap that shows the difference between current average diameter of fruits and target diameter of fruits, where the target is set by the user and identical for every tree"
        // },
        // {
        //     value: 'size_diff',
        //     label: 'Fruit Size Differential Heatmap',
        //     description: "Heatmap that shows the difference in sizes between two scans."
        // },
        // {
        //     value: 'sampled_imgs',
        //     label: 'High-Res Sample Images',
        //     description: "Trees with sampled images."
        // },
        {
            value: 'color',
            label: (
                <>
                    Color Heatmap
                </>
            ),
            description: "Heatmap that shows the average color of fruits per tree"
        },
        // {
        //     value: 'coverage',
        //     label: 'Color Coverage',
        //     description: "Heatmap that shows the average coverage of fruits per tree"
        // },
        {
            value: 'tree-vigor',
            label: 'Tree Vigor Heatmap',
            description: "Heatmap that shows the canopy area of a tree"
        },
    ],// TODO, remove the entity types below
    buds: [regularHeatmap, uniformTargetHeatmap, rowAudit, blockAudit],
    blossoms: [regularHeatmap, uniformTargetHeatmap, rowAudit, blockAudit],
    fruitlets: [regularHeatmap, uniformTargetHeatmap, rowAudit, blockAudit]
};

export const MapSettings = ({
    exclusionArray,
    entityType,
    toggleStyle,
    plotType,
    setPlotType,
    controlEnabled,
    setLowerUniBound,
    setUpperUniBound,
    adminUser,
    viewTapeSections,
    setViewTapeSections,
    viewZoneMap,
    setViewZoneMap,
    sectionGeojson,
    stageType,
    truthfulScanName,
    style,
    view3DMap,
    setView3DMap,
    handlePregenerate,
}) => {
    const [visible, setVisible] = useState(true);
    const [activePopup, setActivePopup] = useState(null);

    useEffect(() => {
        if (entityType === 'trees') {
            setPlotType('tree_diam')
            setLowerUniBound(80)
            setUpperUniBound(120)
        }
        if (entityType === 'fruits') {
            setPlotType('uniform')
            setLowerUniBound(60)
            setUpperUniBound(140)
        }
    }, [entityType])

    const toggleVisible = () => {
        setVisible(v => !v);
    };

    const handleChange = (event) => {
        if (controlEnabled) {
            const value = event.target.value;
            const bounds = {
                'size': [95, 105],
                'uniform': [60, 140],
                'tree_diam': [80, 120],
                'height': [80, 120],
                'tree-vigor': [70, 130],
            };
            setLowerUniBound(bounds[value]?.[0] || 80);
            setUpperUniBound(bounds[value]?.[1] || 120);
            setPlotType(value);
        }
    };

    const handleMouseEnter = (event, id) => {
        setActivePopup(id);
        event.currentTarget.style.position = "relative";
    };

    const handleMouseLeave = (event) => {
        setActivePopup(null);
        event.currentTarget.style.position = "";
    };

    const getVisualizationConfig = (entityType, stageType, adminUser) => {
        let visConfig = [...visualizationConfig[entityType]];
        if (entityType === EntityType.Fruits) {
            if (stageType === StageType.EarlyFruitSet || stageType === StageType.Fruit) {
                visConfig.push(fruitSizeTargetHeatmap);
            }
            if (stageType === StageType.EarlyFruitSet) {
                visConfig = visConfig.filter((item) => item.value !== 'uniform' && item.value !== 'tree-vigor');
            }
        }
        if (adminUser === true) {
            visConfig.push(blockAudit, rowAudit);
            if (entityType === EntityType.Trees) {
                visConfig.push(trunkAudit);
            }
        }
        return visConfig;
    };

    const handleToggleTapeSections = (event) => {
        setViewTapeSections(event.target.checked);
    };

    const handleViewZoneMap = (event) => {
        setViewZoneMap(event.target.checked);
    };

    return (
        <div className='font-lato bg-white border border-gray-900 rounded-lg m-2 px-2 pb-2 pt-1 fixed right-0 top-0 text-lg opacity-80 flex flex-row drop-shadow-md'>
            {visible ? (
                <div className='w-60'>
                    <div className='font-bold text-center'>
                        Plot Type
                        <FontAwesomeIcon className='px-2 text-2xl align-middle' icon='fa-solid fa-caret-right' onClick={toggleVisible} />
                    </div>
                    <hr />
                    <form>
                        {getVisualizationConfig(entityType, stageType, adminUser).map((e, index) => {
                            if (!exclusionArray.includes(e.value)) {
                                return (
                                    <label className='flex flex-row' key={e.value} onMouseEnter={(event) => handleMouseEnter(event, index)}
                                        onMouseLeave={handleMouseLeave}>
                                        <input type='radio' value={e.value} checked={plotType === e.value} onChange={handleChange} disabled={!controlEnabled} />
                                        <span className='ml-2'>{e.label}</span>
                                        {activePopup === index && (
                                            <div className="absolute z-10 top-full left-0 mt-2 w-48 rounded-lg shadow-lg bg-white border border-gray-200">
                                                <div className="py-2 px-4 text-sm">{e.description}</div>
                                            </div>
                                        )}
                                    </label>
                                );
                            }
                            return null;
                        })}
                    </form>
                    {adminUser && (
                        <button style={{ maxWidth: '350px' }}
                            className="btn-primary bg-green-500 hover:bg-green-700"
                            onClick={(e) => handlePregenerate(e, truthfulScanName)}
                        >
                            Pregenerate Scan (admin)
                        </button>
                    )}
                    <hr />
                    {entityType === EntityType.Fruits && sectionGeojson && sectionGeojson.features.length > 0 && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={viewTapeSections}
                                    onChange={handleToggleTapeSections}
                                    className="m-0"
                                />
                            }
                            label="Ground Truth Sections"
                        />
                    )}

                    <FormControlLabel
                        control={
                            <Switch
                                checked={style === 'mapbox://styles/mapbox/outdoors-v12'}
                                onChange={toggleStyle}
                                size="medium"
                                className="m-0"
                            />
                        }
                        label="Toggle Map Style"
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={view3DMap}
                                onChange={(e) => setView3DMap(e.target.checked)}
                                size="medium"
                                className="m-0"
                            />
                        }
                        label={"View Topography"}
                    />
                    
                    {entityType === EntityType.Fruits && plotType !== 'color' && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={viewZoneMap}
                                    onChange={handleViewZoneMap}
                                    size="medium"
                                    className="m-0"
                                />
                            }
                            label={"View Zone Mode"}
                        />
                    )}
                </div>
            ) : (
                <div>
                    <FontAwesomeIcon className='text-2xl align-middle px-2' icon='fa-solid fa-caret-left' onClick={toggleVisible} />
                </div>
            )}
        </div>
    );
};