import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import ALL from '../assets/ALLFruitscope.png';
import CHI from '../assets/CHIFruitscope.png';
import MadeByOrchard from '../assets/MadeByOrchard.png';
import NWF from '../assets/NWFFruitscope.png';
import SAG from '../assets/SAGFruitscope.png';
import STE from '../assets/STEFruitscope.png';
import SVO from '../assets/SVOFruitscope.png';
import ZIR from '../assets/ZIRFruitscope.png';
import loading from '../assets/loading.gif';
import { BlockScanMap, Coordinate, EntityType, Orchard, StageType, Statistics } from '../common/types';
import { MapBox } from '../map/MapBox';
import { FruitletExport } from '../sidebar/FruitletExport';
import { Sidebar } from '../sidebar/Sidebar';

// TODO: replace any with proper types
interface HomeProps {
  orchard: Orchard | null;
  orchardCode: string;
  fruitletDF: any;
  blockData: any;
  initialLoad: number;
  dbName: React.MutableRefObject<string>;
  plotType: string | null;
  selectedVarieties: any[];
  varietyList: any[];
  downloadFormat: string;
  setDownloadFormat: React.Dispatch<React.SetStateAction<string>>;
  setPlotType: React.Dispatch<React.SetStateAction<string | null>>;
  chartType: string;
  setChartType: React.Dispatch<React.SetStateAction<string>>;
  mapInstance: React.MutableRefObject<mapboxgl.Map | null>;
  scanLoading: boolean;
  setScanLoading: React.Dispatch<React.SetStateAction<boolean>>;
  blockScanMap: BlockScanMap;
  entity: EntityType;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  progress: number;
  stats: Statistics | null;
  setTruthfulScanName: React.Dispatch<React.SetStateAction<string | null>>;
  setTruthfulScanId: React.Dispatch<React.SetStateAction<number | null>>;
  blockView: boolean;
  blockName: string | null;
  setBlockName: React.Dispatch<React.SetStateAction<string | null>>;
  sidebarViewMode: string;
  setSidebarViewMode: React.Dispatch<React.SetStateAction<string>>;
  setBlockView: React.Dispatch<React.SetStateAction<boolean>>;
  truthfulScanName: string | null;
  truthfulScanId: number | null;
  uniformTarget: any;
  setSdmTableView: React.Dispatch<React.SetStateAction<boolean>>;
  sdmTableView: boolean;
  addToSidebar: boolean;
  polygonStats: Statistics | null;
  enableRowFilter: boolean;
  stageType: StageType | null;
  blockInfo: any;
  setBlockInfo: React.Dispatch<React.SetStateAction<any>>;
  setTreeViewLat: React.Dispatch<React.SetStateAction<number>>;
  setTreeViewLong: React.Dispatch<React.SetStateAction<number>>;
  setSectionGeojson: React.Dispatch<React.SetStateAction<any>>;
  exclusionArray: string[];
  setEnableRowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  hasRowVarieties: boolean;
  allowedVarieties: any[];
  setAllowedVarieties: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedVarieties: React.Dispatch<React.SetStateAction<any[]>>;
  avgTrunkSpacing: number;
  avgVigor: number;
  avgTrunkDiam: number;
  avgTrunkHeight: number;
  avgFruitCount: any;
  setPolygonStats: React.Dispatch<React.SetStateAction<any>>;
  polygonStatDialog: boolean;
  setPolygonStatDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setAddToSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  setVarietyList: React.Dispatch<React.SetStateAction<any[]>>;
  setIsLoad: React.Dispatch<React.SetStateAction<boolean>>;
  isLoad: boolean;
  mapCenter: any;
  setMapCenter: React.Dispatch<React.SetStateAction<any>>;
  controlEnabled: boolean;
  initialLocation: Coordinate | undefined;
  treeData: any;
  setUniformTarget: React.Dispatch<React.SetStateAction<any>>;
  setControlEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setBlockData: React.Dispatch<React.SetStateAction<any>>;
  adminUser: boolean;
  treeViewLat: number;
  treeViewLong: number;
  sectionGeojson: any;
  sidebarWidth: number;
  setSidebarWidth: React.Dispatch<React.SetStateAction<number>>;
  scanInfo: any;
}

export const Home: React.FC<HomeProps> = ({
  orchard,
  orchardCode,
  fruitletDF,
  blockData,
  initialLoad,
  dbName,
  plotType,
  selectedVarieties,
  varietyList,
  downloadFormat,
  setDownloadFormat,
  setPlotType,
  chartType,
  setChartType,
  mapInstance,
  scanLoading,
  setScanLoading,
  blockScanMap,
  entity,
  setProgress,
  progress,
  stats,
  setTruthfulScanName,
  setTruthfulScanId,
  blockView,
  blockName,
  setBlockName,
  sidebarViewMode,
  setSidebarViewMode,
  setBlockView,
  truthfulScanName,
  truthfulScanId,
  uniformTarget,
  setSdmTableView,
  sdmTableView,
  addToSidebar,
  polygonStats,
  enableRowFilter,
  stageType,
  blockInfo,
  setBlockInfo,
  setTreeViewLat,
  setTreeViewLong,
  setSectionGeojson,
  exclusionArray,
  setEnableRowFilter,
  hasRowVarieties,
  allowedVarieties,
  setAllowedVarieties,
  setSelectedVarieties,
  avgTrunkSpacing,
  avgVigor,
  avgTrunkDiam,
  avgTrunkHeight,
  avgFruitCount,
  setPolygonStats,
  polygonStatDialog,
  setPolygonStatDialog,
  setAddToSidebar,
  setVarietyList,
  setIsLoad,
  isLoad,
  mapCenter,
  setMapCenter,
  controlEnabled,
  initialLocation,
  treeData,
  setUniformTarget,
  setControlEnabled,
  setBlockData,
  adminUser,
  treeViewLat,
  treeViewLong,
  sectionGeojson,
  sidebarWidth,
  setSidebarWidth,
  scanInfo,
}) => {
    const { blockName: urlBlockName } = useParams<{ blockName: string }>();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (urlBlockName && urlBlockName !== blockName) {
          setBlockName(urlBlockName);
        }
      }, [urlBlockName]);
    
      useEffect(() => {
        if (blockName) {
            navigate(`/block/${blockName}`);
        } else {
          navigate('/');
        }
      }, [blockName]);
        
  return blockData === null ? (
    <div className="h-screen flex flex-col justify-center items-center mx-auto mt-auto mb-auto">
      {dbName.current === 'NFM' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={NWF}></img>}
      {dbName.current === 'SAG' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={SAG}></img>}
      {dbName.current === 'ZRK' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={ZIR}></img>}
      {dbName.current === 'CHA' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={CHI}></img>}
      {dbName.current === 'SVO' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={SVO}></img>}
      {dbName.current === 'ABF' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={ALL}></img>}
      {dbName.current === 'STE' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={STE}></img>}
      <img className="max-w-[200px] max-h-[200px]" src={loading} alt="loading..." />
      <p>Loading... {initialLoad}%</p>
      <div className="progress-bar-container justify-center items-center w-full">
        <div className="mx-auto relative pt-1 max-w-[500px]">
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200">
            <div
              style={{ width: `${initialLoad}%` }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
            ></div>
          </div>
        </div>
      </div>
      <img className='absolute bottom-4 max-w-[200px] max-h-[200px]' src={MadeByOrchard}></img>
    </div>
  ) : (
<div className="grid" style={{ gridTemplateColumns: `${sidebarWidth}px 1fr` }}>
      <Toaster position='top-left' toastOptions={{
        success: { duration: 2000 },
        className: 'min-w-[23%]'
      }} />
      <Sidebar
        orchard={orchard}
        plotType={plotType}
        selectedVarieties={selectedVarieties}
        varietyList={varietyList}
        downloadFormat={downloadFormat}
        setDownloadFormat={setDownloadFormat}
        setPlotType={setPlotType}
        chartType={chartType}
        setChartType={setChartType}
        mapInstance={mapInstance}
        scanLoading={scanLoading}
        setScanLoading={setScanLoading}
        blockScanMap={blockScanMap}
        entity={entity}
        setProgress={setProgress}
        progress={progress}
        stats={stats}
        setTruthfulScanName={setTruthfulScanName}
        setTruthfulScanId={setTruthfulScanId}
        blockView={blockView}
        blockName={blockName}
        setBlockName={setBlockName}
        sidebarViewMode={sidebarViewMode}
        setSidebarViewMode={setSidebarViewMode}
        setBlockView={setBlockView}
        truthfulScanName={truthfulScanName}
        uniformTarget={uniformTarget}
        addToSidebar={addToSidebar}
        polygonStats={polygonStats}
        enableRowFilter={enableRowFilter}
        stageType={stageType}
        blockInfo={blockInfo}
        setBlockInfo={setBlockInfo}
        setTreeViewLat={setTreeViewLat}
        setTreeViewLong={setTreeViewLong}
        setSectionGeojson={setSectionGeojson}
        sidebarWidth={sidebarWidth}
        setSidebarWidth={setSidebarWidth}
        scanInfo={scanInfo}
        adminUser={adminUser}
        treeData={treeData}
      />
      <div style={{ visibility: (scanLoading || isLoad || progress !== 100) ? 'hidden' : 'visible'}}>
        {sdmTableView ? (
          <FruitletExport
            data={fruitletDF}
            blockName={blockName}
            truthfulScanName={truthfulScanName}
            setSdmTableView={setSdmTableView}
          />
        ) : (
          <MapBox
            exclusionArray={exclusionArray}
            orchardCode={orchardCode}
            setEnableRowFilter={setEnableRowFilter}
            hasRowVarieties={hasRowVarieties}
            selectedVarieties={selectedVarieties}
            allowedVarieties={allowedVarieties}
            setAllowedVarieties={setAllowedVarieties}
            setSelectedVarieties={setSelectedVarieties}
            varietyList={varietyList}
            avgTrunkSpacing={avgTrunkSpacing}
            avgVigor={avgVigor}
            avgTrunkDiam={avgTrunkDiam}
            avgTrunkHeight={avgTrunkHeight}
            downloadFormat={downloadFormat}
            plotType={plotType}
            setPlotType={setPlotType}
            avgFruitCount={avgFruitCount}
            polygonStats={polygonStats}
            setPolygonStats={setPolygonStats}
            polygonStatDialog={polygonStatDialog}
            setPolygonStatDialog={setPolygonStatDialog}
            setAddToSidebar={setAddToSidebar}
            setVarietyList={setVarietyList}
            entity={entity}
            mapInstance={mapInstance}
            setIsLoad={setIsLoad}
            isLoad={isLoad}
            mapCenter={mapCenter}
            scanLoading={scanLoading}
            setScanLoading={setScanLoading}
            setMapCenter={setMapCenter}
            blockName={blockName}
            truthfulScanName={truthfulScanName}
            truthfulScanId={truthfulScanId}
            controlEnabled={controlEnabled}
            sidebarViewMode={sidebarViewMode}
            stats={stats}
            setSidebarViewMode={setSidebarViewMode}
            setBlockName={setBlockName}
            blockView={blockView}
            setBlockView={setBlockView}
            initialLocation={initialLocation}
            blockData={blockData}
            treeData={treeData}
            setUniformTarget={setUniformTarget}
            setControlEnabled={setControlEnabled}
            setBlockData={setBlockData}
            adminUser={adminUser}
            blockScanMap={blockScanMap}
            stageType={stageType}
            setTreeViewLat={setTreeViewLat}
            setTreeViewLong={setTreeViewLong}
            treeViewLat={treeViewLat}
            treeViewLong={treeViewLong}
            sectionGeojson={sectionGeojson}
            sidebarWidth={sidebarWidth}
            blockInfo={blockInfo}
            setSectionGeojson={setSectionGeojson}  
            orchard={orchard}
          />
        )}
      </div>

      {(scanLoading || isLoad || progress !== 100) && (
        <div className="absolute inset-0 left-1/3 flex flex-col justify-center items-center">
          {dbName.current === 'SAG' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={SAG}></img>}
          {dbName.current === 'NWF' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={NWF}></img>}
          {dbName.current === 'ZIR' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={ZIR}></img>}
          {dbName.current === 'CHI' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={CHI}></img>}
          {dbName.current === 'SVO' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={SVO}></img>}
          {dbName.current === 'ALL' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={ALL}></img>}
          {dbName.current === 'STE' && <img className='absolute top-16 max-w-[200px] max-h-[200px]' src={STE}></img>}
          <p className='font-lato text-gray-500'>Reloading map with new scan data...</p>
          <img className="max-w-[200px] max-h-[200px]" src={loading} alt="loading..." />
        </div>
      )}
    </div>
  );
      }