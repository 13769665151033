import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    FormControlLabel
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { React, useEffect, useState } from 'react';
import legend from '../assets/legend.jpeg';
import { EntityType, StageType } from '../common/types';

export const EnableDrawControl = ({ drawEnabled, setDrawEnabled }) => {
    return (
        <div
            className='flex items-center px-2 py-2 font-mono bg-white text-xs cursor-pointer text-black border border-gray-900 rounded-lg'
            onClick={() => {
                setDrawEnabled((b) => !b);
            }}
        >
            <div className='flex-shrink-0'>
                <FontAwesomeIcon icon={'fa-solid fa-vector-square'} size='xl' fixedWidth />
            </div>
            <div className='ml-1'>
                Area Selection
                <br />
                {drawEnabled && (
                    <span className='font-mono text-gray-600'>Click the first node to confirm selection. Press delete to cancel selection</span>
                )}
            </div>
        </div>
    );

}

export const Legend = () => (
    <div className='fixed bottom-0 bg-white m-2 w-fit flex flex-row left-0 right-0 mx-auto px-2 border border-gray-900 rounded-lg font-lato'>
        <span className='text-center'>Less fruit</span>
        <img src={legend} alt='color scale legend for plots' width={400} className='mx-2 border-x border-gray-900' />
        <span className='text-center'>More fruit</span>
    </div>
)

const rowAudit = {
    value: 'row_audit',
    label: 'Row Audit Interface (admin)',
    description: "Interface for setting rows."
};

const blockAudit = {
    value: 'block_audit',
    label: 'Block Audit Interface (admin)',
    description: "Interface for modifying blocks."
};

const trunkAudit = {
    value: 'trunk_audit',
    label: 'Trunk Audit Interface (admin)',
    description: "Interface for modifying trunks."
};

const fruitSizeTargetHeatmap = {
    value: 'size',
    label: 'Fruit Size Heatmap',
    description: "Heatmap that shows the average fruit size per tree"
};

const rldHeatmap = {
    value: 'rld',
    label: 'RLD Heatmap (admin)',
    description: "Heatmap that shows red leaf disease (RLD) per tree"
};

// Keep commented visualizations in case they're needed for fruit-level exported data later in the season
export const visualizationConfigSubset = {
    trees: [
        // {
        //     value: 'tree-vigor',
        //     label: 'Tree Vigor Heatmap',
        //     description: "Heatmap that shows the canopy area of a tree"
        // },
        // {
        //     value: 'height',
        //     label: 'Canopy Height Heatmap',
        //     description: "Heatmap that shows the canopy height of a tree"
        // },
        {
            value: 'tree_diam',
            label: 'Trunk Diameter Heatmap',
            description: "Heatmap that shows trunk diameter per tree"
        },
        // {
        //     value: 'canopy_hue',
        //     label: 'Canopy Hue Heatmap',
        //     description: "Heatmap that shows the canopy hue of a tree"
        // },
        // { // TODO: Commented for CHA delivery, uncomment when trunk spacing is handled
        //     value: 'spacing',
        //     label: 'Trunk Spacing Heatmap',
        //     description: "Heatmap that shows the spacing between trees"
        // }
    ],
    fruits: [
        {
            value: 'size',
            label: 'Fruit Size Heatmap',
            description: "Heatmap that shows the average fruit size per tree"
        },
        {
            value: 'color',
            label: 'Color Heatmap',
            description: "Heatmap that shows the average color of fruits per tree"
        },
    ],
}
// Keep commented visualizations in case they're needed for fruit-level maps later in the season
export const visualizationConfig = {
    trees: [
        // {
        //     value: 'tree-vigor',
        //     label: 'Tree Vigor Heatmap',
        //     description: "Heatmap that shows the canopy area of a tree"
        // },
        // {
        //     value: 'height',
        //     label: 'Canopy Height Heatmap',
        //     description: "Heatmap that shows the canopy height of a tree"
        // },
        {
            value: 'tree_diam',
            label: 'Trunk Diameter Heatmap',
            description: "Heatmap that shows trunk diameter per tree"
        },
        {
            value: 'canopy_hue',
            label: 'Canopy Hue Heatmap',
            description: "Heatmap that shows the canopy hue of a tree"
        },
        {
            value: 'spacing',
            label: 'Trunk Spacing Heatmap',
            description: "Heatmap that shows the spacing between trees"
        },
    ],
    fruits: [
        {
            value: 'uniform',
            label: 'Fruit Count Heatmap',
            description: "Heatmap that shows the average fruit count per tree"
        },
        // {
        //     value: 'size',
        //     label: 'Fruit Size Target Heatmap',
        //     description: "Heatmap that shows the difference between current average diameter of fruits and target diameter of fruits, where the target is set by the user and identical for every tree"
        // },
        // {
        //     value: 'sampled_imgs',
        //     label: 'High-Res Sample Images',
        //     description: "Trees with sampled images."
        // },
        {
            value: 'color',
            label: (
                <>
                    Color Heatmap
                </>
            ),
            description: "Heatmap that shows the average color of fruits per tree"
        },
        {
            value: 'tree-vigor',
            label: 'Tree Vigor Heatmap',
            description: "Heatmap that shows the canopy area of a tree"
        },
        // {
        //     value: 'canopy_hue',
        //     label: 'Canopy Hue Heatmap',
        //     description: "Heatmap that shows the canopy hue of a tree"
        // },
    ]
};

export const MapSettings = ({
    exclusionArray,
    entityType,
    toggleStyle,
    plotType,
    setPlotType,
    controlEnabled,
    setLowerBound,
    setUpperBound,
    adminUser,
    viewTapeSections,
    setViewTapeSections,
    viewZoneMap,
    setViewZoneMap,
    sectionGeojson,
    stageType,
    truthfulScanName,
    style,
    view3DMap,
    setView3DMap,
    handlePregenerate,
    fruitType,
}) => {
    const [visible, setVisible] = useState(true);
    const [activePopup, setActivePopup] = useState(null);

    useEffect(() => {
        if (entityType === 'trees') {
            setPlotType('tree_diam')
            setLowerBound(80)
            setUpperBound(120)
        }
        if (entityType === 'fruits') {
            setPlotType('uniform')
            setLowerBound(60)
            setUpperBound(140)
        }
    }, [entityType])

    const toggleVisible = () => {
        setVisible(v => !v);
    };

    const handleChange = (event) => {
        if (controlEnabled) {
            const value = event.target.value;
            const bounds = {
                'size': [95, 105],
                'uniform': [60, 140],
                'tree_diam': [80, 120],
                'height': [80, 120],
                'tree-vigor': [70, 130],
                'rld': [0, 150]
            };
            setLowerBound(bounds[value]?.[0] || 80);
            setUpperBound(bounds[value]?.[1] || 120);
            setPlotType(value);
        }
    };

    const handleMouseEnter = (event, id) => {
        setActivePopup(id);
        event.currentTarget.style.position = "relative";
    };

    const handleMouseLeave = (event) => {
        setActivePopup(null);
        event.currentTarget.style.position = "";
    };

    const getVisualizationConfig = (entityType, stageType, adminUser) => {
        let visConfig = [...visualizationConfig[entityType]];
        if (entityType === EntityType.Fruits) {
            // if (fruitType === FruitType.Grape) {
            //     visConfig.push(rldHeatmap);
            // }
            if (stageType === StageType.EarlyFruitSet || stageType === StageType.Fruit) {
                visConfig.push(fruitSizeTargetHeatmap);
            }
            if (stageType === StageType.EarlyFruitSet) {
                visConfig = visConfig.filter((item) => item.value !== 'uniform' && item.value !== 'tree-vigor');
            }
        }
        if (adminUser) {
            visConfig.push(rldHeatmap, blockAudit, rowAudit);
            if (entityType === EntityType.Trees) {
                visConfig.push(trunkAudit);
            }
        }
        return visConfig;
    };

    const handleToggleTapeSections = (event) => {
        setViewTapeSections(event.target.checked);
    };

    const handleViewZoneMap = (event) => {
        setViewZoneMap(event.target.checked);
    };

    return (
        <div className='font-lato bg-white border border-gray-900 rounded-lg m-2 px-2 pb-2 pt-1 fixed right-0 top-0 text-lg opacity-80 flex flex-row drop-shadow-md'>
            {visible ? (
                <div className='w-60'>
                    <div className='font-bold text-center'>
                        Plot Type
                        <FontAwesomeIcon className='px-2 text-2xl align-middle' icon='fa-solid fa-caret-right' onClick={toggleVisible} />
                    </div>
                    <hr />
                    <form>
                        {getVisualizationConfig(entityType, stageType, fruitType, adminUser).map((e, index) => {
                            if (!exclusionArray.includes(e.value)) {
                                return (
                                    <label className='flex flex-row' key={e.value} onMouseEnter={(event) => handleMouseEnter(event, index)}
                                        onMouseLeave={handleMouseLeave}>
                                        <input type='radio' value={e.value} checked={plotType === e.value} onChange={handleChange} disabled={!controlEnabled} />
                                        <span className='ml-2'>{e.label}</span>
                                        {activePopup === index && (
                                            <div className="absolute z-10 top-full left-0 mt-2 w-48 rounded-lg shadow-lg bg-white border border-gray-200">
                                                <div className="py-2 px-4 text-sm">{e.description}</div>
                                            </div>
                                        )}
                                    </label>
                                );
                            }
                            return null;
                        })}
                    </form>
                    {adminUser && (
                        <button style={{ maxWidth: '350px' }}
                            className="btn-primary bg-green-500 hover:bg-green-700"
                            onClick={(e) => handlePregenerate(e, truthfulScanName)}
                        >
                            Pregenerate Scan (admin)
                        </button>
                    )}
                    <hr />
                    {entityType === EntityType.Fruits && sectionGeojson && sectionGeojson.features.length > 0 && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={viewTapeSections}
                                    onChange={handleToggleTapeSections}
                                    className="m-0"
                                />
                            }
                            label="Ground Truth Sections"
                        />
                    )}

                    <FormControlLabel
                        control={
                            <Switch
                                checked={style === 'mapbox://styles/mapbox/outdoors-v12'}
                                onChange={toggleStyle}
                                size="medium"
                                className="m-0"
                            />
                        }
                        label="Toggle Map Style"
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={view3DMap}
                                onChange={(e) => setView3DMap(e.target.checked)}
                                size="medium"
                                className="m-0"
                            />
                        }
                        label={"View Topography"}
                    />
                    
                    {entityType === EntityType.Fruits && plotType !== 'color' && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={viewZoneMap}
                                    onChange={handleViewZoneMap}
                                    size="medium"
                                    className="m-0"
                                />
                            }
                            label={"View Zone Mode"}
                        />
                    )}
                </div>
            ) : (
                <div>
                    <FontAwesomeIcon className='text-2xl align-middle px-2' icon='fa-solid fa-caret-left' onClick={toggleVisible} />
                </div>
            )}
        </div>
    );
};