// import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithPhoneNumber,
    signOut,
    logout,
}from "firebase/auth";



const firebaseConfig = {
    apiKey: "AIzaSyAxTKnfWd3j9bk4jwlCAZZbey2C8FZql7I",
    authDomain: "fruitscope-cloud.firebaseapp.com",
    projectId: "fruitscope-cloud",
    storageBucket: "fruitscope-cloud.appspot.com",
    messagingSenderId: "141860753339",
    appId: "1:141860753339:web:208cd887c0f1ec9c77be9e",
    measurementId: "G-HT0Q0W8D1M"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

// auth.languageCode = 'it';
// window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
firebase.auth().languageCode = 'en';
// const verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);
export const logOut = () => auth.signOut(provider)

export default firebase;