export type Statistics = {
  avg_canopy_area_m2: number;
  avg_fruit_diam: number | null;
  avg_fruitlet_diam: number | null;
  avg_fruit_vol: number | null;
  avg_fruit_weight: number | null;
  avg_hue: number;
  avg_trunk_xsarea: number;
  median_trunk_xsarea: number;
  block_id: number;
  fruit_per_tree_calibrated: number;
  fruit_per_tree_detected: number;
  percent_packable: number | null;
  scan_id: string;
  total_fruit_calibrated: number | null;
  total_fruit_detected: number;
  calibration_ratio: number | null;
  total_trees: number;
  percent_trees: number;
  total_yield: number | null;
  block_acreage: number | null;
  avg_tree_spacing_ft: number | null;
  avg_tree_diam_in: number | null;
  median_tree_diam_in: number | null;
  avg_tree_area_in2: number | null;
  avg_coverage: number;
  avg_canopy_height_m: number | null;
  block_name: string;
  timestamp: string;
  ccsa_red_m2: number | null;
  ccsa_yellowred_m2: number | null;
  ccsa_yellow_m2: number | null;
  ccsa_wilted_m2: number | null;
}

export type Block = {
  block_name: string;
  block_id: number;
  location: Coordinate;
  acreage: number;
}

export type Coordinate = {
  lat: number
  long: number
}

export type Orchard = {
  name: string;
  address: string;
  initialLocation: Coordinate;
  code: string;
  total_acres: number;
  section_code_prefix: number;
}

export type BlockScanMap = {
  [key: string]: {
    center_lat: number; // TODO: refactor into coordinate type
    center_lon: number;
    scans: number[];
    last_scan_timestamp: string;
  };
};

export enum EntityType {
  Trees = 'trees',
  Fruits = 'fruits'
}

export enum FruitType {
  Apple = 'Apples',
  Grape = 'Grapes',
  Cherry = 'Cherries',
  Other = 'Other'
}

export enum StageType {
  Bud = 'Dormant Buds',
  SilverTip = 'Silver Tip',
  GreenTip = 'Green Tip',
  TightCluster = 'Tight Cluster',
  Pink = 'Pink',
  KingBloom = 'King Bloom',
  FullBloom = 'Full Bloom',
  PetalFall = 'Petal Fall',
  EarlyFruitSet = 'Early Fruit Set',
  Fruit = 'Fruit',
}
