import { Checkbox, FormControlLabel, FormGroup, Tabs, Tab, Box } from '@mui/material';
import React, { useState } from 'react';

const RLDLegend = ({ 
    stats, 
    config, 
    upperBound, 
    setUpperBound, 
    applyPercentageToTarget, 
    convertToBoundOfTarget, 
    selectAll, 
    selectedRLDOptions, 
    setSelectedRLDOptions,
    StyledTextField
}) => {
    const [tabValue, setTabValue] = useState(0);

    const options = [
        { value: 'ccsa_red_m2', label: 'Red' },
        { value: 'ccsa_yellowred_m2', label: 'Red-Yellow' },
        { value: 'ccsa_yellow_m2', label: 'Yellow' },
        { value: 'ccsa_wilted_m2', label: 'Wilted' }
    ];

    const handleChange = (event) => {
        setSelectedRLDOptions({
            ...selectedRLDOptions,
            [event.target.name]: event.target.checked
        });
    };

    const handleUpperBoundChange = (event) => {
        convertToBoundOfTarget(event, setUpperBound);
    };

    const handlePercentChange = (event) => {
        if (event.target.value === '') {
            setUpperBound('');
        } else {
            const newPercent = parseFloat(event.target.value);
            if (!isNaN(newPercent)) {
                setUpperBound(newPercent);
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div className='flex flex-col items-center w-full'>
            <span className='text-center font-semibold mb-2'>Leaf Discoloration {config.unit}</span>

            <div className='flex w-full'>
                <div className='w-2/3'>
                    <div className='flex justify-center'>
                        <Tabs 
                            value={tabValue} 
                            onChange={handleTabChange} 
                            aria-label="RLD tabs"
                        >
                            <Tab label="RLD" />
                            <Tab label="RLD/CCSA" />
                        </Tabs>
                    </div>
                    <Box sx={{ p: 2 }}>
                        {tabValue === 0 && (
                            <div className="flex flex-col space-y-2">
                                <StyledTextField
                                    type="number"
                                    value={applyPercentageToTarget(upperBound)}
                                    onChange={handleUpperBoundChange}
                                    onFocus={selectAll}
                                    InputProps={{
                                        endAdornment: <span>{config.unit}</span>,
                                    }}
                                    size="small"
                                    style={{ width: '100px' }}
                                    variant="standard"
                                    label="Upper Bound"
                                />
                                <StyledTextField
                                    type="number"
                                    value={Math.round(upperBound)}
                                    onChange={handlePercentChange}
                                    onFocus={selectAll}
                                    InputProps={{
                                        endAdornment: <span>%</span>,
                                    }}
                                    size="small"
                                    style={{ width: '100px' }}
                                    variant="standard"
                                    label="Percent of Avg"
                                />
                            </div>
                        )}
                        {tabValue === 1 && (
                            <div>
                                {/* Content for RLD/CCSA tab */}
                            </div>
                        )}
                    </Box>
                </div>
                <div className='w-1/3'>
                    <FormGroup>
                        {Object.entries(selectedRLDOptions).map(([key, value]) => (
                            <FormControlLabel
                                key={key}
                                control={
                                    <Checkbox
                                        checked={value}
                                        onChange={handleChange}
                                        name={key}
                                        size="small"
                                    />
                                }
                                label={
                                    <div className="flex items-center">
                                        <span className="mr-2">{options.find(opt => opt.value === key)?.label || key}</span>
                                    </div>
                                }
                            />
                        ))}
                    </FormGroup>
                </div>
            </div>
        </div>
    );
};

export default RLDLegend;