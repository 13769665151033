import React, { useEffect } from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';

export const ImageDisplayModes = {
    HIDE: 0,
    MIDDLE: 5,
    MID_A: 2,
    FULL: 3,
    FULL_A: 4,
    SECRET: 1
}

export const Branding = ({ imgDisplayMode, toggleImgDisplayMode, scanInfo }) => {
    const handleAdmin = () => {
        window.location.pathname = '/admin'
    }

    useEffect(() => {
        if (imgDisplayMode === ImageDisplayModes.SECRET) {
            handleAdmin();
        }
    }, [imgDisplayMode]);

    // Determine fruit color based on scanInfo
    const fruitColor = scanInfo && scanInfo.fruit_type === 'Grapes' ? '#B00080' : '#ff0333';

    return (
        <div
            id='branding'
            className='flex flex-col justify-between p-2 bg-orchardGreen font-lato text-white'
        >
            <div className='flex flex-row justify-center'>
                <Logo
                    width={90}
                    height={90}
                    onClick={() => { toggleImgDisplayMode(imgDisplayMode); }}
                    style={{
                        '--fruit-color': fruitColor,
                    }}
                />
                <div className='flex flex-col justify-center'>
                    <h1 className='font-bold text-3xl mt-3'>FruitScope Cloud</h1>
                    {
                        imgDisplayMode !== ImageDisplayModes.HIDE && imgDisplayMode !== ImageDisplayModes.SECRET ?
                            <h2 className='text-sm text-center'>{(
                                (imgDisplayMode === ImageDisplayModes.MID_A) || (imgDisplayMode === ImageDisplayModes.FULL_A)) ? 'made ' : ''}
                                {(imgDisplayMode === ImageDisplayModes.FULL) || (imgDisplayMode === ImageDisplayModes.FULL_A) ? 'by' : 'from'} Orchard Robotics</h2>
                            :
                            null
                    }
                    {
                        imgDisplayMode === ImageDisplayModes.SECRET ?
                            <h2 className='text-sm text-center'>
                                admin </h2>
                            :
                            null
                    }
                </div>
            </div>
            <p className='text-xs text-right'>Version 11.9.8</p>
        </div>
    );
}