import { faMapLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { OrchardCard } from './Cards';

const BlocksList = ({ blockScanMap, setTreeViewLat, setTreeViewLong, setSidebarViewMode, setBlockName, setBlockView, searchTerm }) => {

    const handleClick = (block_id, center_lat, center_lon) => {
        setTreeViewLat(center_lat);
        setTreeViewLong(center_lon);
        setSidebarViewMode('Block');
        setBlockView(true);
        setBlockName(block_id);
    }

    return (
        <div className="overflow-y-auto h-full flex-grow min-h-0 scrollbar-thin scrollbar-visible scrollbar-thumb-gray-400 scrollbar-track-gray-200">
            {Object.entries(blockScanMap)
                .filter(([blockName, blockData]) => 
                    blockName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    blockData.scans.some(scan => scan.toString().includes(searchTerm))
                )
                .sort(([, a], [, b]) => {
                    if (a.last_scan_timestamp && b.last_scan_timestamp) {
                        return new Date(b.last_scan_timestamp) - new Date(a.last_scan_timestamp);
                    }
                    if (a.last_scan_timestamp) return -1;
                    if (b.last_scan_timestamp) return 1;
                    return 0;
                })
                .map(([blockName, blockData]) => {
                    const { center_lat, center_lon, scans, last_scan_timestamp, last_scan_type } = blockData;
                    return (
                        <Tooltip
                            key={blockName} 
                            title={scans.length === 0 ? 'No scans in block' : scans.join(', ')}
                            placement="top-end"
                            enterDelay={500}
                        >
                            <div
                                className="flex border my-4 bg-gray-200 hover:bg-gray-300 border-gray-400 p-2 rounded-lg cursor-pointer whitespace-nowrap overflow-hidden"
                                onClick={() => handleClick(blockName, center_lat, center_lon)} // Fly to the block
                            >
                                <div className="flex flex-col w-full">
                                    <div className="flex justify-between">
                                        <span className="text-l mr-10">{blockName}</span>
                                        <span className="text-l flex overflow-x-hidden">
                                            {scans.length === 0 ? (
                                                <span className="text-gray-600">No scans in block</span>
                                            ) : (
                                                <span className="overflow-ellipsis overflow-hidden">
                                                    {scans.map(
                                                        (scan, index) => `#${scan}${index < scans.length - 1 ? ', ' : ''}`
                                                    )}
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                    <div className="flex justify-between">
                                        {last_scan_timestamp && <span className="text-sm text-gray-600">Last Scan: {last_scan_timestamp}, {last_scan_type ? last_scan_type : 'Trees'}</span>}
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    );
                })}
        </div>
    );
};


export const OrchardView = ({ blockView, blockScanMap, orchard, setBlockView, setTreeViewLat, setTreeViewLong, setSidebarViewMode, setBlockName }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSetSearchTerm = useCallback(
        debounce((value) => setSearchTerm(value), 300),
        []
    );

    const handleSearchChange = (event) => {
        debouncedSetSearchTerm(event.target.value);
    };

    return (
        <OrchardCard blockView={blockView} title={orchard.name} className="h-full">
            <div className='flex flex-col min-h-full'>
                <span className='flex flex-row mt-1'>
                    <FontAwesomeIcon size='lg' icon={'fa-solid fa-house'} fixedWidth /> {'  '}
                    <span className={'text-l pl-2'}>{orchard.address}</span>
                </span>
                <span className='flex flex-row mt-1'>
                    <FontAwesomeIcon size='lg' icon={faMapLocationDot} fixedWidth /> {'  '}
                    <span className={'text-l pl-2'}>{orchard.total_acres} Acres</span>
                </span>
                <hr className='border-gray-300 my-1' />
                <Tooltip
                    title={"This is your prefix for ground truth sections"}
                    placement="top-start"
                    enterDelay={750}
                >
                <span className='flex flex-row'>
                    <span className={'text-l mr-5'}>Ground Truth Section Code: {orchard.section_code_prefix}</span>
                </span>
                </Tooltip>
                <span>
                    <a href="https://help.fruitscope.com/guides/setting-up-ground-truth-sections" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        Ground Truth Guide
                    </a>
                    <span className='m-1'>|</span>

                    <a href="https://fruitscope.support" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        User Guide
                    </a>
                    <span className='m-1'>|</span>
                    <a href="https://user-manual.fruitscope.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        Camera User Manual
                    </a>
                </span>
        

                <hr className='border-gray-300 my-2' />
                <h1 className='text-left text-xl font-bold font-lato'> Blocks</h1>
                <p className='pb-1 text-xs text-slate-500 pt-1 text-left text-gray-500'>Click on an entry below or click a block in the map to view scans</p>
                
                <input
                    type="text"
                    placeholder="Search Block Name or Scan #"
                    onChange={handleSearchChange}
                    className="mb-2 p-2 border border-gray-300 rounded-md"
                />
                
                <div className='flex-grow overflow-hidden pb-6'>
                    {blockScanMap && (
                        <BlocksList
                            blockScanMap={blockScanMap}
                            setBlockView={setBlockView}
                            setTreeViewLat={setTreeViewLat}
                            setTreeViewLong={setTreeViewLong}
                            setSidebarViewMode={setSidebarViewMode}
                            setBlockName={setBlockName}
                            searchTerm={searchTerm}
                        />
                    )}
                </div>
            </div>
        </OrchardCard>
    );
}