import React from 'react';
import logo from '../assets/logo.svg';

export const Setup = () => {

    return (
        <div className="flex flex-col items-center justify-center  h-screen bg-gray-100">
            <img className="w-36 h-36 mt-40" src={logo} alt="Orchard Robotics Logo" />
            <div className="flex items-center justify-center h-full">
                <div className="flex flex-col items-center mb-48">
                    <div className="h-10"></div>
                    <div className="font-bold text-xl text-center mb-4">Thank you for signing into the Orchard Robotics Cloud Data Dashboard.</div>
                    <div className="text-l text-center mb-4">Congrats! You have completed the last step of the setup process. <br></br> Your first data will be ready to view here at <a href="https://fruitscope.com">fruitscope.com</a> after your first scan.</div>
                </div>
            </div>
        </div >

    );
};



